.section_title {
}

.section_title .hyper {
  font-size: 100px;
}

.sub_title {
  font-family: "Oswald";
  font-weight: 500;
  font-size: 22px;
  line-height: 1;
  letter-spacing: -0.02em;
  /* margin: 0; */
  display: inline-block;
}

.small_title {
  font-family: "Oswald";
  font-weight: 500;
  font-size: 15px;
  line-height: 1;
  margin: 20px 0 0 0;
  position: relative;
  width: 100%;
  display: inline-block;
}

.descr_title {
  margin: 0px 0 50px 0;
}

.descr_title span {
  font-size: 11px;
}

.descr_title span {
  font-family: "CF Asty Std";
  font-weight: normal;
  font-size: 14px;
  line-height: 0;
  margin: 0;
  color: #ffffff;
}

@media screen and (max-width: 991px) {
  .descr_title {
    margin: 0px 0 10px 0;
  }
}

@media screen and (max-width: 480px) {
  .descr_title {
    margin: 0 0 10px 0;
    font-size: 15px;
  }

  .descr_title span {
    font-size: 11px;
  }

  .sub_title {
    font-size: 15px;
  }
}
