.panel-wrapper.open .accordion::after {
  /*animation: half-spin .2s 1 linear;*/
  -webkit-transform: rotate(315deg);
  -ms-transform: rotate(315deg);
  transform: rotate(315deg);
}

.accordion {
  background-color: rgba(255, 255, 255, 0.13);
  color: #ffffff;
  cursor: pointer;
  padding: 18px 50px 18px 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  position: relative;
  margin-bottom: 14px;
  font-family: "CF Asty Std";
  font-weight: 500;
}

.accordion:after {
  /* content: '\002B'; */
  color: #ced9de;
  /* font-weight: bold; */
  /* float: right; */
  /* margin-left: 5px; */
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-right: 2px solid currentColor;
  border-top: 2px solid currentColor;
  /* border-bottom: 2px solid currentColor; */
  /* border-left: 2px solid currentColor; */
  top: 0px;
  right: 0;
  top: calc(50% - 2px);
  right: 20px;
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  /* transform-origin: 50% 25%; */
  -webkit-transition: -webkit-transform 150ms;
  transition: -webkit-transform 150ms;
  -o-transition: transform 150ms;
  transition: transform 150ms;
  transition: transform 150ms, -webkit-transform 150ms;
}

.panel {
  padding: 0 18px;
  color: rgba(255, 255, 255, 0.7);
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.2s ease-out;
  -o-transition: max-height 0.2s ease-out;
  transition: max-height 0.2s ease-out;
  font-family: "CF Asty Std";
  font-weight: 400;
}

.faq h1,
.faq h2 {
  color: white;
  font-size: 30px;
}
.custom_table {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100% !important;
  border-radius: 10px;
  padding-bottom: 20px;
  border: 1px solid rgba(255, 255, 255, 0.7);
  overflow: hidden;
  background: #000000 !important;
  color: #ddd;
  font-family: "DejaVu Sans Mono", monospace, sans-serif;
  font-size: inherit;
  line-height: 20px;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  pointer-events: none;
}

.custom_table td {
  border: solid rgba(255, 255, 255, 0.7);
  border-width: 0 0 1px 0;
  padding: 0.5em;
  vertical-align: top;
}

.faq table {
  table-layout: fixed;
  border: solid rgba(255, 255, 255, 0.7);
  border-collapse: collapse;
  border-width: 1px 1px 0 1px;
  margin: 1em 0;
  width: 100%;
}

.faq th,
.faq td {
  border: solid #e4e2e0;
  border-width: 0 0 1px 0;
  padding: 0.5em;
  vertical-align: top;
}

.faq a {
  color: #fff77d;
}

.faq :not(img) {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow-wrap: break-word;
  word-wrap: break-word;
  max-width: 100% !important;
  width: 100%;
  overflow-y: auto;
  _overflow: auto;
  margin: 0 0 1em;
}

pre {
  white-space: initial;
}

td[width] {
  width: initial !important;
}
