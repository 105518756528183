.raceTitleShape {
  position: relative;
  overflow: hidden;
  margin: 4px;
}

.raceTitleShape:before,
.raceTitleShape:after {
  position: absolute;
  content: "";
  top: 0px;
  height: 100%;
  z-index: 0;
}

.raceTitleShape:before {
  right: 0px;
  top: 20px;
  width: 20px;
  -webkit-transform-origin: right bottom;
  -ms-transform-origin: right bottom;
  transform-origin: right bottom;
  -webkit-transform: skewY(45deg);
  -ms-transform: skewY(45deg);
  transform: skewY(45deg);
}

.raceTitleShape:after {
  right: 20px;
  width: calc(100% - 20px);
  border-left: none;
}

.raceTitleShape:after,
.raceTitleShape:before {
  background: #1b2c4b;
}

.race_head {
  display: table;
  width: calc(100% - 28px); /* 28 is margin & padding left-right total */
  padding: 4px 10px;
  margin: 10px 4px;
  position: relative;
  z-index: 1;
}

.race_head-box {
  display: table-cell;
  padding: 5px;
  vertical-align: middle;
}

.race_head-box:nth-child(1) {
  width: 50%;
}

.race_head-box:nth-child(2) {
  width: 210px;
  min-width: 194px;
}

.race_head-box:nth-child(3) {
  width: 110px;
  min-width: 100px;
}

.race_alingment {
  display: table;
}

.race_alingment-box {
  display: table-cell;
  vertical-align: bottom;
}

.race_head_left {
  width: 90px;
}

.race_head_right {
  width: 250px;
  font-size: 12px;
}

.race_logo {
  width: 180px;
}

.race_txt {
  margin: -4px 10px;
  min-width: 240px;
  color: rgba(255, 255, 255, 0.7);
}

.race_head_top {
  width: 200px;
  margin: 0 auto;
  /* float: right; */
}

.race_head_bottom {
  text-align: center;
  top: 0;
  font-size: 28px;
  font-family: "Oswald";
  font-weight: 500;
  margin: 0 auto;
}

.race_head_bottom p {
  margin: 0;
}

.fade1 {
  padding: 4px;
  margin: 4px; /* background: #0a567d; */ /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1b2d4c+1,ff8989+100 */
  background: #1b2d4c80; /* Old browsers */ /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #1b2d4c80 1%,
    #1b2d4c30 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(1%, #1b2d4c80),
    to(#1b2d4c30)
  );
  background: -o-linear-gradient(left, #1b2d4c80 1%, #1b2d4c30 100%);
  background: linear-gradient(
    to right,
    #1b2d4c80 1%,
    #1b2d4c30 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1b2d4c', endColorstr='#ff8989', GradientType=1); /* IE6-9 */
}

.fade2 {
  padding: 4px;
  margin: 4px 4px 20px 4px; /* background: #0a567d4d; */
  background: #1b2d4c80;
  background: -webkit-linear-gradient(left, #1b2d4c40 1%, #1b2d4c12 100%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(1%, #1b2d4c40),
    to(#1b2d4c12)
  );
  background: -o-linear-gradient(left, #1b2d4c40 1%, #1b2d4c12 100%);
  background: linear-gradient(to right, #1b2d4c40 1%, #1b2d4c12 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1b2d4c', endColorstr='#ff8989', GradientType=1);
}

@media screen and (max-width: 991px) {
  .race_head-box {
    display: inline-block;
    float: left;
    width: 100%;
    padding: 15px 0;
  }

  .race_head-box:nth-child(1) {
    width: 50%;
  }

  .race_alingment {
    display: unset;
  }

  .race_head_left {
    margin: 0 auto;
    width: 100%;
  }

  .race_alingment-box {
    display: inline-block;
    margin: 0 auto;
    width: 100%;
  }

  .race_head_right {
    /* width: 100%; */
    margin: 0 auto;
    font-size: 12px;
  }

  .race_logo {
    width: 200px;
    /* margin: 0 auto; */
  }

  .race_txt {
    /* margin: 10px auto 0 auto; */
    margin: 0px;
    width: 240px;
    text-align: center;
    text-align: left;
  }

  .race_head-box:nth-child(2) {
    width: calc(50% - 30px);
    position: absolute;
    /* left: 0px; */
    right: 15px;
    padding: 15px 0;
    /* top: 26px; */
  }

  .race_head_top {
    /* float: right; */
    right: 0;
    /* top: 6px; */
    /* position: absolute; */
    width: 200px;
  }

  .race_head_bottom {
    /* text-align: right; */
    /* float: right; */
    font-size: 24px;
  }

  .race_head-box:nth-child(3) {
    width: 100%;
    padding: 0;
  }

  a.movingBrackets {
    padding: 0;
  }
}

@media screen and (max-width: 575px) {
  .race_logo {
    width: 120px;
    /* margin: 0 auto; */
  }

  .race_head_top {
    /* float: right; */
    right: 0;
    top: 6px;
    position: absolute;
    width: 130px;
  }

  .race_head_bottom {
    text-align: right;
    /* float: right; */
    font-size: 18px;
  }

  .race_head-box a.movingBrackets {
    text-align: right;
  }
}
