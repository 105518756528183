.slack-logo > g:nth-child(1) {
  fill: #e01e5a !important;
}

.slack-logo > g:nth-child(2) {
  fill: #36c5f0 !important;
}

.slack-logo > g:nth-child(3) {
  fill: #2eb67d !important;
}

.slack-logo > g:nth-child(4) {
  fill: #ecb22e !important;
}

.slack-logo path {
  fill: inherit;
}
