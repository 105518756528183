.terminal-form {
  /*background: red;*/
}

.terminal-form__item {
  margin-top: 1rem;
}

.terminal-form__item > div:first-child {
  font-weight: bold;
  color: #ffdb7a;
}

.terminal-form__type {
  margin-top: 0.5rem;
}

.terminal-form__type span {
  margin-top: 0.5rem;
  color: limegreen;
}

.terminal-form__error {
  display: block;
  margin-top: 0.5rem;
}

.terminal-form__error span {
  color: red;
}

.terminal-form__allowed {
  color: white;
  padding: 0.5rem 0;
  margin-top: 0.2rem;
  font-size: 13px;
}

.terminal-form__allowed small {
  color: white;
  font-size: inherit;
  margin-bottom: 0.2rem;
  margin-right: 0.2rem;
  /*font-weight: 900;*/
}

.terminal-form__allowed div {
  display: inline;
  color: white;
}

.terminal-form__allowed span {
  color: #ffdb7a;
  vertical-align: middle;
}

.terminal-form__suggested {
  color: white;
  font-weight: initial;
}
