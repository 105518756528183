.searchBar,
.searchDropdown {
  float: left;
  display: inline-block;
  padding: 0 10px;
}

.searchBar {
  width: calc(70% - 20px);
}

.searchArea .sTitle {
  width: calc(100% - 20px);
  padding: 10px;
  font-size: 13px;
}

.searchArea .sTitle {
  width: calc(100% - 20px);
  padding: 10px;
  font-size: 13px;
}

input[type="text"].body_field {
  width: 100%;
  /* border: 2px solid #aaa; */
  /* border-radius: 4px; */
  font-size: 12px;
  border: none;
  /* margin: 8px 0; */
  outline: none;
  padding: 15px 40px 15px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  background: rgba(255, 255, 255, 0.13);
}

.searchArea input[type="text"].body_field {
  margin: 0;
  padding: 14px 50px 14px 15px;
}
.searchArea input[type="text"].body_field:focus {
  cursor: pointer;
}

.searchArea .search_field,
.searchArea .side_field {
  width: 100%;
  margin: 0;
}

.inputWithIcon {
  position: relative;
}

.searchDropdown {
  width: calc(30% - 20px);
}

@media screen and (max-width: 991px) {
  .searchBar {
    width: calc(100% - 20px);
  }

  .searchDropdown {
    width: calc(100% - 20px);
  }
}

@media screen and (max-width: 575px) {
  .searchBar {
    width: calc(100% - 20px);
  }

  .searchDropdown {
    width: calc(100% - 20px);
  }
}
