/*!* TAG *!*/
/*.tag {*/
/*    text-transform: lowercase;*/
/*    margin-right: 5px;*/
/*    margin-top: 5px;*/
/*    font-size: .8rem;*/
/*    padding: 5px 10px;*/
/*    vertical-align: middle;*/
/*    border-radius: 6px;*/
/*    }*/

/*!* MODIFIERS *!*/

/*.tag--DEVELOPMENT,*/
/*.tag--NEW {*/
/*    background: #FFDB7A;*/
/*    }*/

/*!* ------------------------------------- *!*/

/*.tag--ACTIVE {*/
/*    background: #006E80;*/
/*    color: #fff;*/
/*    }*/

/*!* ------------------------------------- *!*/

/*.tag--PRODUCTION,*/
/*.tag--PENDING {*/
/*    color: #fff;*/
/*    background: #142347;*/
/*    }*/

/*!* ------------------------------------- *!*/

/*.tag--OPEN {*/
/*    background: #4A4A4A;*/
/*    color: white;*/
/*    }*/

/*!* ------------------------------------- *!*/
/*.tag--BIG {*/
/*    text-transform: uppercase;*/
/*    }*/

/*!* ------------------------------------- *!*/

/*.tag--CLOSED {*/
/*    background: #9B9B9B;*/
/*    color: #fff;*/
/*    line-height: 1;*/
/*    text-decoration: line-through;*/
/*    }*/

/*.tag--FAILED {*/
/*    background: #E73A38;*/
/*    border-radius: 10px;*/
/*    color: white;*/
/*    }*/

.tagSpan {
  margin: 4px;
  display: inline-block;
}

.movingBrackets.right.SUCCESS {
  padding: 3px;
  color: #81c14b;
}

.movingBrackets.right.WARNING {
  padding: 3px;
  color: #fff77d;
}

/*@media screen and (max-width: 900px) {*/
/*    .tagSpan.SUCCESS {*/
/*        font-size: 0.8rem;*/
/*    }*/

/*    .tagSpan.WARNING {*/
/*        font-size: 0.8rem;*/
/*    }*/
/*}*/
