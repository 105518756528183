.faq_category {
  font-family: "Oswald";
  font-weight: 500;
  font-size: 15px;
  line-height: 1;
  position: relative;
  width: 100%;
  display: inline-block;
  margin-bottom: 1rem;
}
