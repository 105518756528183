.fileImportExport__fileItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 1rem;
  padding: 0.6rem 0;
}

.fileImportExport__fileItem .tryout-form__top {
  border: none;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  width: 300px;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.fileImportExport__head {
  text-align: right;
  margin-bottom: 1rem;
}

.fileImportExport__button {
  padding: 0.5rem 1rem;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: white;
  background: transparent;
  /*border: 2px solid #024a6c;*/
  border: 1px solid white;
  -webkit-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
  font-size: 0.8rem;
}

.fileImportExport__button:hover {
  color: white;
  background: #024a6c;
  border-color: #024a6c;
}

.fileImportExport__button--disabled {
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}

.fileImportExport__button--disabled:hover {
  cursor: not-allowed;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.fileImportExport__button--hidden {
  display: none;
}

.fileImportExport__button:active {
  -webkit-transform: translateY(1px);
  -ms-transform: translateY(1px);
  transform: translateY(1px);
}

.fileImportExport__button svg {
  width: 1rem;
  height: 1rem;
  margin-right: 10px;
  fill: currentColor;
}

.download__modal .modal__title {
  font-size: initial;
  font-weight: initial;
  color: initial;
}

.download__modal .modal__content {
  max-height: 80vh;
  overflow-y: auto;
}

.fileImportExport__fileItem .tryOutDescription {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: left;
  margin-left: auto;
  margin-right: 1rem;
  color: white;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  max-width: 100%;
}
