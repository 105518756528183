.hub_logo {
  width: 250px;
  margin: -14px 0 -14px -1px;
  float: left;
}

@media screen and (max-width: 1199px) {
  .hub_logo {
    width: 170px;
    margin: -10px 0 -10px -1px;
  }
}
