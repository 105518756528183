@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono);
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul.table {
  list-style-type: none;
  margin: 0 0 40px 0;
  padding: 0;
  width: 100%;
  display: table;
  position: relative;
}

.accordion_table ul.table {
  margin: 0;
}
.accordion_table ul.table.line {
  border-bottom: 1px solid #ffffff40;
}

.row {
  display: table-row;
  background: #ffffff0a;
}
.row.accord_title .cell {
  padding: 10px 12px;
}
.row.tHeader, .row.tFooter {
  font-weight: 900;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.13);
  -webkit-font-smoothing: antialiased;
}
.row.tHeader .cell, .row.tFooter .cell {
  padding: 30px 12px;
  font-weight: 500;
}
.row.tFooter {
  -webkit-clip-path: polygon(0% 0%, 0% 100%, calc(100% - 30px) 100%, 100% calc(100% - 30px), 100% 0);
          clip-path: polygon(0% 0%, 0% 100%, calc(100% - 30px) 100%, 100% calc(100% - 30px), 100% 0);
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.row.tFooter::after {
  content: "";
  position: absolute;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #ffffff40;
  width: 0px;
  height: 0px;
  left: 0px;
  bottom: 0px;
}
.row.tHeader:after {
  content: "";
  position: absolute;
  border-left: 20px solid transparent;
  border-top: 20px solid #ffffff40;
  width: 0px;
  height: 0px;
  right: 0px;
  top: 0px;
}

.cell {
  padding: 6px 12px;
  display: table-cell;
  font-weight: 500;
}
.cell button {
  background: #fff0;
  border: none;
  color: #fff;
  cursor: pointer;
  display: contents;
}
.cell button span.icon-arrow_down:before {
  color: #ffffff70;
  margin: 0 4px;
}

li.row:nth-child(2) .cell {
  padding: 16px 12px 8px 12px;
}
li.row:nth-last-child(2) .cell {
  padding: 8px 12px 16px 12px;
}

.arr_line_2 {
  font-size: 11px;
  margin: 4px 0 4px 22px;
}
.arr_line_2 span {
  margin: 0 10px 0 0;
}
.arr_line_2 span a {
  color: rgba(255, 255, 255, 0.8);
}

.accordion_table .cell {
  padding: 2px 12px;
  display: table-cell;
  width: 70%;
}
.accordion_table li.row:nth-child(2) .cell {
  padding: 2px 12px 2px 12px;
}
.accordion_table li.row:nth-last-child(2) .cell {
  padding: 2px 12px 2px 12px;
}
.accordion_table .arr_line_2 {
  font-size: 12px;
  margin: 4px 0 4px 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.table_icon {
  width: 20px;
  margin: 0;
  padding: 0;
}

.accordion_table .cell.accordion_column {
  width: 20px;
}
.accordion_table .cell.accordion_column button {
  display: flex;
}

.table_show_hide {
  display: block;
  width: 100%;
}

.nbg_table.accordion_table .array_descr br {
  display: none;
}

@media screen and (max-width: 767px) {
  ul.table {
    display: inline-block;
  }

  .row {
    padding: 10px 0 10px 0;
    display: block;
  }

  .row.line {
    border-bottom: 1px solid #ffffff40;
  }

  .row.tHeader,
.row.tFooter {
    height: 20px;
  }

  .cell,
li.row:nth-child(2) .cell,
li.row:nth-last-child(2) .cell,
.accordion_table .cell,
.accordion_table li.row:nth-last-child(2) .cell {
    padding: 2px 12px 2px 12px;
    display: block;
  }

  .row.tHeader .cell,
.row.tFooter .cell {
    display: none !important;
  }

  .row .cell {
    margin-bottom: 10px;
  }

  .row .cell:before {
    margin-bottom: 6px;
    content: attr(data-title);
    min-width: 98px;
    font-size: 10px;
    line-height: 10px;
    font-weight: bold;
    text-transform: uppercase;
    color: #ffffff40;
    display: block;
  }

  .accordion_table .cell.accordion_column {
    height: 0;
  }

  .arr_line_2 {
    margin: 4px 0 4px 0;
  }

  .accordion_table .cell {
    width: calc(100% - 24px);
  }

  .accordion_table .row .cell {
    margin-bottom: 0px;
  }

  .accord_title .cell:nth-child(1) {
    float: left;
    width: calc(100% - 68px);
  }

  .accord_title .cell:nth-child(2) {
    float: left;
  }

  .nbg_table.accordion_table .array_descr br {
    display: block;
  }
}
.table-like.top li.line {
  padding-top: 5px;
  border-bottom: 1px solid #ffffff40;
}

/*# sourceMappingURL=index.css.map */

@font-face {
  font-family: "icomoon";
  src: url(/static/media/icomoon.5a28950c.eot);
  src: url(/static/media/icomoon.5a28950c.eot#iefix)
      format("embedded-opentype"),
    url(/static/media/icomoon.db9cbee4.ttf) format("truetype"),
    url(/static/media/icomoon.a1a46440.woff) format("woff"),
    url(/static/media/icomoon.b5d0b961.svg#icomoon) format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icon_open:before {
  content: "\e937";
}
.icon-btn_captcha:before {
  content: "\e904";
  color: #024a6c;
}
.icon-icon_technologies_01:before {
  content: "\e91d";
  color: #fff77d;
}
.icon-icon_technologies_02:before {
  content: "\e91e";
  color: #fff77d;
}
.icon-icon_technologies_03:before {
  content: "\e91f";
  color: #fff77d;
}
.icon-icon_technologies_04:before {
  content: "\e920";
  color: #fff77d;
}
.icon-icon_technologies_05:before {
  content: "\e921";
  color: #fff77d;
}
.icon-icon_technologies_06:before {
  content: "\e922";
  color: #fff77d;
}
.icon-arrow_classic_down:before {
  content: "\e923";
}
.icon-arrow_classic_left:before {
  content: "\e924";
}
.icon-arrow_classic_right:before {
  content: "\e925";
}
.icon-arrow_classic_up:before {
  content: "\e926";
}
.icon-arrow_down:before {
  content: "\e927";
}
.icon-arrow_left:before {
  content: "\e928";
}
.icon-arrow_right:before {
  content: "\e929";
}
.icon-arrow_up:before {
  content: "\e92a";
}
.icon-btn_dislike:before {
  content: "\e92b";
}
.icon-btn_like:before {
  content: "\e92c";
}
.icon-icon_chat:before {
  content: "\e92d";
  color: #fff77d;
}
.icon-icon_folder:before {
  content: "\e92e";
  color: #fff77d;
}
.icon-profile_account:before {
  content: "\e92f";
}
.icon-profile_settings:before {
  content: "\e930";
}
.icon-profile_signout:before {
  content: "\e931";
}
.icon-search:before {
  content: "\e932";
}
.icon-social_fb:before {
  content: "\e933";
}
.icon-social_in:before {
  content: "\e934";
}
.icon-social_tw:before {
  content: "\e935";
}
.icon-social_yt:before {
  content: "\e936";
}

@font-face {
  font-family: "icomoon";
  src: url(/static/media/icomoon.5c2bdd8c.eot);
  src: url(/static/media/icomoon.5c2bdd8c.eot#iefix)
      format("embedded-opentype"),
    url(/static/media/icomoon.648a6062.ttf) format("truetype"),
    url(/static/media/icomoon.b0e9ed6f.woff) format("woff"),
    url(/static/media/icomoon.623b1296.svg#icomoon) format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icon_filter:before {
  content: "\e900";
  color: #fff77d;
}

.icon-icon_filter:before {
  content: "\e900";
  color: #fff77d;
}

footer {
  color: #8bb8ca;
}
footer a {
  color: rgba(255, 255, 255, 0.7);
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}
footer li:hover a {
  color: #fff;
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}
footer .container.footer {
  background: rgba(255, 255, 255, 0.13);
  padding: 0;
  font-size: 14px;
}
footer .box {
  display: table-cell;
  padding: 16px;
  vertical-align: middle;
}

.footer-left,
.footer-right {
  float: left;
  display: inline-block;
  position: relative;
}

.footer-left {
  width: 19.8%;
  border-right: 1px solid #4e8ba1;
}

.footer-right {
  width: 100%;
}
.footer-right .nbg_logo {
  margin: 0 auto;
  border-right: 1px solid #ffffff4f;
}
.footer-right .nbg_logo img {
  width: 180px;
  margin: 0 auto;
}
.footer-right .options {
  line-height: 30px;
  /* width: calc(45% - 80px); */
  border-right: 1px solid #ffffff4f;
}
.footer-right .newsletter {
  border-right: 1px solid #ffffff4f;
  /* width: calc(33% - 80px); */
  min-width: 214px;
  font-weight: 500;
}
.footer-right .social {
  min-width: 161px;
}

.footer-wrapper {
  padding: 50px 0px;
}

footer .col-box {
  display: table;
  width: 100%;
}

ul.footer-menu {
  list-style-type: none;
  -webkit-columns: 3;
          columns: 3;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  font-weight: 500;
}
ul.footer-menu a {
  text-transform: uppercase;
}
ul.footer-menu li:nth-child(1),
ul.footer-menu li:nth-child(2),
ul.footer-menu li:nth-child(3) {
  width: 115px;
}

.bordered a {
  border: 1px solid rgba(255, 255, 255, 0.7);
  padding: 5px;
}
.bordered a:hover {
  color: #fff;
}

.newsletter_txt1 {
  font-size: 15px;
}

.newsletter_txt2 {
  font-size: 20px;
}

.container .afterfooter {
  font-family: "Oswald";
  font-size: 11px;
  padding: 20px 0px 20px 0;
}

.copyrights {
  text-transform: uppercase;
  float: left;
}

.afterfooter > div {
  position: relative;
  width: 50%;
  display: inline-block;
}
.afterfooter p {
  padding: 0 10px;
}

.privacy-policy > div {
  position: relative;
  float: right;
  float: right;
  text-align: center;
}

.newsletter {
  text-transform: uppercase;
}

.footer-social {
  font-size: 16px;
  padding: 0;
  margin: 0;
}
.footer-social li {
  display: inline-block;
  padding: 5px;
  border: 1px solid rgba(255, 255, 255, 0.7);
  color: rgba(255, 255, 255, 0.7) !important;
  margin: 5px;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}
.footer-social li:hover {
  border: 1px solid #ffffff;
}

.fab .fa-facebook-f {
  padding: 0 4.9px;
}
.fab .fa-twitter {
  padding: 0 1px;
}
.fab .fa-linkedin-in {
  padding: 0 2px;
}
.fab .fa-youtube {
  padding: 0;
}

input .body_field:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #8bb8ca inset;
  -webkit-text-fill-color: #ffffff !important;
}

input .body_field {
  -webkit-text-fill-color: rgba(255, 255, 255, 0.8) !important;
}

/*** newsletter ***/
.newsletter .inputWithIcon {
  font-size: 14px !important;
}

input[type=text] .body_field {
  width: 100%;
  font-size: 12px;
  border: none;
  outline: none;
  padding: 15px 40px 15px 20px;
  box-sizing: border-box;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background: rgba(255, 255, 255, 0.13);
}

.inputWithIcon {
  position: relative;
}
.inputWithIcon input[type=text] {
  padding-right: 40px;
}
.inputWithIcon i {
  position: absolute;
  left: calc(100% - 53px);
  /* right: 5px; */
  top: 0px;
  padding: 15px 19px;
  color: #fff;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
}
.inputWithIcon input[type=text]:focus + i {
  color: #fff77d;
}

.search_field .inputWithIcon i {
  padding: 13px 21px;
  left: calc(100% - 60px);
}
.search_field .inputWithIcon i.icon-search {
  font-size: 18px;
}

.form-newsletter .inputWithIcon i {
  font-size: 14px;
  padding: 17px 17px;
}

.container.afterfooter {
  font-family: "Oswald";
  font-size: 11px;
  padding: 20px 0px 20px 0;
}

@media screen and (max-width: 1199px) {
  .footer-right .social {
    min-width: 82px;
    max-width: 116px;
  }
}
@media screen and (max-width: 991px) {
  footer .container.footer {
    font-size: 12px;
  }

  .footer-wrapper {
    padding: 0px 0px;
  }

  .footer-right .nbg_logo,
.footer-right .options,
.footer-right .newsletter {
    border-right: none;
    border-bottom: 1px solid #ffffff4f;
  }

  footer .box {
    display: block;
    padding: 16px 0;
    margin: 0 auto;
    width: 90% !important;
  }

  footer .desktopFooterLogo {
    display: none !important;
  }

  .footer-right .social {
    max-width: 162px;
    padding: 0;
  }

  .footer-social {
    font-size: 16px;
    margin: 20px 0 40px 0;
    padding-bottom: 20px;
  }

  footer .mobileFooterLogo {
    display: block !important;
    border-bottom: none !important;
    border-top: 1px solid #ffffff4f;
  }

  .footer-right .nbg_logo img {
    width: 140px;
  }
}
@media screen and (max-width: 767px) {
  .container.afterfooter {
    padding: 20px 0px 90px 0px;
  }

  .afterfooter > div {
    width: 100%;
    float: left;
    margin: 0;
    text-align: center;
  }

  .afterfooter p {
    padding: 0 10px;
  }

  .privacy-policy > div {
    position: relative;
    width: 50%;
    margin: 0;
    float: right;
  }
}
@media screen and (max-width: 575px) {
  .box.options {
    overflow: hidden;
  }

  ul.footer-menu {
    padding-left: 0;
    overflow: hidden;
    width: 349px;
  }
  ul.footer-menu li:nth-child(4),
ul.footer-menu li:nth-child(5),
ul.footer-menu i:nth-child(6) {
    margin-left: 20px;
    width: 90px;
  }
  ul.footer-menu li:nth-child(7) {
    margin-left: 10px;
    width: 45px;
  }
}
@media screen and (max-width: 350px) {
  ul.footer-menu {
    width: 310px;
  }
}

/*# sourceMappingURL=footer.css.map */



.newsletter {
  text-transform: uppercase;
}

input[type="email"].body_field {
  width: 100%;
  font-size: 12px;
  border: none;
  outline: none;
  padding: 15px 40px 15px 20px;
  box-sizing: border-box;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background: rgba(255, 255, 255, 0.13);
}
input.body_field {
  -webkit-text-fill-color: rgba(255, 255, 255, 0.8) !important;
}

hr.form_divider {
  border: 0;
  border-top: 1px solid #0e5379;
  margin-top: 8px;
  margin-bottom: 8px;
}

hr.divider {
  width: calc(100% + 100px);
  margin-left: -50px;
  overflow-x: hidden;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.22);
  margin-top: 0;
  margin-bottom: 0;
}

hr.divider.logo_dividerLeft {
  width: 32px;
  display: inline-block;
  float: left;
}

hr.divider.logo_dividerRight {
  width: calc(100% - 215px);
  display: inline-block;
  float: right;
  margin-right: -50px;
}

@media screen and (max-width: 1199px) {
  hr.divider.logo_dividerLeft {
    width: 32px;
  }

  hr.divider.logo_dividerRight {
    width: calc(100% - 134px);
    margin-right: -50px;
  }
}
@media screen and (max-width: 575px) {
  hr.divider {
    width: calc(100% + 20px);
    margin-left: -10px;
    overflow-x: hidden;
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.22);
    margin-top: 0;
    margin-bottom: 0;
  }

  hr.divider.logo_dividerLeft {
    width: 4px;
  }

  hr.divider.logo_dividerRight {
    width: calc(100% - 170px);
    margin-right: -10px;
  }
}

/*# sourceMappingURL=divider.css.map */

.user_header {
  z-index: 1002;
  height: 25px;
  background: #012a2f;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
}

.pre_menu {
  padding-top: 15px;
}

.after_menu {
  border-left: none;
  width: calc(100% - 99px);
}

@media screen and (max-width: 575px) {
  .after_menu {
    width: calc(100% - 19px);
  }

  .user_header {
    padding: 0 12px;
  }
  .user_header .usr_drop {
    right: 24px;
  }
}

/*# sourceMappingURL=header.css.map */

nav {
  width: calc(100% - 275px);
  padding: 0px 0;
  display: inline-block;
  list-style-type: none;
}
nav ul {
  float: right;
  margin: 26px 0px;
  padding: 0;
}
nav ul li {
  display: inline-block;
  float: left;
}
nav ul li:not(:first-child) {
  margin-left: 25px;
}
nav ul li a {
  display: inline-block;
  outline: none;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  letter-spacing: 0.04em;
}
nav ul li a:hover {
  color: #fff77d;
  text-decoration: none;
}

#nav:checked + .nav-open {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: fixed;
  right: 33px;
  top: 35px;
  z-index: 1003;
}
#nav:checked + .nav-open i {
  background: #fff;
  -webkit-transition: -webkit-transform 0.2s ease;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}
#nav:checked + .nav-open i:nth-child(1) {
  -webkit-transform: translateY(6px) rotate(180deg);
  transform: translateY(6px) rotate(180deg);
}
#nav:checked + .nav-open i:nth-child(2) {
  opacity: 0;
}
#nav:checked + .nav-open i:nth-child(3) {
  -webkit-transform: translateY(-10px) rotate(90deg);
  transform: translateY(-10px) rotate(90deg);
}
#nav:checked ~ .nav-container {
  z-index: 1002;
  opacity: 1;
}
#nav:checked ~ .nav-container ul li a {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.search_item {
  padding: 0 0 0 25px;
  margin-top: -20px;
}

/*# sourceMappingURL=navBar.css.map */

.hub_logo {
  width: 250px;
  margin: -14px 0 -14px -1px;
  float: left;
}

@media screen and (max-width: 1199px) {
  .hub_logo {
    width: 170px;
    margin: -10px 0 -10px -1px;
  }
}

@media screen and (min-width: 1200px) {
  .hub_logo.mobile,
.mobile-nav-container {
    display: none;
  }
}
@media screen and (max-width: 1199px) {
  .nav-container ul li.search_item {
    display: none;
  }

  nav {
    width: calc(100% - 169px);
  }

  nav ul {
    float: left;
  }

  .nav-container {
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    background: #012a2f;
    opacity: 0;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    overflow: hidden;
  }

  .nav-container ul {
    position: relative;
    width: 100%;
  }

  .nav-container ul li {
    display: block;
    float: none;
    width: 100%;
    text-align: center;
    margin: 2px 0;
  }

  .mobile-nav-container ul.mobile-bottom-menu {
    display: block;
    position: absolute;
    bottom: 0px;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .nav-container ul.desktop {
    display: none;
  }

  .mobile-nav-container {
    z-index: 10000;
    opacity: 1;
    height: 100vh !important;
  }

  .nav-container .hub_logo.mobile {
    position: absolute;
    top: 20px;
    left: 14px;
    display: block;
  }

  .mobile-icon {
    margin-left: 24px;
    width: 20px;
  }

  .mobile-seach-input {
    color: #fff;
    background: #8bb8ca00;
    padding: 3px 25px 5px 25px;
    width: calc(100% - 125px);
    border: none;
    cursor: pointer;
    font-family: "CF Asty Std";
    font-weight: 500;
    font-size: 18px;
  }

  .mobile-welcome {
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    font-family: "CF Asty Std";
  }

  .mobile-nav-container ul.mobile {
    display: block;
    position: absolute;
    top: 0px;
    padding: 90px 0 0 0;
    margin: 0;
  }

  .mobile-nav-container ul.mobile-bottom-menu li {
    border-top: 2px solid #284a50;
    width: 100%;
    margin: 0 !important;
    padding: 10px 0;
    text-align: left;
  }

  .nav-container ul li:not(:first-child) {
    margin-left: 0;
  }

  .nav-container ul li a {
    padding: 5px 0;
    opacity: 0;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  .nav-open {
    position: relative;
    float: right;
    right: 20px;
    top: 2px;
    display: block;
    width: 30px;
    height: 29px;
    padding: 8px 0;
    cursor: pointer;
    border-radius: 50%;
  }

  .nav-open i {
    display: block;
    width: 30px;
    height: 3px;
    background: #ffffff;
    /* border-radius: 2px; */
    margin: 0;
  }

  .nav-open i:nth-child(1) {
    margin-top: 5px;
  }

  .nav-open i:nth-child(2) {
    margin-top: 5px;
    opacity: 1;
  }

  .nav-open i:nth-child(3) {
    margin-top: 5px;
  }

  /**** search *****/
  .search {
    right: 0;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    padding: 5px 25px;
  }

  /**** search end *****/
  .nav-container ul li.search_mobile {
    display: table;
    margin: 0 0 0 0;
  }

  .nav-container ul li.mobile-signup {
    display: flex;
    justify-content: space-around;
    margin: 0 0 0 0;
    padding: 10px 0 10px 0;
    /* border: none; */
    /* border-top: 1px solid #315558; */
    border-bottom: 1px solid #315558;
  }

  .mobile-signup div {
    display: table-cell;
    text-align: center;
  }

  .mobile-signup div a {
    font-size: 10px !important;
    line-height: 18px;
    width: 70px;
  }

  .mobile-signup div span {
    font-size: 22px;
    margin-left: 10px;
    margin-right: 10px;
    color: #1c869d;
    display: block;
  }

  .mobile-signup div hr {
    display: none;
  }

  .prof-icon.icon-profile_settings,
.prof-icon.icon-profile_account {
    color: #1c869d;
  }

  .prof-icon.icon-profile_signout {
    color: #c1272d;
  }

  .profile_signup_signin_mobile {
    text-align: left !important;
    padding: 0 10px;
    width: calc(100% - 20px) !important;
  }

  .profile_signup_signin_mobile span {
    color: #ffffff2e !important;
    font-size: 18px !important;
  }

  .profile_signup_signin_mobile a {
    font-size: 14px !important;
    font-weight: 500 !important;
    padding: 0 10px !important;
  }

  .profile_signup_signin_mobile {
    display: block !important;
    margin: 0 !important;
    padding: 30px 10px;
    border-bottom: 1px solid #315558;
  }

  /***************** **********/
  .main_menu {
    /*display:none;*/
  }

  .mobile_main_menu {
    /*display:block;*/
  }

  .emptyitem {
    padding: 20px 0;
  }
}
/*Medium devices (landscape phones, less than 992px)*/
@media screen and (max-width: 991px) {
  nav {
    width: calc(100% - 169px);
  }
}
/*Small devices (landscape phones, less than 768px)*/

/*# sourceMappingURL=navBarMobile.css.map */

.container {
  width: calc(100% - 100px);
  margin: 0 auto;
  border-right: 1px solid rgba(255, 255, 255, 0.22);
  border-left: 1px solid rgba(255, 255, 255, 0.22);
  padding: 20px 0px 0 0;
  position: relative;
}

.containerInner {
  margin: 0 auto;
}

.grig_bg {
  background-color: #0092ff00;
  background-image: linear-gradient(rgba(255, 255, 255, 0.08) 1px, transparent 1px), linear-gradient(90deg, rgba(255, 255, 255, 0.08) 1px, transparent 1px), linear-gradient(rgba(255, 255, 255, 0.05) 1px, transparent 1px), linear-gradient(90deg, rgba(255, 255, 255, 0.05) 1px, transparent 1px);
  background-size: 100px 100px, 100px 100px, 20px 20px, 20px 20px;
  background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;
}

@media screen and (max-width: 575px) {
  .container {
    width: calc(100% - 20px);
  }

  .containerInner {
    width: 100%;
  }
}

/*# sourceMappingURL=container.css.map */

.bracketButton {
  font-weight: 500;
}
.bracketButton.inline {
  margin: 0 4px;
  display: inline-flex;
}
.bracketButton.secondary a {
  color: #5090ad;
}
.bracketButton.secondary button {
  color: #5090ad;
}
.bracketButton .fas.fa-caret-right {
  margin: 0 4px 0 2px;
}

.movingBrackets {
  font-size: 14px;
  display: inline-block;
  background: none;
  cursor: pointer;
  border: none;
  color: #fff77d;
  text-align: center;
  float: left;
  padding: 0 6px;
}
.movingBrackets a {
  color: inherit;
}
.movingBrackets::before,
.movingBrackets::after {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  position: absolute;
}
.movingBrackets::before {
  content: "[ ";
  margin: 0px 0px 0px -8px;
}
.movingBrackets::after {
  content: " ]";
  margin: 0px 0px 0px 3px;
}
.movingBrackets:hover::before {
  margin: 0px 0px 0px -12px;
}
.movingBrackets:hover::after {
  margin: 0px 0px 0px 7px;
}

@media screen and (max-width: 991px) {
  a.movingBrackets.right {
    text-align: center;
    float: left;
  }

  a.movingBrackets {
    padding: 0;
  }
}

/*# sourceMappingURL=bracketButton.css.map */

a.btn {
  text-decoration: none;
}

.btn {
  outline: none;
  padding: 10px 25px;
  display: inline-block;
  cursor: pointer;
  font-weight: 400;
  border: none;
  background: none;
}

.electro.btn {
  outline-color: rgba(255, 247, 125, 0.6) !important;
}

.electro.btn:before {
  content: "";
  position: absolute;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  top: 50%;
  right: -6px;
  width: 4px;
  height: 27px;
  background-color: #fff77d;
  z-index: 1;
}

.electro.btn:after {
  content: "";
  position: absolute;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  top: 50%;
  left: -6px;
  width: 4px;
  height: 27px;
  background-color: #fff77d;
  z-index: 1;
}

.simple_btn.btn {
  padding: 10px 60px;
  background: #024a6c;
  border: none;
  outline-offset: 3px;
  outline: 1px solid rgba(255, 255, 255, 0.25);
  margin: 4px;
  font-family: "CF Asty Std";
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  position: relative;
}

.tag {
  position: relative;
  padding: 14px 50px 14px 20px;
  margin: 6px 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.13);
  border: none;
  font-family: "CF Asty Std";
  font-weight: 500;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  text-align: left;
}

.tag:after {
  content: attr(data-tag_num);
  position: absolute;
  right: 20px;
  color: #fff77d;
}

@media screen and (max-width: 480px) {
  .simple_btn .btn,
.simple_btn.btn {
    font-size: 17px;
  }
}
.btn[disabled],
input.btn[disabled] {
  cursor: not-allowed !important;
  opacity: 0.7;
}

/*# sourceMappingURL=button.css.map */

.section_title {
}

.section_title .hyper {
  font-size: 100px;
}

.sub_title {
  font-family: "Oswald";
  font-weight: 500;
  font-size: 22px;
  line-height: 1;
  letter-spacing: -0.02em;
  /* margin: 0; */
  display: inline-block;
}

.small_title {
  font-family: "Oswald";
  font-weight: 500;
  font-size: 15px;
  line-height: 1;
  margin: 20px 0 0 0;
  position: relative;
  width: 100%;
  display: inline-block;
}

.descr_title {
  margin: 0px 0 50px 0;
}

.descr_title span {
  font-size: 11px;
}

.descr_title span {
  font-family: "CF Asty Std";
  font-weight: normal;
  font-size: 14px;
  line-height: 0;
  margin: 0;
  color: #ffffff;
}

@media screen and (max-width: 991px) {
  .descr_title {
    margin: 0px 0 10px 0;
  }
}

@media screen and (max-width: 480px) {
  .descr_title {
    margin: 0 0 10px 0;
    font-size: 15px;
  }

  .descr_title span {
    font-size: 11px;
  }

  .sub_title {
    font-size: 15px;
  }
}

h1.folowed_by_subtitle,
h2.folowed_by_subtitle {
  margin: 0px 0px 0px 0px;
}

h1,
h2 {
  font-family: "Oswald";
  font-weight: 500;
  letter-spacing: -2px;
  font-size: 50px;
  line-height: 50px;
  margin: 0px 0px 50px 0px;
}
@media screen and (max-width: 991px) {
  h1,
h2 {
    margin: 0px 0px 0px 0px;
  }
}
@media screen and (max-width: 480px) {
  h1,
h2 {
    margin: 0px 0px 0px 0px;
    font-size: 41px;
    line-height: 1em;
  }
}

/*# sourceMappingURL=heading.css.map */

.simple_btn .btn {
  padding: 10px 60px;
  background: #024a6c;
  border: none;
  outline-offset: 3px;
  outline: 1px solid rgba(255, 255, 255, 0.25);
  margin: 4px;
  font-family: "CF Asty Std";
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  position: relative;
}

.simple_btn .btn:hover {
  background: #1c869d;
}

.simple_btn.left {
  float: left;
}

.simple_btn.right {
  float: right;
}

/*# sourceMappingURL=simpleButtonWrapper.css.map */

.yellow {
  color: #fff77d;
}

#nbg_effect_area {
  padding-bottom: 47%;
  margin-left: -50px;
  overflow: hidden;
  margin-top: -39px;
  position: relative;
}
#nbg_effect_area iframe {
  background-color: transparent;
  overflow: hidden;
  border: 0;
  z-index: 999;
  position: absolute;
  height: 0;
  max-height: 100%;
  max-width: 100%;
  min-height: 100%;
  min-width: 100%;
  width: 0;
  top: 0;
  left: 0;
}
#nbg_effect_area .home-page-title {
  position: absolute;
  z-index: 1000;
  top: 38%;
  -webkit-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  left: 80px;
  display: inline-block;
}
#nbg_effect_area .bracketButton {
  margin: 16px 0 16px 7px;
  width: 190px;
  display: inline-block;
}
#nbg_effect_area .bracketButton a.movingBrackets {
  float: none;
}
#nbg_effect_area .hero-actions {
  display: flex;
  flex-wrap: wrap;
}

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1199px) {
  .home-page-title {
    position: relative;
    z-index: 1000;
    top: auto;
    -webkit-transform: none;
    transform: none;
  }

  #nbg_effect_area .section_title h1 {
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
    margin: 0 0 10px 0;
  }
  #nbg_effect_area .section_title h1 .hyper {
    font-size: 60px;
  }
}
@media screen and (max-width: 2300px) {
  .home-race-logo-wrapper {
    width: 534px;
  }

  .home-page-title p {
    margin-top: 5px;
    margin-bottom: 12px;
    font-size: 17px;
    line-height: 25px;
  }
}
@media screen and (max-width: 1850px) {
  .home-race-logo-wrapper {
    width: 466px;
  }
}
@media screen and (max-width: 991px) {
  #nbg_effect_area {
    padding-bottom: 50%;
    width: calc(100% + 100px);
    margin-left: -50px;
    overflow: hidden;
    margin-top: -39px;
    position: relative;
  }

  #nbg_effect_area .home-page-title {
    top: 50%;
    -webkit-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    left: 80px;
  }
}
@media screen and (max-width: 767px) {
  #nbg_effect_area {
    padding-bottom: 73%;
    width: calc(100% + 100px);
    margin-left: -50px;
    overflow: hidden;
    margin-top: -39px;
    position: relative;
  }
}
@media screen and (max-width: 575px) {
  #nbg_effect_area {
    padding-bottom: 75%;
    width: calc(100% + 20px);
    margin-left: -10px;
    overflow: hidden;
    margin-top: -39px;
    position: relative;
  }
  #nbg_effect_area .home-page-title {
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    left: 30px;
    max-width: 185px;
  }
}
@media only screen and (max-width: 480px) {
  #nbg_effect_area {
    padding-bottom: 90%;
    margin-left: -10px;
    overflow: hidden;
    margin-top: -39px;
    position: relative;
  }
  #nbg_effect_area .section_title h1 {
    letter-spacing: -2px;
    font-size: 38px;
    line-height: 38px;
    margin: 0 0 0 0;
  }
  #nbg_effect_area .section_title h1 .hyper {
    font-size: 45px;
    font-weight: 500;
    letter-spacing: -2px;
  }
}
@media only screen and (max-width: 374px) {
  #nbg_effect_area {
    padding-bottom: 100%;
  }
  #nbg_effect_area .section_title h1 {
    font-size: 28px;
    line-height: 32px;
    font-weight: 500;
    margin: 0 0 0 0;
  }
  #nbg_effect_area .section_title h1 .hyper {
    font-size: 36px;
    letter-spacing: -2px;
  }
}

/*# sourceMappingURL=heroArea.css.map */

.awards-section {
  position: absolute;
  left: 80px;
  top: 78%;
  display: flex;
  z-index: 1000;
}
.awards-section a {
  cursor: pointer;
  margin-right: 16px;
}
.awards-section a img {
  max-width: 80px;
}
@media screen and (max-width: 575px) {
  .awards-section {
    right: calc(10% + -20px);
    bottom: 0;
    top: auto;
    top: initial;
    left: auto;
    left: initial;
  }
  .awards-section a {
    margin-bottom: 8px;
    margin-right: 0;
    margin-left: 8px;
  }
}
@media screen and (max-width: 485px) {
  .awards-section a {
    margin-bottom: 8px;
    margin-right: 0;
  }
}
@media screen and (max-width: 400px) {
  .awards-section a {
    margin-bottom: 8px;
    margin-left: 8px;
  }
}
@media screen and (max-width: 375px) {
  .awards-section {
    flex-direction: column;
  }
  .awards-section a {
    margin-right: 0;
    margin-left: 0;
  }
}

/*# sourceMappingURL=awards.css.map */

.animation-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}
.animation-container__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.animation-container__images {
  left: 57.6%;
  top: 14%;
  z-index: 1001;
  position: absolute;
  width: 100%;
  border: 0;
}
.animation-container__action {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  background: transparent;
  border: none;
}
.animation-container__action svg {
  width: 16px;
}
.animation-container__action svg path {
  fill: white;
}
.animation-container__action--next {
  position: absolute;
  cursor: pointer;
  left: 21.5%;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.animation-container__action--next:hover {
  -webkit-transform: translateY(-50%) translateX(10%);
          transform: translateY(-50%) translateX(10%);
}
.animation-container__action--previous {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-100%);
          transform: translateY(-50%) translateX(-100%);
  left: 0;
  cursor: pointer;
}
.animation-container__action--previous:hover {
  -webkit-transform: translateY(-50%) translateX(-110%);
          transform: translateY(-50%) translateX(-110%);
}
.animation-container__action--previous:focus {
  outline: none;
}

#triangle_images {
  width: 21.5%;
  overflow: hidden;
  display: flex;
}

.triangle {
  flex: 1 0 100%;
}
.triangle img {
  width: 100%;
  height: auto;
}

/*# sourceMappingURL=heroAnimation.css.map */

.raceTitleShape {
  position: relative;
  overflow: hidden;
  margin: 4px;
}

.raceTitleShape:before,
.raceTitleShape:after {
  position: absolute;
  content: "";
  top: 0px;
  height: 100%;
  z-index: 0;
}

.raceTitleShape:before {
  right: 0px;
  top: 20px;
  width: 20px;
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom;
  -webkit-transform: skewY(45deg);
  transform: skewY(45deg);
}

.raceTitleShape:after {
  right: 20px;
  width: calc(100% - 20px);
  border-left: none;
}

.raceTitleShape:after,
.raceTitleShape:before {
  background: #1b2c4b;
}

.race_head {
  display: table;
  width: calc(100% - 28px); /* 28 is margin & padding left-right total */
  padding: 4px 10px;
  margin: 10px 4px;
  position: relative;
  z-index: 1;
}

.race_head-box {
  display: table-cell;
  padding: 5px;
  vertical-align: middle;
}

.race_head-box:nth-child(1) {
  width: 50%;
}

.race_head-box:nth-child(2) {
  width: 210px;
  min-width: 194px;
}

.race_head-box:nth-child(3) {
  width: 110px;
  min-width: 100px;
}

.race_alingment {
  display: table;
}

.race_alingment-box {
  display: table-cell;
  vertical-align: bottom;
}

.race_head_left {
  width: 90px;
}

.race_head_right {
  width: 250px;
  font-size: 12px;
}

.race_logo {
  width: 180px;
}

.race_txt {
  margin: -4px 10px;
  min-width: 240px;
  color: rgba(255, 255, 255, 0.7);
}

.race_head_top {
  width: 200px;
  margin: 0 auto;
  /* float: right; */
}

.race_head_bottom {
  text-align: center;
  top: 0;
  font-size: 28px;
  font-family: "Oswald";
  font-weight: 500;
  margin: 0 auto;
}

.race_head_bottom p {
  margin: 0;
}

.fade1 {
  padding: 4px;
  margin: 4px; /* background: #0a567d; */ /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1b2d4c+1,ff8989+100 */
  background: #1b2d4c80; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(
    linear,
    left top, right top,
    color-stop(1%, #1b2d4c80),
    to(#1b2d4c30)
  );
  background: linear-gradient(
    to right,
    #1b2d4c80 1%,
    #1b2d4c30 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1b2d4c', endColorstr='#ff8989', GradientType=1); /* IE6-9 */
}

.fade2 {
  padding: 4px;
  margin: 4px 4px 20px 4px; /* background: #0a567d4d; */
  background: #1b2d4c80;
  background: -webkit-gradient(linear, left top, right top, color-stop(1%, #1b2d4c40), to(#1b2d4c12));
  background: linear-gradient(to right, #1b2d4c40 1%, #1b2d4c12 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1b2d4c', endColorstr='#ff8989', GradientType=1);
}

@media screen and (max-width: 991px) {
  .race_head-box {
    display: inline-block;
    float: left;
    width: 100%;
    padding: 15px 0;
  }

  .race_head-box:nth-child(1) {
    width: 50%;
  }

  .race_alingment {
    display: unset;
  }

  .race_head_left {
    margin: 0 auto;
    width: 100%;
  }

  .race_alingment-box {
    display: inline-block;
    margin: 0 auto;
    width: 100%;
  }

  .race_head_right {
    /* width: 100%; */
    margin: 0 auto;
    font-size: 12px;
  }

  .race_logo {
    width: 200px;
    /* margin: 0 auto; */
  }

  .race_txt {
    /* margin: 10px auto 0 auto; */
    margin: 0px;
    width: 240px;
    text-align: center;
    text-align: left;
  }

  .race_head-box:nth-child(2) {
    width: calc(50% - 30px);
    position: absolute;
    /* left: 0px; */
    right: 15px;
    padding: 15px 0;
    /* top: 26px; */
  }

  .race_head_top {
    /* float: right; */
    right: 0;
    /* top: 6px; */
    /* position: absolute; */
    width: 200px;
  }

  .race_head_bottom {
    /* text-align: right; */
    /* float: right; */
    font-size: 24px;
  }

  .race_head-box:nth-child(3) {
    width: 100%;
    padding: 0;
  }

  a.movingBrackets {
    padding: 0;
  }
}

@media screen and (max-width: 575px) {
  .race_logo {
    width: 120px;
    /* margin: 0 auto; */
  }

  .race_head_top {
    /* float: right; */
    right: 0;
    top: 6px;
    position: absolute;
    width: 130px;
  }

  .race_head_bottom {
    text-align: right;
    /* float: right; */
    font-size: 18px;
  }

  .race_head-box a.movingBrackets {
    text-align: right;
  }
}

.search_field,
.side_field {
  width: 100%;
  font-size: 14px;
  margin: 6px 6px 6px 0px;
}

@media screen and (max-width: 991px) {
  #custom_mobile_position_link {
    text-align: right;
    position: relative;
    margin-top: -35px;
  }
}

@media screen and (max-width: 480px) {
  #custom_mobile_position_link {
    text-align: right;
    margin-top: -35px;
  }
}

.sidebar {
  /* width: 100%; */
  /* margin: 14px; */
  float: left;
}

.sidebar.left {
  width: calc(25% - 40px);
  /* width: 310px; */
  padding: 0 20px;
}

.sidebar.left .view_all .bracketButton {
  margin: 10px 0;
}

.sidebar.left .view_all .bracketButton {
  margin: 10px 0;
}

.sidebar.right .small_title,
.array_title {
  font-family: "Oswald";
  font-weight: 500;
  font-size: 25px;
  line-height: 1;
  letter-spacing: -2px;
  margin: 10px 0 0 0;
  padding: 0;
}

.sidebar.right {
  width: calc(75% - 40px);
  /* width: calc(100% - 310px); */
  margin-bottom: 20px;
  padding: 0 20px;
  margin-bottom: 60px !important;
}

.sidebar.right .array_descr {
  margin: 8px 0;
  padding: 0px 16px;
  float: left;
}

.sidebar.right .array_descr span.path_big {
  margin: 2px 0 6px 0;
  /* padding: 0 16px; */
  font-size: 25px;
  letter-spacing: -0.05em;
  line-height: 1em;
  font-family: "Oswald";
  font-weight: 500;
}

.sidebar.right .news {
  display: inline-block;
  float: left;
}

.sidebar.right .news {
  display: inline-block;
  float: left;
  margin: 4px 0;
}

.area_of.titles {
  /* width: calc(100% - 20px); */
  /* padding: 0 10px; */
  width: 100%;
  /* margin: 53px; */
  position: relative;
}

.area_of.view_all {
  width: 100%;
}

.area_of {
  /*display: inline-block;*/
  /*float: left;*/
  /* width: 100%; */
}

.left-sidebar-button-wrapper {
  /* margin-top: 30px; */
  width: 100%;
  position: relative;
}

.left-sidebar-button-wrapper .btn {
  margin: 0 !important;
  outline: 0;
  padding: 14px 60px 14px 20px !important;
  width: 100%;
  text-align: left;
}

.ApiButtons {
  width: 100%;
  padding: 0;
  /* margin: 10px 0; */
  margin: 6px 0;
}

@media screen and (max-width: 991px) {
  .sidebar {
    margin-bottom: 20px;
  }

  .sidebar.left {
    width: calc(100% - 40px);
    /* display: table; */
  }

  .sidebar.right {
    width: calc(100% - 40px);
    /* width: 100%; */
  }

  .sidebar.right .array_descr {
    margin: 8px 0;
    padding: 0 16px;
    float: left;
    width: calc(100% - 32px);
  }

  .area_of.titles {
    /* position: relative; */
    /* width: calc(100% - 20px); */
  }

  .area_of.view_all {
    width: 100%;
    vertical-align: bottom;
    /* width: calc(100% - 20px); */
  }
}

@media screen and (max-width: 767px) {
  .sidebar.right .array_descr {
    float: none;
    display: inline-block;
    float: left;
    width: 100%;
    padding: 0;
  }
}

/*!* TAG *!*/
/*.tag {*/
/*    text-transform: lowercase;*/
/*    margin-right: 5px;*/
/*    margin-top: 5px;*/
/*    font-size: .8rem;*/
/*    padding: 5px 10px;*/
/*    vertical-align: middle;*/
/*    border-radius: 6px;*/
/*    }*/

/*!* MODIFIERS *!*/

/*.tag--DEVELOPMENT,*/
/*.tag--NEW {*/
/*    background: #FFDB7A;*/
/*    }*/

/*!* ------------------------------------- *!*/

/*.tag--ACTIVE {*/
/*    background: #006E80;*/
/*    color: #fff;*/
/*    }*/

/*!* ------------------------------------- *!*/

/*.tag--PRODUCTION,*/
/*.tag--PENDING {*/
/*    color: #fff;*/
/*    background: #142347;*/
/*    }*/

/*!* ------------------------------------- *!*/

/*.tag--OPEN {*/
/*    background: #4A4A4A;*/
/*    color: white;*/
/*    }*/

/*!* ------------------------------------- *!*/
/*.tag--BIG {*/
/*    text-transform: uppercase;*/
/*    }*/

/*!* ------------------------------------- *!*/

/*.tag--CLOSED {*/
/*    background: #9B9B9B;*/
/*    color: #fff;*/
/*    line-height: 1;*/
/*    text-decoration: line-through;*/
/*    }*/

/*.tag--FAILED {*/
/*    background: #E73A38;*/
/*    border-radius: 10px;*/
/*    color: white;*/
/*    }*/

.tagSpan {
  margin: 4px;
  display: inline-block;
}

.movingBrackets.right.SUCCESS {
  padding: 3px;
  color: #81c14b;
}

.movingBrackets.right.WARNING {
  padding: 3px;
  color: #fff77d;
}

/*@media screen and (max-width: 900px) {*/
/*    .tagSpan.SUCCESS {*/
/*        font-size: 0.8rem;*/
/*    }*/

/*    .tagSpan.WARNING {*/
/*        font-size: 0.8rem;*/
/*    }*/
/*}*/

/*SQUARE*/
.square {
  float: left;
  position: relative;
  width: 31%;
  /* min-width: 295px; */
  /* min-height: 0; */
  padding-bottom: 31%;
  margin: 10px;
  /* background-color: #1E1E1E; */
  border: 1px solid #5090ad;
  overflow: hidden;
}

.square_content {
  position: absolute;
  height: 90%;
  width: 90%;
  padding: 5%;
}

.square_table {
  display: table;
  width: 100%;
  height: 100%;
}

.square_table-cell {
  display: table-cell;
  vertical-align: middle;
}

.choise_img {
  width: 60px;
  margin: 0 auto;
}

.choise_img svg {
  width: 100% !important;
  fill: white;
  height: auto;
  max-width: 50px;
}

.choise_title h3 {
  text-align: center;
  text-transform: uppercase;
  font-family: "Oswald";
  font-weight: 500;
  font-size: 25px;
  margin: 16px;
}

.choise_descr {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  text-align: center;
  height: 0;
}

.square_table .bracketButton {
  position: absolute;
  bottom: 15px;
}

.square a.movingBrackets {
  color: rgba(255, 255, 255, 0.8);
}

.choise_more {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

/* *************** plakidio ******************** */
.plakidio .choise_more {
  bottom: 10px;
  right: 0px;
}
.plakidio .bracketButton {
  bottom: 20px !important;
  position: absolute;
  /* bottom: 10px; */
  left: 10px;
  margin: 6px;
  width: 100%;
}
.plakidio a.movingBrackets {
  color: #5090ad;
}

.plakidio_content {
  position: absolute;
  height: 90%;
  /* = 100% - 2*5% padding */
  width: 90%;
  /* = 100% - 2*5% padding */
  /* margin-top: -90px; */
  padding: 5%;
}

.plakidio_move {
  margin-top: -90px;
}

.plakidio .choise_img {
  /* WebKit */
  -webkit-transition: all 0.5s ease;
  /* Opera */
  /* Standard */
  transition: all 0.5s ease;
}

.plakidio .choise_title {
  color: #fff77d;
}

.plakidio .choise_title h3 {
  font-weight: 600;
  line-height: 1.2em;
}

.plakidio_table {
  display: table;
  width: 100%;
  height: 100%;
}

.plakidio_table-cell {
  display: table-cell;
  vertical-align: middle;
}

.plakidio {
  float: left;
  position: relative;
  width: 303px;
  min-height: 0;
  padding-bottom: 200px;
  /* = width for a 1:1 aspect ratio */
  margin: 10px;
  border-left: 1px solid rgba(255, 255, 255, 0.22);
  border-bottom: 1px solid rgba(255, 255, 255, 0.22);
  overflow: hidden;
}
.plakidio:before, .plakidio:after {
  position: absolute;
  content: "";
  top: 0px;
  height: 100%;
  z-index: -1;
}
.plakidio:after {
  right: 0px;
  top: 20px;
  width: 20px;
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom;
  -webkit-transform: skewY(45deg);
  transform: skewY(45deg);
  border-top: 1px solid rgba(255, 255, 255, 0.22);
  border-right: 1px solid rgba(255, 255, 255, 0.22);
}
.plakidio:before {
  right: 20px;
  width: calc(100% - 20px);
  border-top: 1px solid rgba(255, 255, 255, 0.22);
}

.blocked {
  color: black !important;
  cursor: not-allowed !important;
}

@media screen and (min-width: 1400px) {
  .plakidio {
    width: calc(33% - 19px);
    /* padding-bottom: 165px; */
    /* = width for a 1:1 aspect ratio */
    padding-bottom: 20%;
  }
}
@media screen and (max-width: 1399px) {
  .plakidio {
    width: calc(50% - 21px);
    /* padding-bottom: 165px; */
    /* = width for a 1:1 aspect ratio */
  }
}
/********************************************************************************************/
.hide_item {
  display: none;
}

@media screen and (max-width: 1399px) {
  .square {
    width: calc(50% - 22px);
    padding-bottom: calc(50% - 22px);
  }

  .choise_title h3 {
    margin: 8px;
  }

  .plakidio {
    width: calc(50% - 21px);
    /* padding-bottom: 165px; */
  }
}
@media screen and (max-width: 991px) {
  .choises {
    margin: 0 auto;
  }
}
@media screen and (max-width: 767px) {
  .choises {
    margin: 0 auto;
  }

  .square {
    width: calc(100% - 20px);
    padding-bottom: calc(100% - 20px);
  }

  .plakidio {
    width: calc(100% - 20px);
    /* padding-bottom: 165px; */
  }
}

/*# sourceMappingURL=squarePreview.css.map */

.corWrapper {
  width: 100%;
  text-align: center;
}

.corButton {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin: 8px;
}

.corButton span {
  display: block;
  padding: 7px;
  font-size: 14px;
  color: #fff77d;
  font-weight: 500;
  text-transform: uppercase;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.corButton.in .corners {
  position: absolute;
  width: 100%;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
}

.corButton.in .corners.bottom {
  bottom: 14px;
}

.corButton.in .corners.bottom:before {
  /* border-width: 0 0 1px 1px; */
  border: none;
}

.corButton.in .corners:before,
.corButton.in .corners:after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-color: #fff77d;
  border-style: solid;
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.corButton.in .corners.bottom:after {
  border-width: 0 2px 2px 0;
}

.corButton.in .corners:after {
  right: 0px;
}

.corButton.in:hover .corners.bottom {
  bottom: 12px;
}

@media only screen and (max-width: 480px) {
  .corButton span {
    font-size: 15px;
  }
}

/*animation*/

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeOutUp {
  0% {
    /*opacity: 1;*/
    display: none;
  }

  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.cr {
  width: 120px;
  padding: 8px 0;
  position: absolute;
  text-align: center;
  color: #f0f0f0;
  /*color: #fff77d;*/
  /*color: rgba(255, 255, 255, 0.7);*/
  font-size: 0.55rem;
  /*background: #ffffff21;*/
  background: #024a6c;
  /*border: 1px solid #024a6c ;*/
}

.cr-sticky {
  position: fixed;
}

/* Positions */

.cr-top.cr-left {
  top: 28px;
  left: 28px;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
          transform: translateX(-50%) translateY(-50%) rotate(-45deg);
}

.cr-left.cr-bottom {
  bottom: 28px;
  left: 28px;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
          transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

.cr-top.cr-right {
  top: 28px;
  right: 28px;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-transform: translateX(50%) translateY(-50%) rotate(45deg);
          transform: translateX(50%) translateY(-50%) rotate(45deg);
}

.cr-bottom.cr-right {
  bottom: 28px;
  right: 28px;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-transform: translateX(50%) translateY(50%) rotate(-45deg);
          transform: translateX(50%) translateY(50%) rotate(-45deg);
}

/* Colors */

.cr-white {
  background-color: #f0f0f0;
  color: #444;
}
.cr-black {
  background-color: #333;
}
.cr-grey {
  background-color: #999;
}
.cr-blue {
  background-color: #39d;
}
.cr-green {
  background-color: #2c7;
}
.cr-turquoise {
  background-color: #1b9;
}
.cr-purple {
  background-color: #95b;
}
.cr-red {
  background-color: #e43;
}
.cr-orange {
  background-color: #e82;
}
.cr-yellow {
  background-color: #ec0;
}


.pagination {
  font-family: "Oswald";
  width: 100%;
  margin: 0px auto 0 auto;
  display: flex;
  padding-top: 20px;
}

.pagination ul {
  padding: 0;
  margin: 0 auto;
}

.pagination ul li {
  display: inline-block;
  float: left;
  margin: 4px 2px;
  padding: 6px;
  background: rgba(255, 255, 255, 0.13);
  width: 20px;
  text-align: center;
  font-size: 13px;
  cursor: pointer;
}

.pagination ul li a {
  /* margin: 10px; */
  padding: 14px 0;
  color: #000;
}

.pagination ul li:nth-child(1),
.pagination ul li:nth-last-child(1) {
  background: none;
}

.pagination ul li:nth-child(1),
.pagination ul li:nth-child(2),
.pagination ul li:nth-last-child(2),
.pagination ul li:nth-last-child(1) {
  margin: 6px 2px;
  padding: 4px;
  font-size: 15px;
}

.pagination ul li:nth-child(1) {
  width: 70px;
}

.pagination ul li:nth-child(1) {
  position: relative;
  overflow: hidden;
}

.pagination ul li:nth-child(1):before,
.pagination ul li:nth-child(1):after {
  position: absolute;
  content: "";
  top: 0px;
  height: 100%;
  z-index: -1;
}

.pagination ul li:nth-child(1):before {
  left: 0px;
  top: 0px;
  width: 20px;
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom;
  -webkit-transform: skewY(-45deg);
  transform: skewY(-45deg);
}

.pagination ul li:nth-child(1):after,
.pagination ul li:nth-child(1):before {
  background: rgba(255, 255, 255, 0.13);
}

.pagination ul li:nth-child(1):after {
  right: 0;
  width: calc(100% - 20px);
  border-left: none;
}

.pagination ul li:nth-child(2) {
  width: 100px;
}

.pagination ul li:nth-last-child(2) {
  width: 100px;
}

.pagination ul li:nth-last-child(1) {
  width: 70px;
}

.pagination ul li:nth-last-child(1) {
  position: relative;
  overflow: hidden;
}

.pagination ul li:nth-last-child(1):before,
.pagination ul li:nth-last-child(1):after {
  position: absolute;
  content: "";
  top: 0px;
  height: 100%;
  z-index: -1;
}

.pagination ul li:nth-last-child(1):before {
  right: 20px;
  width: calc(100% - 20px);
  border-left: none;
}

.pagination ul li:nth-last-child(1):after,
.pagination ul li:nth-last-child(1):before {
  background: rgba(255, 255, 255, 0.13);
}

.pagination ul li:nth-last-child(1):before,
.pagination ul li:nth-last-child(1):after {
  position: absolute;
  content: "";
  top: 0px;
  height: 100%;
  z-index: -1;
}

.pagination ul li:nth-last-child(1):after {
  right: 0px;
  top: -20px;
  width: 20px;
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom;
  -webkit-transform: skewY(-45deg);
  transform: skewY(-45deg);
}

.pagination .active {
  color: #fff;
  cursor: not-allowed;
}

.pagination .active a {
  color: #fff;
  cursor: not-allowed;
}

.pagination .blocked {
  cursor: auto !important;
  cursor: initial !important;
  background: rgba(128, 128, 128, 0.7);
}

.pagination ul .page {
  width: inherit !important;
  padding: 0px !important;
}
.pagination ul .page a {
  display: inline-block;
  float: left;
  padding: 6px;
  background: rgba(255, 255, 255, 0.13);
  width: 20px;
  text-align: center;
  font-size: 13px;
  cursor: pointer;
}
.pagination ul .disabled {
  cursor: auto !important;
  cursor: initial !important;
  background: rgba(128, 128, 128, 0.7) !important;
}

.pagination ul li.blocked:nth-child(1):after,
.pagination ul li.blocked:nth-child(1):before {
  background: rgba(128, 128, 128, 0.7) !important;
}

.pagination ul li.blocked:nth-last-child(1):after,
.pagination ul li.blocked:nth-last-child(1):before {
  background: rgba(128, 128, 128, 0.7) !important;
}

@media screen and (max-width: 767px) {
  .pagination {
    width: 280px;
    padding-top: 45px;
    margin-top: 10px;
  }

  .pagination ul {
    display: flex;
    position: relative;
    padding: 0;
    margin: 0;
    flex-wrap: wrap;
  }

  .pagination ul li:nth-child(1) {
    order: 101;
  }

  .pagination ul li:nth-child(2) {
    order: 102;
  }

  .pagination ul li:nth-last-child(2) {
    order: 103;
  }

  .pagination ul li:nth-last-child(1) {
    order: 104;
  }

  .pagination ul li:nth-child(1),
  .pagination ul li:nth-child(2),
  .pagination ul li:nth-last-child(2),
  .pagination ul li:nth-last-child(1) {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(25% - 12px);
    width: auto !important;
    width: initial !important;
  }
}

.block__form_element_label {
  display: block;
  /*font-size: 1.077rem;*/
  /*margin-bottom: 0.2em;*/
  /*padding-top: 5px;*/
  /*font-weight: 400;*/
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 8px;
}

.field_checkbox div:nth-child(1) {
  margin-top: 0.7rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 900px) {
  .block__form_element_label {
    /*padding-top: 16px;*/
  }

  .block__form_text_input {
    width: auto;
    width: initial;
    margin-bottom: 8px;
  }
}


.error-message {
  font-weight: normal;
  margin-left: 8px;
}

.tryout-form-body {
  position: relative;
  width: 100%;
  display: block;
  height: 100%;
  background: black !important;
  color: white !important;
  font-weight: 300 !important;
  font-family: Courier, monospace !important;
  font-size: 13px !important;
  min-height: 60px;
}
.tryout-form-body * {
  padding: 0;
  border: none;
}
.tryout-form-body div {
  height: 100%;
  overflow: hidden;
}
.tryout-form-body span.token.operator + span.token.string {
  color: #ffdb7a;
}
.tryout-form-body span.token.operator + span.token.boolean,
.tryout-form-body span.token.operator + span.token.number {
  color: #81c14b;
}

.tryout-form-body textarea {
  color: white !important;
  -webkit-text-fill-color: white;
  font-weight: 300 !important;
  font-family: Courier, monospace !important;
  font-size: 13px !important;
  background: transparent !important;
  width: 100%;
  height: 100%;
  min-height: 240px;
  resize: none;
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
}

.tryout-form-body--fullscreen {
  z-index: 9999;
}

.tryout-form-body--fullscreen,
.tryout-form-body--fullscreen textarea {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.tryout-form-body__expand {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 8px;
  top: 8px;
  background: none;
  padding: 4px !important;
  border: 1px solid white;
  background: white;
}

.tryout-form-body__copy {
  position: absolute;
  right: 48px;
  top: 8px;
  height: auto !important;
  height: initial !important;
  overflow: visible !important;
  overflow: initial !important;
}

.tryout-form-body__copy div {
  overflow: visible;
  overflow: initial;
  height: auto;
  height: initial;
}

.tryout-form-body__copy .popover__message {
  width: 158px !important;
  font-size: 10px;
  text-align: center;
}

.tryout-form-body__copy button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: none;
  padding: 4px !important;
  border: 1px solid white;
  background: rgba(255, 255, 255, 0.9);
}

.tryout-form-body__copy svg,
.tryout-form-body__expand svg {
  fill: #015978;
  width: 16px;
  height: 16px;
}

@media screen and (max-width: 900px) {
  .tryout-form-body > div {
    word-break: break-all !important;
    /*height: 160px;*/
  }
}

/*# sourceMappingURL=redocParamBody.css.map */

.popover {
  position: relative;
  display: inline-block;
}
.popover__message {
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  color: #1c869d;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.4;
  padding: 8px 16px !important;
  position: absolute;
  top: 50%;
  left: 100%;
  width: 180px;
  margin-left: 12px;
  -webkit-transform: translate(0%, -50%);
          transform: translate(0%, -50%);
  z-index: 9997;
  display: none;
  text-align: center;
}
.popover__message-redoc {
  display: none;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  color: #1c869d;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.4;
  padding: 7px 5px !important;
  position: absolute;
  width: 110px;
  margin-top: -12px;
  text-align: center;
}
.popover__message-redoc::before {
  content: "";
  width: 0;
  height: 0;
  border-color: transparent #fff transparent transparent;
  border-style: solid;
  border-width: 8px 12px 8px 0;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translate(-12px, -50%);
          transform: translate(-12px, -50%);
}
.popover__message::before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 12px 8px 0;
  border-color: transparent white transparent transparent;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translate(-12px, -50%);
          transform: translate(-12px, -50%);
}
.popover__close {
  position: absolute;
  top: -4px;
  right: 0;
  padding: 8px;
  cursor: pointer;
}
.popover--left .popover__message {
  background: white;
  top: 50%;
  left: 0;
  margin-left: -14px;
  -webkit-transform: translate(-100%, -50%);
          transform: translate(-100%, -50%);
}
.popover--left .popover__message::before {
  top: 50%;
  right: 0;
  left: auto;
  left: initial;
  -webkit-transform: translate(12px, -50%);
          transform: translate(12px, -50%);
  border-width: 8px 0 8px 12px;
  border-color: transparent transparent transparent white;
}
.popover--bottom .popover__message {
  top: auto;
  top: initial;
  left: 50%;
  bottom: 0;
  margin-left: 0;
  margin-bottom: -14px;
  -webkit-transform: translate(-50%, 100%);
          transform: translate(-50%, 100%);
}
.popover--bottom .popover__message::before {
  top: 0;
  right: auto;
  right: initial;
  left: 50%;
  -webkit-transform: translate(-50%, -12px);
  transform: translate(-50%, -12px);
  border-width: 0 8px 12px 8px;
  border-color: transparent transparent white transparent;
}
.popover--top .popover__message {
  top: 0;
  left: 50%;
  bottom: auto;
  bottom: initial;
  margin-left: 0;
  margin-top: -14px;
  -webkit-transform: translate(-50%, -100%);
          transform: translate(-50%, -100%);
}
.popover--top .popover__message-redoc::before {
  top: auto;
  right: auto;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 12px);
          transform: translate(-50%, 12px);
  border-width: 12px 8px 0;
  border-color: #fff transparent transparent;
}
.popover--top .popover__message::before {
  top: auto;
  top: initial;
  right: auto;
  right: initial;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 12px);
          transform: translate(-50%, 12px);
  border-width: 12px 8px 0 8px;
  border-color: white transparent transparent transparent;
}
.popover--autohide .popover__message {
  display: none;
}
.popover--autohide:hover .popover__message {
  display: block;
}
@media screen and (max-width: 900px) {
  .popover__message {
    width: 150px;
  }
}

.popover--top .popover__message-redoc {
  top: 0;
  left: 50%;
  bottom: auto;
  margin-left: 0;
  -webkit-transform: translate(-50%, -100%);
          transform: translate(-50%, -100%);
}

/*# sourceMappingURL=popover.css.map */

.datepicker {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999999;
  color: black;
}
.datepicker__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}
.datepicker__picker {
  background: white;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1), 0 1px 6px rgba(0, 0, 0, 0.15);
}
.datepicker__picker * {
  font-family: inherit !important;
}

.picky-date-time__close,
.datepicker__picker .picky-date-time-clock__icon,
.datepicker__picker .picky-date-time-calendar__icon {
  font-family: picky-date-time !important;
  vertical-align: middle;
}

.picky-date-time-calendar__button:hover {
  border: none;
}

.picky-date-time-dropdown-calendar__year-item > div:hover {
  background: rgba(13, 26, 67, 0.8);
}

.datepicker__actions .btn--primary,
.picky-date-time-dropdown-calendar__month-item > div:hover,
.picky-date-time-dropdown-calendar__year-item.active > div,
.picky-date-time-dropdown-calendar__month-item.active > div,
.picky-date-time-calendar__button:hover,
.picky-date-time-calendar__table-cel.picky-date-time-calendar__date-item:not(.disabled):hover,
.datepicker__picker .picky-date-time-calendar__table-cel.active:not(.today) {
  background: #0d1a43;
}

.datepicker__actions {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 2rem;
}

.picky-date-time-clock__input {
  -webkit-text-fill-color: initial !important;
}

/*# sourceMappingURL=datePicker.css.map */

.guid-button {
  margin-top: 2px;
  padding: 3px;
  background: white;
  color: #015978;
  fill: #015978;
  font-size: 8px;
  position: absolute;
  display: inline-flex;
}
.guid-button svg {
  width: 10px !important;
  height: 10px !important;
}

/*# sourceMappingURL=generateGuidButton.css.map */

.checkbox * {
  box-sizing: border-box;
}

.checkbox__label {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  margin-bottom: 0;
}

.checkbox__input {
  display: none;
}

.checkbox__input:checked {
}

.checkbox__input:checked + .checkbox__icon {
  border-color: #fff77d;
}

.checkbox__input:checked + .checkbox__icon svg {
}

.checkbox__input:checked + .checkbox__icon svg path {
  fill: #fff77d;
}

.checkbox__input:checked + .checkbox__icon svg polyline {
  stroke-dashoffset: 0;
}

.checkbox__label:hover .checkbox__icon svg path {
  stroke-dashoffset: 0;
}

.checkbox__icon {
  position: relative;
  top: 2px;
  float: left;
  margin-right: 8px;
  width: 20px;
  height: 20px;
  border: 2px solid #5090ad;
  border-radius: 3px;
}

.checkbox__icon svg {
  position: absolute;
  top: -2px;
  left: -2px;
}

.checkbox__icon svg path {
  fill: none;
  stroke: #fff77d;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 71px;
  stroke-dashoffset: 71px;
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
}

.checkbox__icon svg polyline {
  fill: none;
  stroke: #024a6c;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 18px;
  stroke-dashoffset: 18px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.checkbox__label > span {
  color: white;
  /*pointer-events: none;*/
  vertical-align: sub;
  cursor: pointer;
}

.react-tagsinput-input,
.react-tagsinput-input input {
  background: transparent;
  border: 0;
  color: #777;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  max-width: none !important;
  max-width: initial !important;
}

.copy-paste {
  display: inline-block;
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
  margin-left: 15px;
  cursor: pointer;
}

.enter-icon {
  width: 20px;
  height: auto;
  fill: white;
}

.nbg-organizations-wrapper {
  min-height: 400px;
}

.nbg-organizations-wrapper .cell i.fa-ban {
  color: #fff77d;
  cursor: not-allowed;
}

.organization-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  word-break: break-word;
}

.fa-sitemap {
  color: #fff77d;
}


.edd-root,
.edd-root *,
.edd-root *::before,
.edd-root *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.edd-root {
  display: inline-block;
  position: relative;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #333;
  width: 100%;
  border: none;
  background: #ffffff21;
  resize: vertical;
  color: rgba(255, 255, 255, 0.8);
  content: "/f107";
}

.edd-root-open .edd-body {
  opacity: 1;
  pointer-events: all;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: opacity 200ms,
    -webkit-transform 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: opacity 200ms,
    -webkit-transform 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: opacity 200ms,
    transform 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: opacity 200ms,
    transform 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
    -webkit-transform 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.edd-root-open-below .edd-body {
  top: 100%;
}

.edd-head {
  /*overflow: hidden;*/
}

.edd-head,
.edd-body {
  font-weight: 500;
}

.edd-value {
  width: calc(100% - 26px);
  padding: 14px 30px 14px 20px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
  text-align: left;
}

.edd-value,
.edd-option,
.edd-group-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  /*overflow: hidden;*/
}

.edd-value,
.edd-option {
  cursor: pointer;
}

.edd-arrow {
  position: absolute;
  width: 14px;
  height: 10px;
  top: calc(50% - 5px);
  right: 24px;
  -webkit-transition: -webkit-transform 150ms;
  transition: -webkit-transform 150ms;
  transition: transform 150ms;
  transition: transform 150ms, -webkit-transform 150ms;
  pointer-events: none;
  color: #ffffff;
}

.edd-arrow::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-right: 2px solid currentColor;
  border-bottom: 2px solid currentColor;
  top: 0;
  right: 2px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 50% 25%;
  transform-origin: 50% 25%;
}

.edd-select {
  position: absolute;
  opacity: 0;
  /* width: 100%; */
  width: 0%;
  /* left: -100%; */
  top: 0;
}

.edd-head,
.edd-body {
  /* border-radius: 4px; */
  /* font-size: 1em; */
  font-weight: 500;
}

.edd-body {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  /* border: 1px solid #eee; */
  pointer-events: none;
  /*overflow: hidden;*/
  /* margin: 8px 0; */
  z-index: 999;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
  background: #024a6c;
}

.edd-items-list {
  overflow: auto;
  max-height: 0;
  -webkit-transition: max-height 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: max-height 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -webkit-overflow-scrolling: touch;
}

.edd-option {
  padding: 4px 8px;
  font-size: 12px;
}

.edd-gradient-top,
.edd-gradient-bottom {
  content: "";
  position: absolute;
  left: 2px;
  right: 2px;
  height: 32px;
  /* background-image: linear-gradient( 0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 40%, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 0) 100% ); */
  background-repeat: repeat-x;
  background-size: 100% 200%;
  pointer-events: none;
  -webkit-transition: opacity 100ms;
  transition: opacity 100ms;
  opacity: 0;
}

.edd-gradient-top {
  background-position: bottom;
  top: 0;
}

.edd-gradient-bottom {
  background-position: top;
  bottom: 0;
}

.nbg-organization-wrapper-left {
  min-height: 400px;
}

/*.plakidio {*/
/*    float: left;*/
/*    position: relative;*/
/*    width: 315px;*/
/*    !* min-width: 295px; *!*/
/*    min-height: 0;*/
/*    padding-bottom: 200px;*/
/*    margin: 10px;*/
/*    !* background-color: #1E1E1E; *!*/
/*    border-left: 1px solid rgba(255, 255, 255, 0.22);*/
/*    border-bottom: 1px solid rgba(255, 255, 255, 0.22);*/
/*    overflow: hidden;*/
/*}*/

.status-message {
  padding: 1rem;
  margin: 1rem 0;
  text-align: left;
  position: relative;
}
.status-message__close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
}
.status-message--success {
  color: white;
  background: rgba(255, 255, 255, 0.13);
}
.status-message--fail {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}
.status-message--warning {
  color: white;
  background-color: #7b5e7b;
}
.status-message--info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

/*# sourceMappingURL=statusMessage.css.map */

.modal {
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 800px;
  width: 100%;
  background-color: #005782;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
  padding: 15px 25px 20px;
}

.modal:focus {
  outline: none;
}

.modal__overlay {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.modal__title {
  font-size: 1.3rem;
  font-weight: 500;
  color: white;
  font-family: "Oswald";
}

.modal__content {
  /*color: rgba(255, 255, 255, 0.8);*/
  color: rgba(255, 255, 255, 0.8);
  max-height: 800px;

}
.modal__header_container {
  position: absolute;
  top: 5px;
  right: 10px;
  display: flex;
  width: 50%;
  justify-content: flex-end;
}

.modal__input {
  width: 80%;
}
.modal__close {
  border: none;
  background: transparent;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal__close:focus,
.modal__close:hover,
.modal__close:active {
  border: none;
  outline: none;
}

@media screen and (max-height: 650px) {
  .modal {
    height: 100%;
    overflow-y: auto;
  }
}

.confirm-modal {
  max-width: 600px;
}

.fa-edit {
  color: #fff77d;
}

.fa-user-plus {
  color: #1c869d;
}

.organizationMenu .plakidio {
  width: 100%;
  padding-bottom: 0;
  min-height: 0 !important;
  min-height: initial !important;
  float: none;
  position: relative;
  margin: 0 !important;
  margin-top: 16px !important;
}
.organizationMenu .plakidio_content {
  position: static !important;
  position: initial !important;
  padding: 8px 0;
}
.organizationMenu .plakidio_table,
.organizationMenu .plakidio_table-cell {
  display: block;
  padding-left: 8px;
  vertical-align: baseline;
  vertical-align: initial;
  width: 100%;
}
.organizationMenu .plakidio_table-cell a {
  display: block;
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
}
.organizationMenu .choise_img {
  width: 32px;
  display: inline-block;
  max-width: 32px;
  margin: 0 !important;
  -webkit-transition: none 0s ease 0s !important;
  transition: none 0s ease 0s !important;
  -webkit-transition: initial !important;
  transition: initial !important;
}
.organizationMenu .choise_title,
.organizationMenu .choise_title h3 {
  font-size: 14px;
  position: relative;
  top: -2px;
  display: inline-block;
}
.organizationMenu .choise_title h3 {
  margin: 0 !important;
  margin-left: 8px !important;
}

/*# sourceMappingURL=menuTabs.css.map */

.nbg-organization-wrapper {
  min-height: 400px;
}

.thumb {
  width: 80px;
  height: 80px;
  float: left;
  background-size: 60%;
  background-position: center;
  background-repeat: no-repeat !important;
}

.thumb--yellow {
  background-color: #ffdb7a;
}

.thumb--purple {
  background-color: #a863a8;
}

.thumb--blue {
  background-color: #6f99e0;
}

.thumb--green {
  background-color: #4bbb69;
}

.thumb--brown {
  background-color: #b18b32;
}

.thumb--round {
  border-radius: 50%;
}

.thumb--text-circle {
  shape-outside: circle();
  -webkit-shape-outside: circle();
}

.thumb--stacked {
  background-image: url(/static/media/api_thumb.deecd526.svg);
}

.thumb--mobile {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfhCB4JJiP/EsAzAAABTklEQVRo3u2ZMUoDYRCF30pAQYuAC7JpcghJYSfYeo4U6XOHHCAHsMgRbK2S1jK1XYKwQkgwrNWzEEKI/7/zu8Ni8161u/+bmW92ttoBEsQux1xwz1TtueCY3ZTcKeX7XCaXPtaSfTt7ZpYv8IoicPB+cn8T8Kxxm629/U+C3c1/+eZB38TK3zEJHoJPB3w6ffKH6CPZIyhx7XiBH1lebzgzU1w5yidE2yMAgE88Nij+jEsX/I9Ykdw0ityQrCyXPYKWJQABCEAAAhCAAAQgAAEIQAACEIAABCAAAQhAAAL4d4C0v+Ud3reV215YVDh3NPiVXdQbvCPYYutL4AHYYYgeehhi52yjTqyie8HpwTONesyFhQ1QRpMf9mQcRD2lfwRv0ZO7wFV6dDLAS/RkxBwAmGPUIDpVLLiKT5gzzmq+khULN0Dby+s0hBbX999O/tz7PPawiwAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxNy0wOC0zMFQwOTozODozNSswMjowMD6F61wAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTctMDgtMzBUMDk6Mzg6MzUrMDI6MDBP2FPgAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==);
}

.uploadDocumentForm {
  margin: 2rem 0;
  padding: 1rem;
  border: 1px dashed rgba(255, 255, 255, 0.22);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.uploadDocumentForm > div:first-child {
  flex: 1 1 100%;
}

.uploadDocumentForm label {
  font-size: 16px;
}

.uploadDocumentForm input[type="file"] {
  color: white;
}

.nbg-organization-members-wrapper {
  min-height: 380px;
}

.nbg-organization-members-wrapper .modal__overlay {
  overflow: auto;
}

.members_form {
  display: flex;
  justify-content: space-around;
  padding: 15px 0px;
}

.members_form .option_item_description {
  float: right;
  margin-right: 17px;
}
.members_form .checkbox {
  margin-left: 10px;
}

.members_form .popover__content {
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
}
.members_form .popover__message {
  margin-top: 7px;
}

.members_form_email {
  width: 40%;
}

.members_form_roles {
  width: 50%;
}

.remove_member {
  width: 30px;
  height: 30px;
}

.remove_member .trash_icon {
  -webkit-transform: translateY(25%);
          transform: translateY(25%);
  text-align: center;
  cursor: pointer;
}

.remove_member .trash_icon svg {
  fill: white;
  width: 15px;
  height: 15px;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
}
.members_form_buttons {
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 767px) {
  .members_form {
    display: flex;
    flex-direction: column;
  }

  .members_form_email {
    margin: auto;
    width: 50%;
  }

  .members_form_roles {
    margin: 20px auto auto auto;
    width: 50%;
  }

  .remove_member {
    margin: auto;
    width: 30px;
    height: 30px;
  }
}
@media screen and (max-width: 767px) {
  .members_form {
    display: flex;
    flex-direction: column;
  }

  .members_form_email {
    margin: auto;
    width: 100%;
  }

  .members_form_roles {
    margin: 20px auto auto auto;
    width: 100%;
  }

  .remove_member {
    margin: auto;
    width: 30px;
    height: 30px;
  }
}

.edd-multiple-root,
.edd-multiple-root *,
.edd-multiple-root *::before,
.edd-multiple-root *::after {
  /*margin: 0;*/
  /*padding: 0;*/
  box-sizing: border-box;
}

.edd-multiple-root {
  display: inline-block;
  position: relative;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #333;
  width: 100%;
  border: none;
  background: #ffffff21;
  resize: vertical;
  color: #ffffff91;
  content: "/f107";
}

.edd-root-open .edd-body {
  opacity: 1;
  pointer-events: all;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: opacity 200ms,
    -webkit-transform 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: opacity 200ms,
    -webkit-transform 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: opacity 200ms,
    transform 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: opacity 200ms,
    transform 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
    -webkit-transform 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.edd-root-open-below .edd-body {
  top: 100%;
}

.edd-head {
  /*overflow: hidden;*/
}

.edd-head,
.edd-body {
  font-weight: 500;
}

.edd-multiple-value {
  width: calc(100% - 26px);
  padding: 14px 30px 14px 20px;
  font-size: 12px;
  color: #ffffff91;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
}

.edd-multiple-value,
.edd-option,
.edd-group-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  /*overflow: hidden;*/
}

.edd-multiple-value,
.edd-option {
  cursor: pointer;
}

.edd-arrow {
  position: absolute;
  width: 14px;
  height: 10px;
  top: calc(50% - 5px);
  right: 24px;
  -webkit-transition: -webkit-transform 150ms;
  transition: -webkit-transform 150ms;
  transition: transform 150ms;
  transition: transform 150ms, -webkit-transform 150ms;
  pointer-events: none;
  color: #ffffff;
}

.edd-arrow::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-right: 2px solid currentColor;
  border-bottom: 2px solid currentColor;
  top: 0;
  right: 2px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 50% 25%;
  transform-origin: 50% 25%;
}

.edd-select {
  position: absolute;
  opacity: 0;
  /* width: 100%; */
  width: 0%;
  /* left: -100%; */
  top: 0;
}

.edd-head,
.edd-body {
  /* border-radius: 4px; */
  /* font-size: 1em; */
  font-weight: 500;
}

.edd-body {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  /* border: 1px solid #eee; */
  pointer-events: none;
  /*overflow: hidden;*/
  /* margin: 8px 0; */
  z-index: 999;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
  background: #024a6c;
}

.edd-items-list {
  overflow: auto;
  max-height: 0;
  -webkit-transition: max-height 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: max-height 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -webkit-overflow-scrolling: touch;
}

.edd-multiple-option {
  padding: 8px 8px;
  font-size: 12px;
  display: flex;
}
.edd-multiple-option .checkbox {
  flex: 1 1 20%;
}

.edd-multiple-option span {
  margin-left: 8px;
  -webkit-transform: translateY(20%);
          transform: translateY(20%);
  flex: 2 1 100%;
  margin-right: 40px;
}

.edd-gradient-top,
.edd-gradient-bottom {
  content: "";
  position: absolute;
  left: 2px;
  right: 2px;
  height: 32px;
  /* background-image: linear-gradient( 0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 40%, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 0) 100% ); */
  background-repeat: repeat-x;
  background-size: 100% 200%;
  pointer-events: none;
  -webkit-transition: opacity 100ms;
  transition: opacity 100ms;
  opacity: 0;
}

.edd-gradient-top {
  background-position: bottom;
  top: 0;
}

.edd-gradient-bottom {
  background-position: top;
  bottom: 0;
}

.inviteMembersModal {
  /*max-height: 100vh;*/
  /*overflow-y: auto;*/
}
.inviteMembersModal .edd-items-list {
  overflow: unset;
}

.inviteMembersModal::-webkit-scrollbar {
  width: 8px;
}

.inviteMembersModal::-webkit-scrollbar-thumb {
  background-color: white;
}

.inviteMembersModal::-webkit-scrollbar-thumb:hover {
  background-color: #1c869d;
}

.member_info_modal {
  min-height: 400px;
  max-width: 700px;
}
.member_info_modal ::-webkit-scrollbar {
  width: 8px;
}
.member_info_modal ::-webkit-scrollbar-thumb {
  background-color: white;
}
.member_info_modal ::-webkit-scrollbar-thumb:hover {
  background-color: #1c869d;
}

.member_info {
  display: flex;
  justify-content: space-between;
  padding: 15px 0px;
}

.member_profile_info table {
  margin-top: 8px;
}
.member_profile_info table tr td:first-child {
  font-weight: 700;
  color: white;
}
.member_profile_info table tr td:last-child {
  text-align: right;
}

.member_profile_photo {
  max-width: 144px;
  margin: auto;
}

.member_email {
  margin-top: 1rem;
}

.member_roles_checkbox {
  margin-top: 0.4rem;
}

.member_info_buttons {
  position: absolute;
  bottom: 0;
  right: 24px;
  margin-bottom: 36px;
}

.edit_member_roles {
  display: flex;
  flex-direction: column;
}

.member_roles_checkbox {
  display: flex;
  width: 250px;
}

.member_roles_checkbox .checkbox {
  flex: 1 1 auto;
}

.member_roles_description {
  float: right;
}

.member_actions {
  margin-top: 16px;
  /*padding: 0 16px;*/
  font-size: 14px;
}
.member_actions__action {
  border: 1px solid rgba(255, 255, 255, 0.22);
  padding: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}
.member_actions__action:hover {
  color: white;
}
.member_actions__action i {
  margin-right: 8px;
}
.member_actions__action svg {
  margin-right: 8px;
  width: 14px;
}

@media screen and (max-width: 767px) {
  .member_info {
    display: flex;
    flex-direction: column;
  }

  .members_form_email {
    margin: auto;
    width: 50%;
  }

  .edit_member_roles {
    margin-top: 1rem;
  }

  .member_info_buttons {
    position: unset;
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (max-width: 767px) {
  .members_form {
    display: flex;
    flex-direction: column;
  }

  .members_form_email {
    margin: auto;
    width: 100%;
  }

  .members_form_roles {
    margin: 20px auto auto auto;
    width: 100%;
  }

  .remove_member {
    margin: auto;
    width: 30px;
    height: 30px;
  }
}

/*# sourceMappingURL=organizationMemberInfoModal.css.map */

.nbg-organization-members-wrapper .cell i {
  cursor: pointer;
}

.app_credentials {
  margin: 45px auto;
}

.app_credentials__form {
  /*width: 100%;*/
  /*padding: 0 20px 25px;*/
}

.app_credentials__form label,
.app_credentials__form input {
  margin-bottom: 8px;
  display: block;
}

.app_credentials__form input {
  /*max-width: 440px;*/
  /*margin-right: 25px;*/
  /*display: inline-block;*/
  /*margin-bottom: 10px;*/
}

.app_credentials__form #show {
  /*margin: 0;*/
  /*padding: 0;*/
  /*width: auto;*/
  /*-webkit-appearance: checkbox;*/
}

.app_credentials__form #secret {
  /*margin-right: 25px;*/
}

.app_credentials__form .show_span {
  /*color: #006E80;*/
  /*margin-right: 35px;*/
}

.body_field {
  width: 100%;
  /* border: 2px solid #aaa; */
  /* border-radius: 4px; */
  font-size: 12px;
  border: none;
  margin: 5px 0;
  outline: none;
  padding: 15px 40px 15px 20px;
  box-sizing: border-box;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background: rgba(255, 255, 255, 0.13);
}

.body_field.tags-input {
  padding-top: 8px;
  padding-bottom: 8px;
}

.application-info__credentials {
  display: flex;
}

.application-info__credentials > div {
  width: 100%;
  margin-bottom: 8px;
}

.application-info__credentials > div:not(:first-child) {
  margin-left: 8px;
}

.application-info__credentials > div input {
  margin-bottom: 8px;
}

.app_credentials__form .client_info {
  position: relative;
}

.app_credentials__form .app_credentials__form-button__copy {
  position: absolute;
  right: 4px;
  top: 8px;
}

@media screen and (max-width: 900px) {
  .application-info__credentials {
    flex-wrap: wrap;
  }
}

.app_credentials__form-button__copy {
  display: inline-flex;
  margin-left: 15px;
}

.app_credentials__form-button__copy button {
  padding: 5px;
  border: none;
}
.app_credentials__form-button__copy svg {
  fill: white;
  width: 16px;
  height: auto;
}

.client_info-wrapper {
  margin-top: 10px;
}
.client__buttons {
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.client__buttons .simple_btn .btn {
  font-weight: 400;
  font-size: 12px;
}
.application-info__credentials-clientSecret div .simple_btn .btn {
  font-weight: 400;
  font-size: 12px;
}

@media screen and (max-width: 767px) {
  /*.client_info-wrapper .simple_btn {*/
  /*    width: calc(100% - 8px);*/
  /*}*/
  .client__buttons .simple_btn label.btn {
    width: 50%;
  }
}

.application-info__credentials-clientSecret {
  height: 100px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.13);
  border: 1px solid rgba(255, 255, 255, 0.22);
  padding: 10px;
  padding-bottom: 30px;
  /*animation: border-dance 5s infinite linear;*/
}

@-webkit-keyframes border-dance {
  0% {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, blue), color-stop(50%, transparent)),
      -webkit-gradient(linear, left top, right top, color-stop(50%, blue), color-stop(50%, transparent)),
      -webkit-gradient(linear, left bottom, left top, color-stop(50%, blue), color-stop(50%, transparent)),
      -webkit-gradient(linear, left bottom, left top, color-stop(50%, blue), color-stop(50%, transparent));
    background: linear-gradient(90deg, blue 50%, transparent 50%),
      linear-gradient(90deg, blue 50%, transparent 50%),
      linear-gradient(0deg, blue 50%, transparent 50%),
      linear-gradient(0deg, blue 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 15px 4px, 15px 4px, 4px 15px, 4px 15px;
    background-position: 0px 0px, 100% 116px, 0px 116px, 100% 0px;
    background-position: 0px 0px, 100% 116px, 0px 150px, 100% 0px;
  }
  100% {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, blue), color-stop(50%, transparent)),
      -webkit-gradient(linear, left top, right top, color-stop(50%, blue), color-stop(50%, transparent)),
      -webkit-gradient(linear, left bottom, left top, color-stop(50%, blue), color-stop(50%, transparent)),
      -webkit-gradient(linear, left bottom, left top, color-stop(50%, blue), color-stop(50%, transparent));
    background: linear-gradient(90deg, blue 50%, transparent 50%),
      linear-gradient(90deg, blue 50%, transparent 50%),
      linear-gradient(0deg, blue 50%, transparent 50%),
      linear-gradient(0deg, blue 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 15px 4px, 15px 4px, 4px 15px, 4px 15px;
    background-position: 0px 0px, 100% 116px, 0px 116px, 100% 0px;
    background-position: 100% 0px, 0px 116px, 0px 0px, 100% 150px;
  }
}

@keyframes border-dance {
  0% {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, blue), color-stop(50%, transparent)),
      -webkit-gradient(linear, left top, right top, color-stop(50%, blue), color-stop(50%, transparent)),
      -webkit-gradient(linear, left bottom, left top, color-stop(50%, blue), color-stop(50%, transparent)),
      -webkit-gradient(linear, left bottom, left top, color-stop(50%, blue), color-stop(50%, transparent));
    background: linear-gradient(90deg, blue 50%, transparent 50%),
      linear-gradient(90deg, blue 50%, transparent 50%),
      linear-gradient(0deg, blue 50%, transparent 50%),
      linear-gradient(0deg, blue 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 15px 4px, 15px 4px, 4px 15px, 4px 15px;
    background-position: 0px 0px, 100% 116px, 0px 116px, 100% 0px;
    background-position: 0px 0px, 100% 116px, 0px 150px, 100% 0px;
  }
  100% {
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, blue), color-stop(50%, transparent)),
      -webkit-gradient(linear, left top, right top, color-stop(50%, blue), color-stop(50%, transparent)),
      -webkit-gradient(linear, left bottom, left top, color-stop(50%, blue), color-stop(50%, transparent)),
      -webkit-gradient(linear, left bottom, left top, color-stop(50%, blue), color-stop(50%, transparent));
    background: linear-gradient(90deg, blue 50%, transparent 50%),
      linear-gradient(90deg, blue 50%, transparent 50%),
      linear-gradient(0deg, blue 50%, transparent 50%),
      linear-gradient(0deg, blue 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 15px 4px, 15px 4px, 4px 15px, 4px 15px;
    background-position: 0px 0px, 100% 116px, 0px 116px, 100% 0px;
    background-position: 100% 0px, 0px 116px, 0px 0px, 100% 150px;
  }
}

/*.status-message--application * {*/
/*    box-sizing: inherit;*/
/*}*/

/*.status-message--application input[type="text"] {*/
/*    -webkit-transition: border-color 0.7s ease;*/
/*    -o-transition: border-color 0.7s ease;*/
/*    transition: border-color 0.7s ease;*/
/*    outline: none;*/
/*    border: 1px solid #dddddd;*/
/*    padding: .5rem 1rem;*/
/*    width: 50%;*/
/*    vertical-align: middle;*/
/*}*/

/*.status-message--application input[type="text"] + label {*/
/*    padding: .8rem 2rem !important;*/
/*    font-size: 1rem;*/
/*}*/

/*.status-message--application input[type="text"]:focus {*/
/*    border: 1px solid #ffdb7a;*/
/*}*/

.application-info {
  /*display: flex;*/
  /*flex-direction: column;*/
}

.application-info__description {
}

.application-info__description p:first-child {
  margin-top: 0;
}

.application-info__table {
  border-collapse: collapse;
}

.application-info__table .tag {
  display: inline-block;
}

.application-info__table tr td {
  padding: 1rem 0.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  /*border: 1px solid rgba(255, 255, 255, 0.7);*/
}

.application-info__table tr td:first-child {
  width: 1%;
  color: #fff77d;
  font-weight: 500;
  white-space: nowrap;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

.app-operations {
  float: right;
  display: inline-flex;
}

.app-operations .bracketButton {
  display: -webkit-inline-box;
  /*display: -ms-inline-flexbox;*/
  /*display: inline-flex;*/
  margin: 0 16px;
}

.app-operations .bracketButton a {
  font-size: 16px;
  font-weight: 500;
}

.warning-icon {
  color: #fff77d;
  border: 1px solid #fff77d;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 28px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

/*# sourceMappingURL=applicationOverview.css.map */

.popupMessage {
  position: fixed;
  z-index: 100001;
  background: -webkit-gradient(linear, left top, right top, from(#015978), to(#005782));
  background: linear-gradient(to right, #015978 0%, #005782 100%);
}

.popupMessage__content {
  width: 400px;
  max-width: 80vw;
  background: rgba(255, 255, 255, 0.13);
  padding: 16px;
  font-size: 13px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.18);
}

.popupMessage__content__closeButton {
  width: 11px;
  height: 11px;
  position: absolute;
  cursor: pointer;
  right: 8px;
  top: 8px;
  fill: white;
}

.blurred-background {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.scopes {
  text-align: center;
}

.scopes table {
  box-sizing: border-box;
}

.scopes td,
.scopes th {
  border: 1px solid;
  padding: 8px;
}

.scope th {
  font-weight: 900;
}

.subscriptions_box {
  background-color: rgba(255, 255, 255, 0.02);
  padding-top: 40px;
  padding-left: 40px;
  padding-bottom: 100px;
  padding-right: 150px;
  -webkit-clip-path: polygon(
    0% 0%,
    0% 100%,
    calc(100% - 60px) 100%,
    100% calc(100% - 60px),
    100% 0
  );
  clip-path: polygon(
    0% 0%,
    0% 100%,
    calc(100% - 60px) 100%,
    100% calc(100% - 60px),
    100% 0
  );
  position: relative;
  margin-bottom: 30px;
}

.subscriptions_box:before {
  content: "";
  position: absolute;
  border-left: 30px solid transparent;
  /* border-right: 20px solid transparent; */
  border-top: 30px solid rgba(255, 255, 255, 0.02);
  /* border-bottom: 20px solid #1a4663; */
  width: 0px;
  height: 0px;
  /* transform: rotate(45deg); */
  right: 0px;
  top: 0px;
}

.subscriptions_box:after {
  content: "";
  position: absolute;
  /* border-left: 30px solid transparent; */
  border-right: 30px solid transparent;
  /* border-top: 30px solid #1a4663; */
  border-bottom: 30px solid rgba(255, 255, 255, 0.02);
  width: 0px;
  height: 0px;
  /* transform: rotate(45deg); */
  left: 0px;
  bottom: 0px;
}

.subscriptions_box .subscriptions__icons {
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
}

.subscriptions_box .subscriptions__icons .subscriptions__viewDetails {
  text-align: end;
  margin-right: 4px;
}

.subscriptions_box .subscriptions__icons .subscriptions__viewDetails a span {
  margin-left: 3.5px;
}

.subscriptions_box .subscriptions__icons .subscriptions__unsubscribe {
  cursor: pointer;
  margin-top: 7px;
  text-align: end;
  margin-right: 4px;
}

.subscriptions_box .subscriptions__icons .subscriptions__unsubscribe svg {
  font-size: 0.7rem;
}

.application_subscriptions_table {
  padding: 10px 12px !important;
}

@media screen and (max-width: 650px) {
  .scopes {
    overflow-x: auto;
  }
}

@media screen and (max-width: 767px) {
  .subscriptions_box {
    padding-left: 20px;
    padding-right: 50px;
  }
  .subscriptions__icons {
    width: 50%;
    float: right;
  }
  .application_subscriptions_info {
    width: 50%;
  }
}

.cornersButton-wrapper:hover .cornersButton .btn,
.cornersButton-wrapper.enable .cornersButton .btn {
  background: #1c869d;
  color: #ffffff;
}

.cornersButton-wrapper:hover:before,
.cornersButton-wrapper.enable:before {
  content: "";
  position: absolute;
  border-top: 1px solid rgba(255, 255, 255, 0.7);
  border-left: 1px solid rgba(255, 255, 255, 0.7);
  top: -4px;
  left: -4px;
  width: 10px;
  height: 10px;
  z-index: 1;
}

.cornersButton .btn {
  position: relative;
  padding: 10px 130px;
  background: rgba(255, 255, 255, 0.22);
  border: none;
  /* outline-offset: 3px; */
  /* outline: 1px solid #4e8ba1; */
  margin: 10px 4px;
  font-family: "CF Asty Std";
  font-weight: 500;
  font-size: 14px;
  color: #015978;
  cursor: pointer;
}

.cornersButton .btn:after {
  content: "";
  position: absolute;
  border-top: 2px solid #015978;
  border-right: 2px solid #015978;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 18px;
  right: 30px;
  width: 7px;
  height: 7px;
  z-index: 1;
}

.cornersButton .btn {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.cornersButton.left {
  float: left;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .cornersButton .btn:after {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
}

.subscribe-icon {
  color: #fff77d;
}

.subscribe-icon.subscribed {
  color: #81c14b;
}

.subscribe-icon.disabled {
  color: grey !important;
  cursor: not-allowed;
}

.table-container {
  width: 100%;
  border-collapse: collapse;
}

.table-header {
  background: hsla(0, 0%, 100%, 0.13);
  font-weight: 900;
  color: #fff;
  background: hsla(0, 0%, 100%, 0.13);
  -webkit-font-smoothing: antialiased;
  position: relative;
}

.table-header-column {
  padding: 30px 12px;
  text-align: left;
  font-weight: 500;
}

.table-header-column-default{
  padding: 12px 8px;
}

.table-container:after {
  content: "";
  position: absolute;
  border-left: 20px solid transparent;
  border-top: 20px solid hsla(0, 0%, 100%, 0.25);
  width: 0;
  height: 0;
  right: 0;
  top: 0;
}

.table-body-column {
  padding: 6px 12px;
  font-weight: 500;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
}
.table-container {
  position: relative;
}

.search__icon {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0px;
  background-color: #ffffff00;
  color: #fff;
  width: 40px;
  height: 60px;
  font-size: 18px;
  text-align: center;
  border-color: transparent;
  /* border-radius: 50%; */
  pointer-events: none;
  display: inline-block;
  -webkit-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
}

.search__icon__nav {
  background-color: #ffffff00;
  color: #fff;
  font-size: 18px;
  text-align: center;
  border-color: transparent;
}

.body_field + .search__icon {
  height: 100%;
  width: 60px;
}

.st0 {
  fill: #859096;
}
.st1 {
  fill: #fff77d;
}
.st2 {
  fill: #ffffff;
}
.st3 {
  opacity: 0.1;
  fill: #ffffff;
}
.st4 {
  fill: #33b3bf;
}
.st5 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ffffff;
}
.st6 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #007580;
}
.st7 {
  fill: #231f20;
}
.st8 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #231f20;
}
.st9 {
  fill: #c49958;
}
.st10 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 0.9789;
  stroke-miterlimit: 10;
}
.st11 {
  opacity: 0.3;
}
.st12 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 1.0001;
  stroke-miterlimit: 10;
}
.st13 {
  fill: none;
  stroke: #fff77d;
  stroke-width: 1.0001;
  stroke-miterlimit: 10;
}
.st14 {
  fill: none;
  stroke: #fff77d;
  stroke-width: 0.5;
  stroke-miterlimit: 10;
}
.st15 {
  fill: #024a6c;
}
.st16 {
  fill: #1c869d;
}
.st17 {
  fill: #1b2d4c;
}
.st18 {
  opacity: 0.5;
  fill: #1b2d4c;
}
.st19 {
  opacity: 0.2;
  fill: #1b2d4c;
}
.st20 {
  fill: url(#SVGID_1_);
}
.st21 {
  fill: url(#SVGID_2_);
}
.st22 {
  fill: url(#SVGID_3_);
}
.st23 {
  opacity: 0.7;
  fill: url(#SVGID_4_);
}
.st24 {
  opacity: 0.5;
  fill: #ffffff;
}
.st25 {
  opacity: 0.5;
}
.st26 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 1.0014;
  stroke-miterlimit: 10;
}
.st27 {
  fill: none;
  stroke: #fff77d;
  stroke-width: 1.0014;
  stroke-miterlimit: 10;
}
.st28 {
  opacity: 0.15;
}
.st29 {
  -webkit-clip-path: url(#SVGID_6_);
  clip-path: url(#SVGID_6_);
}
.st30 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 0.9738;
  stroke-miterlimit: 10;
}
.st31 {
  opacity: 0.05;
  fill: #ffffff;
}
.st32 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 0.5;
  stroke-miterlimit: 10;
}
.st33 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 2;
  stroke-miterlimit: 10;
}
.st34 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #1c869d;
}
.st35 {
  fill: #c1272d;
}
.st36 {
  fill: #0b698e;
}
.st37 {
  opacity: 0.1;
}
.st38 {
  -webkit-clip-path: url(#SVGID_8_);
  clip-path: url(#SVGID_8_);
}
.st39 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 1.0005;
  stroke-miterlimit: 10;
  stroke-opacity: 0.3;
}
.st40 {
  opacity: 0.3;
  fill: #ffffff;
}

.subscribe-product-to-app .modal__content::-webkit-scrollbar-corner {
  background: transparent;
}

/*# sourceMappingURL=styles.css.map */

.charts-section {
  padding: 0 20px;
}

.analytics__filters-wrapper {
  display: flex;
}
.analytics__charts-wrapper {
  display: flex;
  width: 100%;
}
.analytics__charts-wrapper > div {
  flex: 1 1;
  padding: 16px;
  border: 1px solid rgba(255, 255, 255, 0.13);
  margin-bottom: 16px;
}
.analytics__charts-wrapper > div:first-child {
  margin-right: 8px;
  flex: 1.4 1;
}
.analytics__charts-wrapper > div:nth-child(2) {
  margin-left: 8px;
}
.analytics__charts-wrapper > div:only-child {
  margin-right: 0;
}
@media screen and (max-width: 1200px) {
  .analytics__charts-wrapper {
    flex-direction: column;
  }
  .analytics__charts-wrapper > div {
    margin-right: 0 !important;
  }
}

.recharts-tooltip-wrapper .custom-tooltip {
  background-color: #015978;
  color: #fff;
  cursor: pointer;
  padding: 18px 50px 18px 18px;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  position: relative;
  margin-bottom: 14px;
  font-family: CF Asty Std;
  font-weight: 500;
}

.recharts-tooltip-wrapper .custom-tooltip .label {
  margin: 0;
  color: #fff;
  font-weight: 700;
}

.recharts-tooltip-wrapper .custom-tooltip .dataKey {
  margin: 0;
  color: #fff;
}

.recharts-tooltip-wrapper .custom-tooltip .value {
  margin: 0;
  color: #fff !important;
}

.DateInput_input {
  color: #fff !important;
  padding: 0 !important;
  font-size: medium;
  font-size: initial;
}

.DateRangePicker {
  width: 100%;
}

.DateRangePickerInput {
  display: flex !important;
  justify-content: space-between !important;
  flex-wrap: wrap !important;
}

.DateInput,
.DateInput_input,
.DateRangePickerInput {
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll !important;
  background: initial !important;
}

.DateRangePickerInput__withBorder {
  border: none !important;
}

.DateInput_input__focused {
  border-bottom: 2px solid #fff77d !important;
  color: #fff !important;
}

.DateRangePickerInput_arrow {
  color: #fff !important;
}

.DateRangePickerInput_arrow_svg {
  fill: #fff !important;
}

.CalendarDay__selected {
  background: #024a6c !important;
}

.CalendarDay__selected_span {
  background: #1c869d !important;
  border: 1px solid #4e8ba1 !important;
}

.recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child,
.recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
  stroke-opacity: 0;
}

.recharts-legend-item-text {
  font-size: 12px;
}

/*# sourceMappingURL=analytics.css.map */


/* FORUM FOLDER */
.cell.forum a {
  text-transform: capitalize;
}

.cell button span.icon-arrow_down:before {
  color: #ffffff70;
  margin: 0 4px;
}

.forum .cell button {
  background: transparent;
}

/*.forum-folder {*/
/*    margin-bottom: 1rem;*/
/*    }*/

/*.forum-folder__header {*/
/*    border-top-left-radius: 10px;*/
/*    border-top-right-radius: 10px;*/
/*    padding: 1rem 2rem;*/
/*    background: #006E80;*/
/*    font-size: 1.5rem;*/
/*    font-weight: 700;*/
/*    color: white;*/
/*    text-align: left !important;*/
/*    text-transform: capitalize;*/
/*    }*/

/*!* FORUM TABLE *!*/

/*.forum-table {*/
/*    color: black;*/
/*    border-collapse: collapse;*/
/*    width: 100%;*/
/*    }*/

/*.forum-table thead {*/
/*    background: rgb(219, 219, 219);*/
/*    }*/

/*.forum-table thead th {*/
/*    font-weight: 300;*/
/*    }*/

/*.forum-table tbody {*/
/*    border-radius: 10px;*/
/*    -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1), 0 1px 6px rgba(0, 0, 0, .15);*/
/*            box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1), 0 1px 6px rgba(0, 0, 0, .15);*/
/*    }*/

/*.forum-table tbody tr th:not(.forum-folder__header) {*/
/*    background: rgba(155, 155, 155, 0.1);*/
/*    }*/

/*.forum-table tbody tr th:first-child {*/
/*    text-align: left;*/
/*    }*/

/*.forum-table td,*/
/*.forum-table th {*/
/*    text-align: center;*/
/*    padding: .5rem 1rem;*/
/*    }*/

/*.forum-table td {*/
/*    text-align: center;*/
/*    padding: .7rem 1rem;*/
/*    }*/

/*.forum-table td a {*/
/*    text-decoration: none;*/
/*    color: #006E80;*/
/*    font-size: 1rem;*/
/*    }*/

/*.forum-table .forum-table__row:nth-child(even) {*/
/*    background: #dddddd;*/
/*    }*/

/*.forum-table__row {*/
/*    width: 100%;*/
/*    }*/

/*.forum-table__row td:first-child {*/
/*    text-align: left;*/
/*    }*/

/*.forum-table__row svg {*/
/*    width: 45px;*/
/*    margin-right: 1rem;*/
/*    display: inline-block;*/
/*    vertical-align: middle;*/
/*    }*/

/*.forum-table__row--discussion svg {*/
/*    width: 45px !important;*/
/*    height: 45px;*/
/*    }*/

/*@media screen and (max-width: 450px) {*/
/*    .forum-table__row svg {*/
/*        width: 24px;*/
/*        }*/

/*    .forum-table__row--discussion svg {*/
/*        width: 24px !important;*/
/*        }*/
/*    }*/

/*tbody.separator,*/
/*tbody.spinner {*/
/*    height: 300px;*/
/*    -webkit-box-shadow: none;*/
/*            box-shadow: none;*/
/*    border: none;*/
/*    }*/
/*tbody.separator{*/
/*    height: 50px;*/
/*}*/

/*!*---------------------------------*!*/

/*.forum-table--small {*/
/*    }*/

/*.forum-table--small td,*/
/*.forum-table--small th {*/
/*    padding: .5rem;*/
/*    }*/

/*.forum-table--small .forum-table__row td:first-child {*/
/*    text-align: inherit;*/
/*    }*/

/*.forum-table__row td:first-child > div{*/
/*    display: -webkit-box;*/
/*    display: -ms-flexbox;*/
/*    display: flex;*/
/*    -webkit-box-pack: justify;*/
/*        -ms-flex-pack: justify;*/
/*            justify-content: space-between;*/
/*    -webkit-box-align: center;*/
/*        -ms-flex-align: center;*/
/*            align-items: center;*/
/*}*/

/*!*---------------------------------*!*/

/*.forum-table--sharp {*/
/*    border: 1px solid white;*/
/*    -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1), 0 1px 6px rgba(0, 0, 0, .15);*/
/*            box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1), 0 1px 6px rgba(0, 0, 0, .15);*/
/*    }*/

/*.forum-table--sharp tbody {*/
/*    -webkit-box-shadow: none;*/
/*            box-shadow: none;*/
/*    border-radius: 0;*/
/*    }*/

/*!*---------------------------------*!*/

/*.forum-table--colored-head {*/
/*    border-collapse: initial;*/
/*    }*/

/*.forum-table--colored-head thead th {*/
/*    background-color: #006E80;*/
/*    font-weight: 900;*/
/*    color: white;*/
/*    }*/

/*blockquote {*/
/*    display: block;*/
/*    background: #fff;*/
/*    padding: 15px 20px 15px 45px !important;*/
/*    margin: 0 0 20px !important;*/
/*    position: relative;*/
/*    line-height: 1.2;*/
/*    color: #666;*/

/*    -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1), 0 1px 6px rgba(0, 0, 0, .15);*/

/*            box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1), 0 1px 6px rgba(0, 0, 0, .15);*/
/*    border-right: 2px solid #34bc9d !important;*/
/*    border-left: 15px solid #46cfb0 !important;*/
/*    }*/

/*blockquote::before {*/
/*    content: "\201C";*/
/*    !*font-family: Georgia, serif;*!*/
/*    font-size: 60px;*/
/*    font-weight: bold;*/
/*    color: #999;*/
/*    position: absolute;*/
/*    left: 10px;*/
/*    top: 5px;*/
/*    }*/

/*blockquote::after {*/
/*    content: "";*/
/*    }*/

/*blockquote p {*/
/*    font-size: 16px;*/
/*    }*/

/*blockquote a {*/
/*    text-decoration: none;*/
/*    background: #eee;*/
/*    cursor: pointer;*/
/*    padding: 0 3px;*/
/*    color: #006E80;*/
/*    margin-bottom: .4rem;*/
/*    }*/

/*blockquote a:hover {*/
/*    color: #666;*/
/*    }*/

/*blockquote em {*/
/*    font-style: italic;*/
/*    }*/

/*blockquote code {*/
/*    color: #da4453;*/
/*    }*/

/*blockquote span {*/
/*    font-weight: bolder;*/
/*    }*/

/*blockquote h1 {*/
/*    text-align: left;*/
/*    font-size: 16px;*/
/*    font-family: 'Francois One', sans-serif;*/
/*    }*/

/*@media screen and (max-width: 450px) {*/
/*    blockquote {*/
/*        border-right: 2px solid #34bc9d !important;*/
/*        border-left: 8px solid #46cfb0 !important;*/
/*        padding: 15px 20px 15px 25px !important;*/

/*        max-height: 8.3rem;*/
/*        overflow: hidden;*/
/*        }*/

/*    blockquote::before {*/
/*        font-size: 30px;*/
/*        }*/

/*    blockquote::after {*/
/*        content: '...';*/
/*        }*/

/*    blockquote:hover {*/
/*        max-height: initial;*/

/*        }*/

/*    blockquote:hover::after {*/
/*        content: '';*/
/*        }*/
/*    }*/

.forum .sidebar.right .array_descr {
  margin: 8px 0;
  padding: 0 16px;
  float: none;

}

.forum_post {
  font-family: "CF Asty Std";
  font-weight: 900;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.13);
  -webkit-font-smoothing: antialiased;
  width: 100%;
  position: relative;
}

.forum .bracketButton {
  margin: 17px 0;
  padding-right: 16px;
  float: right;
}

.forum_post:after {
  content: "";
  position: absolute;
  border-left: 20px solid transparent;
  border-top: 20px solid rgba(255, 255, 255, 0.22);
  width: 0px;
  height: 0px;
  right: 0px;
  top: 0px;
}

.title_table {
  display: table;
  width: 100%;
}

.title_cell {
  display: table-cell;
  padding: 20px;
  vertical-align: middle;
}

.post_title {
}

.title_right_side {
  font-weight: 100;
}

.post_user {
  text-align: right;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
}

.post_date {
  text-align: right;
  font-size: 10px;
  color: #fff77d;
  font-weight: 300;
}

.forum_txt {
  font-size: 13px;
  padding: 20px 20px;
  background: #ffffff08;
  /* margin: 0; */
  border-bottom: 6px solid rgba(255, 255, 255, 0.22);
}

.forum_tags span {
  font-family: "Oswald";
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
}

.forum_tags span.hastag {
  color: #fff77d;
  margin: 0 10px;
}

.comments {
}

.comment {
}

.main_comment {
  display: flex;
  width: 100%;
  padding: 20px 0;
}

.comment_details {
  display: inline-block;
  float: left;
  width: 140px;
  padding: 0 10px;
}

.comment_author {
  text-align: right;
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  font-weight: 500;
}

.comment_date {
  text-align: right;
  font-size: 12px;
  color: #fff77d;
}

.comment_body {
  display: inline-block;
  float: left;
  width: calc(100% - 180px);
  padding: 0 10px;
}

.comment_title_likes {
  display: flex;
}

.comment_title {
  width: calc(100% - 200px);
  padding: 0;
  display: inline-block;
  float: left;
  font-weight: 500;
  font-size: 15px;
}

.comment_likes {
  width: 200px;
  padding: 0;
  display: inline-block;
  float: left;
}

.likes,
.dislikes {
  display: inline-block;
  float: left;
  margin: 0 10px;
  font-size: 18px;
}

.likesNum {
  font-size: 12px;
  padding: 4px;
}

.icon-btn_dislike:before {
  color: #0f5569;
  cursor: pointer;
}

.icon-btn_like:before {
  color: #33b3bf;
  cursor: pointer;
}

.icon-btn_dislike:hover:before {
  color: #fff77d;
}

.icon-btn_like:hover:before {
  color: #fff77d;
}

.comment_txt {
  color: rgba(255, 255, 255, 0.7);
}

.comment_txt a {
  color: #fff77d;
}

.sub_comment {
  /*display: flex;*/
  padding: 10px 0;
}

.sub_comment .comment_details {
  display: inline-block;
  float: left;
  width: 180px;
  padding: 0 10px;
}

.comment_author {
}

.sub_comment .comment_date {
  color: #33b3bf;
}

.sub_comment .comment_body {
  display: inline-block;
  float: left;
  width: calc(100% - 220px);
  padding: 0 10px;
}

.comment_txt span {
}

.post_comment {
  flex: 0 0 100px;
  border: 0;
  font-size: 16px;
  background: #2564c9;
  color: #fff;
  position: relative;
}

.sub_comment_form {
  display: inline-block;
  float: right;
  width: calc(100% - 220px);
  padding: 0 10px;
}

.sub_comment_form form {
  position: relative;
}

.sub_comment_form input.body_field {
  padding: 15px 120px 15px 20px;
  background: rgba(0, 0, 0, 0.13);
}

.sub_comment_form input.post_comment {
  flex: 0 0 100px;
  border: 0;
  font-size: 14px;
  background: none;
  color: #fff77d;
  -webkit-text-fill-color: #fff77d !important;
  top: 0;
  right: 0;
  padding: 12px;
  position: relative;
  position: absolute;
  font-family: "Oswald";
  cursor: pointer;
}

.leave_comment {
  width: calc(100% - 20px);
  padding: 20px 10px;
  display: inline-block;
}

.leave_comment label {
  font-weight: 500;
}

/*Small devices (landscape phones, less than 768px)*/
@media screen and (max-width: 767px) {
  .title_table {
    display: table;
    width: 100%;
  }

  .title_cell {
    display: inline-block;
    float: left;
    padding: 10px;
    width: calc(100% - 20px);
    font-size: 15px;
    font-weight: 500;
  }

  .post_user,
  .post_date {
    text-align: left;
    display: inline-block;
    font-size: 14px;
    margin-right: 7px;
  }

  .main_comment {
    display: inline-block;
    padding: 20px 0 10px 0;
  }

  .comment_details {
    display: inline-block;
    float: left;
    width: 100%;
    padding: 0;
  }

  .comment_author,
  .comment_date {
    text-align: left;
    display: inline-block;
    margin-right: 7px;
  }

  .comment_body {
    display: inline-block;
    float: left;
    width: 100%;
    padding: 0;
  }

  .comment_title {
    width: calc(100% - 40px);
    padding: 10px 0;
  }

  .sub_comment {
    display: inline-block;
  }

  .sub_comment .comment_details {
    display: inline-block;
    float: left;
    width: calc(100% - 40px);
    padding: 0 0 0 40px;
    margin: 4px 0;
  }

  .sub_comment .comment_body {
    display: inline-block;
    float: left;
    width: calc(100% - 40px);
    padding: 0 0 0 40px;
    margin: 4px 0;
  }

  .sub_comment_form {
    display: inline-block;
    float: right;
    width: calc(100% - 40px);
    padding: 0 0 0 40px;
  }

  .leave_comment {
    width: 100%;
    padding: 20px 0px;
    display: inline-block;
  }
}

.forum-topic--comment-answer {
  padding-left: 10px;
  font-size: 13px;
}

.forum_back_to_topics {
  margin-top: 20px;
}

.forum_rightSide .bracketButton {
  float: right;
}

.topicDropdowns {
  width: 100%;
  min-width: 280px;
}

.topicDropdowns .side_field {
  width: 30%;
  min-width: 240px;
  display: inline-block;
}

@media screen and (max-width: 767px) {
  .topicDropdowns .side_field {
    width: 100%;
    min-width: 240px;
  }
}

.wysiwyg {
  /*padding: 1rem ;*/
  /*border-radius: 6px;*/
  /*-webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1), 0 1px 5px rgba(0, 0, 0, .15);*/
  /*        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1), 0 1px 5px rgba(0, 0, 0, .15);*/
  max-width: 100%;

  resize: vertical;
  width: 100%;
  font-size: 14px;
  border: none;
  margin: 8px 0;
  outline: none;
  padding: 15px 40px 15px 20px;
  box-sizing: border-box;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background: rgba(255, 255, 255, 0.13);
  color: #ced9de;
  -webkit-text-fill-color: rgba(255, 255, 255, 0.8);
  font-family: "CF Asty Std";
}

.rdw-dropdown-wrapper * {
  color: black !important;
  -webkit-text-fill-color: initial !important;
}

.wysiwyg__toolbar {
  border: none;
  padding: 4px 0;
  background: transparent;
  border-bottom: 1px solid #fff;
}

.rdw-editor-wrapper .rdw-link-modal {
  border: none;
  background-color: #1c869d;
  box-shadow: none;
}

.rdw-editor-wrapper .rdw-link-modal-input {
  border: none;
  background: #ffffff21;
  font-size: 12px;
}

.rdw-editor-wrapper .rdw-link-modal-btn {
  border: none;
  background: #024a6c;
  font-size: 12px;
  box-shadow: none;
}

.rdw-editor-wrapper .rdw-link-modal-btn:hover {
  background: #1c869d;
}

.rdw-editor-wrapper .rdw-colorpicker-modal {
  border: none;
  background-color: #1c869d;
  box-shadow: none;
}

.rdw-editor-wrapper .rdw-embedded-modal {
  border: none;
  background-color: #1c869d;
  box-shadow: none;
}

.rdw-editor-wrapper .rdw-embedded-modal-link-input {
  border: none;
  background: #ffffff21;
  font-size: 12px;
}

.rdw-editor-wrapper .rdw-embedded-modal-size-input {
  border: none;
  background: #ffffff21;
  font-size: 12px;
}

.rdw-editor-wrapper .rdw-embedded-modal-btn {
  border: none;
  background: #024a6c;
  font-size: 12px;
  box-shadow: none;
}

.rdw-editor-wrapper .rdw-embedded-modal-btn:hover {
  background: #1c869d;
}

.rdw-editor-wrapper .rdw-emoji-modal {
  border: none;
  background-color: #1c869d;
  box-shadow: none;
}

.rdw-editor-wrapper .rdw-link-decorator-wrapper {
  /*display: inline-flex;*/
  display: inline-block;
}
.rdw-editor-wrapper .rdw-link-decorator-wrapper a {
  color: #fff77d;
}

.rdw-editor-wrapper .rdw-link-decorator-icon {
  max-width: 12px;
  padding: 8px;
  position: absolute;
  top: 8px;
  right: 8px;
  left: auto;
  left: initial;
  -webkit-transform: translate(100%, -100%);
          transform: translate(100%, -100%);
  background: transparent;
}

.rdw-editor-wrapper .rdw-link-modal-target-option input {
  -webkit-appearance: checkbox !important;
}

.rdw-editor-wrapper .rdw-image-modal {
  border: none;
  background-color: #1c869d;
  box-shadow: none;
}

.rdw-editor-wrapper .rdw-image-modal-url-input {
  border: none;
  background: #ffffff21;
  font-size: 12px;
}

.rdw-editor-wrapper .rdw-image-modal-size-input {
  border: none;
  background: #ffffff21;
  font-size: 12px;
}

.rdw-editor-wrapper .rdw-image-modal-btn {
  border: none;
  background: #024a6c;
  font-size: 12px;
  box-shadow: none;
}

.rdw-editor-wrapper .rdw-image-modal-btn:hover {
  background: #1c869d;
}

.wysiwyg__toolbar .rdw-inline-wrapper .rdw-option-wrapper:first-child {
  margin-left: 0;
}

.wysiwyg__toolbar .rdw-inline-wrapper .rdw-option-wrapper:last-child {
  margin-right: 0;
}

.wysiwyg__editor {
  max-width: 100%;
  min-height: 300px;
}

@media screen and (max-width: 450px) {
  .wysiwyg__toolbar {
    display: none;
  }

  .wysiwyg__editor {
    max-width: 100%;
    min-height: 100px;
  }
}

.rdw-option-wrapper {
  min-width: 11px;

  /*padding: 10px 60px;*/
  color: #024a6c;
  border: none;
  outline-offset: 3px;
  outline: 1px solid rgba(255, 255, 255, 0.25);
  margin: 4px;
  margin-right: 8px;
  font-family: "CF Asty Std";
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  position: relative;
}

.rdw-dropdown-optionwrapper {
  color: #024a6c;
}

#add_topic_form {
}

#add_topic_form h2 {
  color: #006e80;
}

#add_topic_form .block__form_text_input {
  border: none !important;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1), 0 1px 5px rgba(0, 0, 0, 0.15);
}

.subscribe-popup-table {
  max-height: 390px;
  overflow: auto;
}

/*# sourceMappingURL=subscribeModal.css.map */


.features {
  display: grid;
  grid-template-columns: 50% 50%;
}

.aFeature {
  float: left;
  display: inline-block;
  margin: 10px 0;
}
.aFeature p {
  margin: 0;
}

.featureIcon {
  width: 30px;
  padding: 0 10px;
  float: left;
  display: inline-block;
}

.featureContent {
  width: calc(100% - 72px);
  float: left;
  padding: 0 10px;
  display: inline-block;
  border-left: 2px solid rgba(255, 255, 255, 0.8);
}

.fetureTitle {
  font-size: 15px;
}

.fetureDescr {
  color: rgba(255, 255, 255, 0.8);
  font-size: 13px;
}

@media screen and (max-width: 991px) {
  .aFeature {
    width: 100%;
  }

  .features {
    display: block;
  }
}

/*# sourceMappingURL=apiProductFeatures.css.map */

.cornersButton-wrapper {
  position: relative;
  float: left;
  margin: 6px 0;
  width: 100%;
  width: 100%;
  position: relative;
}

cornersButton-wrapper:hover:before,
.cornersButton-wrapper.enable:before {
  content: "";
  position: absolute;
  border-top: 1px solid rgba(255, 255, 255, 0.7);
  border-left: 1px solid rgba(255, 255, 255, 0.7);
  top: -4px;
  left: -4px;
  width: 10px;
  height: 10px;
  z-index: 1;
}

.cornersButton-wrapper:hover .cornersButton:before,
.cornersButton-wrapper.enable .cornersButton:before {
  content: "";
  position: absolute;
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  border-left: 1px solid rgba(255, 255, 255, 0.7);
  bottom: -4px;
  left: -4px;
  width: 10px;
  height: 10px;
  z-index: 1;
}

.cornersButton-wrapper:hover .btn:after,
.cornersButton-wrapper.enable .btn:after {
  border-top: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
}

.cornersButton-wrapper:hover .cornersButton:after,
.cornersButton-wrapper.enable .cornersButton:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  border-right: 1px solid rgba(255, 255, 255, 0.7);
  bottom: -4px;
  right: -4px;
  width: 10px;
  height: 10px;
  z-index: 1;
}

.cornersButton-wrapper:hover:after,
.cornersButton-wrapper.enable:after {
  content: "";
  position: absolute;
  border-top: 1px solid rgba(255, 255, 255, 0.7);
  border-right: 1px solid rgba(255, 255, 255, 0.7);
  top: -4px;
  right: -4px;
  width: 10px;
  height: 10px;
  z-index: 1;
}

.txtBox-wrapper.advantage-wrapper {
  width: 1345px;
  position: relative;
  width: calc(100% - 20px);
}

.advantage-wrapper-inner {
  background-color: rgba(255, 255, 255, 0.13);
  padding-top: 40px;
  padding-left: 40px;
  padding-bottom: 100px;
  padding-right: 150px;
  -webkit-clip-path: polygon(
    0% 0%,
    0% 100%,
    calc(100% - 60px) 100%,
    100% calc(100% - 60px),
    100% 0
  );
  clip-path: polygon(
    0% 0%,
    0% 100%,
    calc(100% - 60px) 100%,
    100% calc(100% - 60px),
    100% 0
  );
  position: relative;
  margin-bottom: 30px;
}

.advantage-wrapper-inner:before {
  content: "";
  position: absolute;
  border-left: 30px solid transparent;
  /* border-right: 20px solid transparent; */
  border-top: 30px solid rgba(255, 255, 255, 0.22);
  /* border-bottom: 20px solid #1a4663; */
  width: 0px;
  height: 0px;
  /* transform: rotate(45deg); */
  right: 0px;
  top: 0px;
}

.advantage-wrapper-inner a {
  color: #fff77d;
}

.txtBox-wrapper.advantage-wrapper p {
  padding: 10px 0;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.8);
}

.advantage-wrapper-inner:after {
  content: "";
  position: absolute;
  /* border-left: 30px solid transparent; */
  border-right: 30px solid transparent;
  /* border-top: 30px solid #1a4663; */
  border-bottom: 30px solid rgba(255, 255, 255, 0.22);
  width: 0px;
  height: 0px;
  /* transform: rotate(45deg); */
  left: 0px;
  bottom: 0px;
}

@media screen and (max-width: 1950px) {
  .txtBox-wrapper.advantage-wrapper {
    width: auto;
  }

  .nbg-hub-apis-product .advantage-wrapper-inner {
    margin-top: 30px;
  }

  .advantage-wrapper-inner {
    padding-right: 100px;
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 1199px) {
  .nbg-hub-apis-product .simple_btn .btn,
  .simple_btn .btn {
    padding: 10px 20px;
  }
}

@media screen and (max-width: 991px) {
  .nbg-hub-apis-product .simple_btn {
    width: 100%;
    margin: 10px 0;
  }

  .nbg-hub-apis-product .simple_btn.left {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) {
  .nbg-hub-apis-product .simple_btn .btn {
    width: calc(100% - 8px);
  }

  .nbg-hub-apis-product .advantage-wrapper-inner {
    margin-top: 0px;
    padding-top: 5px;
  }

  .advantage-wrapper-inner {
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 50px;
  }

  .txtBox-wrapper.advantage-wrapper p {
    padding: 10px 0;
    line-height: 1.2em;
    font-size: 13px;
  }
}


.home-top-banner-image-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 50px 30px 0;
  position: relative;
  margin: 0 auto 50px auto;
}
.home-top-banner-image-wrapper .home-image-outer-wrapper {
  max-width: 2000px;
  width: 80%;
  background-size: 100% 100%;
  position: relative;
  margin-left: auto;
  background-image: url(/static/media/04_image_frame-white.8597e641.svg);
}
@media only screen and (max-width: 1199px) {
  .home-top-banner-image-wrapper .home-image-outer-wrapper {
    max-width: 2000px;
    width: 100%;
    position: relative;
    float: left;
  }
}
.home-top-banner-image-wrapper .home-image-outer-wrapper .home-image-label {
  padding: 20px 0;
  margin: 0px 0;
  width: 100%;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
}
.home-top-banner-image-wrapper .home-image-inner-wrapper {
  -webkit-clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 22%, calc(100% - 10%) 0%);
          clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 22%, calc(100% - 10%) 0%);
  padding: 1.6% 1.2%;
}
.home-top-banner-image-wrapper .home-image-inner-wrapper > div {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  padding: 43.52% 0 0 0;
}
.home-top-banner-image-wrapper .home-image-inner-wrapper img {
  object-fit: cover;
  display: block;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.home-top-banner-image-wrapper .over-image-21 {
  position: absolute;
  top: 5px;
  left: 0;
  width: 97.6%;
  padding: 1.1% 1.2%;
}
@media screen and (max-width: 2756px) {
  .home-top-banner-image-wrapper .home-top-banner-image-wrapper {
    width: 100%;
  }
}

.home-page-title {
  position: absolute;
  z-index: 1000;
  top: 50%;
  -webkit-transform: translate(0%, -50%);
          transform: translate(0%, -50%);
  max-width: 30%;
}
@media only screen and (max-width: 1199px) {
  .home-page-title {
    max-width: none;
    max-width: initial;
    margin-bottom: 16px;
  }
}

.home-image-txt {
  margin-bottom: 20px;
}

/*# sourceMappingURL=headerImage.css.map */

.home-image-label {
  position: absolute;
  width: 300px;
  bottom: 50px;
  right: 80px;
  padding: 20px;
  background: #195a739c;
}
@media only screen and (max-width: 1199px) {
  .home-image-label {
    padding: 20px 0;
    margin: 0px 0;
    width: 100%;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    position: static;
    position: initial;
    flex: 1 1;
    background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
    background: initial;
  }
}

/*# sourceMappingURL=headerLabel.css.map */

.productNotificationSubscribe i {
  font-size: medium;
  font-size: initial;
}
.productNotificationSubscribe p {
  font-size: 0.8rem !important;
  line-height: normal !important;
  line-height: initial !important;
  letter-spacing: normal;
  letter-spacing: initial;
  font-family: "CF Asty Std";
}

.productNotificationSubscribe .popover__content {
  display: flex;
}

:root {
  --border-color: rgba(255, 255, 255, 0.22);
  --light-title-color: rgba(255, 255, 255, 0.7);
}

.comparison-table {
  margin: 36px 0px;
  border-collapse: collapse;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.22);
  border-bottom: 1px solid var(--border-color);
}
.comparison-table .category-heading {
  font-weight: bold;
}
.comparison-table .category-heading-light {
  color: rgba(255, 255, 255, 0.7);
  color: var(--light-title-color);
}
.comparison-table th {
  padding: 0 1em 1em;
}
.comparison-table th:nth-child(2) {
  background-color: rgba(255, 255, 255, 0.22);
  background-color: var(--border-color);
}
.comparison-table tr {
  padding: 0;
}
.comparison-table td {
  text-align: center;
  padding: 1em;
  border-top: 1px solid rgba(255, 255, 255, 0.22);
  border-top: 1px solid var(--border-color);
  border-spacing: 0;
}
.comparison-table td:nth-child(2) {
  border: 0px;
  background-color: rgba(255, 255, 255, 0.22);
  background-color: var(--border-color);
}

@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  .comparison-table-container {
    overflow: scroll;
  }
}

/*# sourceMappingURL=comparisonTable.css.map */

.ApiButton {
  position: relative;
  padding: 14px 50px 14px 20px;
  margin: 6px 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.13);
  border: none;
  font-family: "CF Asty Std";
  font-weight: 500;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  text-align: left;
}

.ApiButton:first-child {
  margin: 0 0 6px 0;
}

.ApiButton .icon-icon_open:before {
  /* content: "\e937"; */
  position: absolute;
  right: 20px;
  color: rgba(255, 255, 255, 0.8);
}

.nbg_table .array_descr {
  font-family: "Oswald";
}

.nbg-api-doc-button-wrapper .bracketButton {
  display: flex;
}

.nbg-api-doc-button-wrapper .bracketButton .movingBrackets.right {
  width: calc(100% - 12px);
}

.nbg-api-doc-button-wrapper .movingBrackets.right {
  text-align: right;
}

ul.table {
  margin: 0 0 40px 0;
  padding: 0;
  width: 100%;
  display: table;
  position: relative;
}

ul.table {
  list-style-type: none;
}

.accordion_table ul.table {
  margin: 0;
}

.row {
  display: flex;
  background: #ffffff0a;
}

.cell {
  padding: 6px 12px;
  display: table-cell;
  font-weight: 500;
}

.row.tHeader,
.row.tFooter {
  font-weight: 900;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.13);
  -webkit-font-smoothing: antialiased;
}

.accordion_table .row .cell {
  margin-bottom: 0px;
}

.row.tHeader .cell,
.row.tFooter .cell {
  padding: 30px 12px;
  font-weight: 500;
}

.accordion_table .cell {
  padding: 2px 12px;
  display: table-cell;
  width: 70%;
}

.accordion_table .cell.accordion_column {
  width: 20px;
}

.row.tHeader:after {
  content: "";
  position: absolute;
  border-left: 20px solid transparent;
  border-top: 20px solid #ffffff40;
  width: 0px;
  height: 0px;
  right: 0px;
  top: 0px;
}

.cell button {
  background: #fff0;
  border: none;
  color: #fff;
  cursor: pointer;
  display: contents;
}

.accordion_table .cell.accordion_column button {
  display: flex;
}

.row.accord_title .cell {
  padding: 10px 12px;
}

.table_show_hide {
  display: block;
  width: 100%;
}

.arr_line_2 span {
  margin: 0 10px 0 0;
}

.transp_white {
  color: rgba(255, 255, 255, 0.8);
}

.row.tFooter {
  -webkit-clip-path: polygon(
    0% 0%,
    0% 100%,
    calc(100% - 30px) 100%,
    100% calc(100% - 30px),
    100% 0
  );
  clip-path: polygon(
    0% 0%,
    0% 100%,
    calc(100% - 30px) 100%,
    100% calc(100% - 30px),
    100% 0
  );
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.row.tFooter:after {
  content: "";
  position: absolute;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #ffffff40;
  width: 0px;
  height: 0px;
  left: 0px;
  bottom: 0px;
}

.notification__subscription {
  margin-top: 16px;
}

@media screen and (max-width: 991px) {
  .nbg-api-doc-button-wrapper .bracketButton {
    display: flex;
    margin: 6px 0;
    padding: 0 12px;
  }
}

/*# sourceMappingURL=productDetails.css.map */

.changelog-container {
  margin-bottom: 16px;
}

.changelog {
  color: white;

  font-size: 0.8rem;
  margin-top: 8px;
  margin-bottom: 8px;
}

/*.changelog:nth-of-type(odd) {*/
/*    background: rgb(245, 245, 245);*/
/*}*/

.changelog__bar {
  background: rgba(255, 255, 255, 0.13);
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
}

.changelog__bar:hover {
  /*color: #006E80;*/
}

.changelog__bar {
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.changelog__bar > div {
  display: flex;
  align-items: center;
  text-align: center;
}

.changelog__bar > div > small {
}

.changelog__bar > div > small:first-child {
  margin-right: 20px;
}

.changelog__bar > div .tag {
  margin-left: 5px;
  font-size: inherit;
  text-transform: capitalize;
}

.changelog__toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  cursor: pointer;

  padding: 8px;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.changelog__toggle svg {
  fill: white;
}

/*.changelog__bar:hover .changelog__toggle svg {*/
/*    fill: #006E80;*/
/*}*/

.changelog__toggle svg {
  width: 16px;
  height: 16px;
}

.changelog__contents {
  color: rgba(255, 255, 255, 0.57);
  font-size: 11px;
  padding: 1px 16px 8px;
  background: transparent;
}

.changelog--open .changelog__bar:hover {
  /*background: none;*/
  /*color: #006E80;*/
}

.changelog--open .changelog__toggle span {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.st0 {
  fill: #859096;
}
.st1 {
  fill: #fff77d;
}
.st2 {
  fill: #ffffff;
}
.st3 {
  opacity: 0.1;
  fill: #ffffff;
}
.st4 {
  fill: #33b3bf;
}
.st5 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ffffff;
}
.st6 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #007580;
}
.st7 {
  fill: #231f20;
}
.st8 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #231f20;
}
.st9 {
  fill: #c49958;
}
.st10 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 0.9789;
  stroke-miterlimit: 10;
}
.st11 {
  opacity: 0.3;
}
.st12 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 1.0001;
  stroke-miterlimit: 10;
}
.st13 {
  fill: none;
  stroke: #fff77d;
  stroke-width: 1.0001;
  stroke-miterlimit: 10;
}
.st14 {
  fill: none;
  stroke: #fff77d;
  stroke-width: 0.5;
  stroke-miterlimit: 10;
}
.st15 {
  fill: #024a6c;
}
.st16 {
  fill: #1c869d;
}
.st17 {
  fill: #1b2d4c;
}
.st18 {
  opacity: 0.5;
  fill: #1b2d4c;
}
.st19 {
  opacity: 0.2;
  fill: #1b2d4c;
}
.st20 {
  fill: url(#SVGID_1_);
}
.st21 {
  fill: url(#SVGID_2_);
}
.st22 {
  fill: url(#SVGID_3_);
}
.st23 {
  opacity: 0.7;
  fill: url(#SVGID_4_);
}
.st24 {
  opacity: 0.5;
  fill: #ffffff;
}
.st25 {
  opacity: 0.5;
}
.st26 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 1.0014;
  stroke-miterlimit: 10;
}
.st27 {
  fill: none;
  stroke: #fff77d;
  stroke-width: 1.0014;
  stroke-miterlimit: 10;
}
.st28 {
  opacity: 0.15;
}
.st29 {
  -webkit-clip-path: url(#SVGID_6_);
  clip-path: url(#SVGID_6_);
}
.st30 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 0.9738;
  stroke-miterlimit: 10;
}
.st31 {
  opacity: 0.05;
  fill: #ffffff;
}
.st32 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 0.5;
  stroke-miterlimit: 10;
}
.st33 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 2;
  stroke-miterlimit: 10;
}
.st34 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #1c869d;
}
.st35 {
  fill: #c1272d;
}
.st36 {
  fill: #0b698e;
}
.st37 {
  opacity: 0.1;
}
.st38 {
  -webkit-clip-path: url(#SVGID_8_);
  clip-path: url(#SVGID_8_);
}
.st39 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 1.0005;
  stroke-miterlimit: 10;
  stroke-opacity: 0.3;
}
.st40 {
  opacity: 0.3;
  fill: #ffffff;
}

.chatbot .moveBox__window {
  bottom: 92px;
  right: 99px;
}

.chatbot .moveBox__navbar {
  border-bottom: 1px solid lightgray;
  background-color: #366563;
  position: relative;
}

.chatbot .moveBox__content {
  margin-bottom: -6px;
}

.chatbot__iframe {
  min-height: 400px;
  width: 100%;
  height: 100%;
  margin-top: -40px;
  border: none;
}

.chatbot .moveBox__expand {
  background: white;
}

.chatbot .moveBox__expand svg {
  width: 25px;
}

@media screen and (max-width: 900px) {
  .chatbot .moveBox__window {
    bottom: 160px;
    right: 16px;
  }

  .chatbot__iframe {
    min-height: 200px;
  }

  .chatbot .moveBox__content {
    max-height: none !important;
    max-height: initial !important;
    overflow-y: auto;
  }
}

.moveBox__window {
  padding: 0 !important;
  width: 460px;
  color: black;
  position: fixed;
  background: #1c869d;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1), 0 1px 6px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.moveBox__content {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  /*max-height: 300px;*/
  margin-bottom: auto;
  background: #1c869d;
}

.moveBox__navbar {
  background: black;
  padding: 14px 20px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: -webkit-grab;
  cursor: grab;
}

.moveBox__navbar span {
  line-height: 1;
  font-size: 90%;
  width: 70%;
}

.moveBox__navbar button {
  font-family: inherit;
  background: #222d32;
  padding: 0 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-left: 10px;
  border: none;
  color: white;
  cursor: pointer !important;
  outline: none !important;
}

.moveBox__navbar button.minify {
  background: #ffdb7a;
  color: black;
}

.moveBox__navbar button.close {
  color: white;
  background-color: #ac3931;
}

.moveBox--moving * {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
}

.moveBox--minified .moveBox__content {
  display: none;
}

.moveBox__expand {
  background: #ffcd47;
  color: white;
}

.moveBox--minified svg {
  width: 25px;
  fill: black;
}

.moveBox__resizer {
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
  cursor: se-resize !important;
}

.moveBox__resizer::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 4px;
  background: transparent;
  color: lightgrey;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  opacity: 0.6;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 15px 0 currentColor, 15px 5px currentColor, 15px 10px currentColor, 15px 15px currentColor, 10px 5px currentColor, 10px 10px currentColor, 10px 15px currentColor, 5px 10px currentColor, 5px 15px currentColor, 0 15px currentColor;
}

.moveBox__resizer:hover .moveBox__resizer::before {
  opacity: 1;
}

.moveBox__resizer--overflown {
  right: 12px;
}

@media screen and (max-width: 900px) {
  .moveBox__resizer {
    display: none;
  }
}
.moveBox__actions {
  padding: 0 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.22);
}

.moveBox--minified .moveBox__expand:hover svg {
  -webkit-animation-name: hvr-pulse;
  animation-name: hvr-pulse;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.moveBox--minified .moveBox__window {
  display: none;
}

/******************************************************************************/
.moveBox__appBar {
  display: flex;
  justify-content: flex-end;
  padding: 0 10px;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.22);
  background: #1c869d;
}

.moveBox__appBar > * {
  margin-left: 8px;
}

.moveBox__appBar--absolute {
  position: absolute;
  top: 48px;
  right: 0;
  left: 0;
  border-bottom: none;
}

/******************************************************************************/
.moveBox__external-btn svg {
  height: 14px;
  width: 14px;
  fill: white;
}

.moveBox--external {
  padding: 0;
}

.moveBox--external .moveBox__window {
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
}

.moveBox--external .moveBox__content {
  max-height: 100% !important;
  height: 100% !important;
}

.moveBox--external .moveBox__content * {
  font-size: 13px !important;
}

.moveBox--external .tryout-form-body {
  height: 100% !important;
}

.moveBox--external .moveBox__window {
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
}

.moveBox__navbar {
  touch-action: none;
}

.moveBox__navbar button {
  padding: 0 6px;
  font-size: 16px;
}

@media screen and (max-width: 900px) {
  .moveBox--fullscreenMobile .moveBox__navbar,
.moveBox--fullscreenMobile .tryout-form__top,
.moveBox--fullscreenMobile .tryout-form__content form {
    padding: 8px 12px;
    font-size: 0.7rem;
  }

  .moveBox--fullscreenMobile .parameters-container label {
    font-size: 0.8rem;
  }

  .moveBox--fullscreenMobile .moveBox__appBar {
    display: none;
  }

  .moveBox--fullscreenMobile .moveBox__window {
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
    height: 100% !important;
  }
}
@media screen and (max-width: 900px), screen and (max-height: 800px) {
  .moveBox__window {
    max-width: 90vw;
    touch-action: none;
  }

  .moveBox__content {
    max-height: 50vh;
  }

  .moveBox--minified .moveBox__expand {
    right: 20px;
    bottom: 180px;
  }

  /*fullscreen mobile*/
}
@-webkit-keyframes hvr-pulse {
  25% {
    width: 30px;
    height: 30px;
  }
  75% {
    width: 35px;
    height: 35px;
  }
}
@keyframes hvr-pulse {
  25% {
    width: 30px;
    height: 30px;
  }
  75% {
    width: 35px;
    height: 35px;
  }
}

/*# sourceMappingURL=MoveBox.css.map */

.fabButton {
  position: fixed;
  top: auto;
  top: initial;
  left: auto;
  left: initial;
  /*cursor: pointer;*/
  right: 16px;
  bottom: 20px;
  width: 30px;
  height: 30px;
  background: #ffffff1f;
  color: #fff77d;
  -webkit-clip-path: polygon(0% 0%, 0% 100%, calc(100% - 8px) 100%, 100% calc(100% - 8px), 100% 0);
  clip-path: polygon(0% 0%, 0% 100%, calc(100% - 8px) 100%, 100% calc(100% - 8px), 100% 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  cursor: pointer;
}

.fabButton > * {
  padding: 6px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
}

.fabButton span {
  font-size: 14px;
}

.fabButton svg {
  width: 30px;
  height: 30px;
}

@media screen and (max-width: 900px) {
  .fabButton {
    right: 20px !important;
  }
}

/*# sourceMappingURL=fab.css.map */

.dropdown {
  z-index: 2;
}

.dropdown__trigger {
  cursor: pointer;

  padding: 8px;
  border-radius: 50%;
  display: inline-flex;
  position: relative;
}

.dropdown__trigger::before {
  content: "";
  -webkit-transform: scale(0);
  transform: scale(0);
  width: 100%;
  height: 100%;
  border-radius: 50%;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.4;
  -webkit-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
  background: lightgrey;
  z-index: 0;
}

.dropdown__trigger:hover::before {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  background: rgba(255, 255, 255, 0.5019607843137255);
}

.dropdown__trigger svg {
  width: 16px;
  height: 16px;
  fill: white;
  z-index: 1;
}

.dropdown__content {
  border-top: rgba(0, 0, 0, 0.05) 1px solid;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  display: none;
  position: absolute;
  right: 15px;
  margin-top: 5px;
  background: #ffffff;
  color: black;
  font-size: 0.97rem;
}

.dropdown__item {
  padding: 10px 15px;
  cursor: pointer;
}

.dropdown__item:hover {
  background: lightgrey;
}

.dropdown__item--no-hover:hover {
  background: none;
}

.dropdown__segment {
  border-bottom: #e1e1e1 1px solid;
}

.dropdown--active .dropdown__content {
  display: block;
}

.dockIcon {
  display: inline-block;
  color: darkgrey;
  border: 2px solid currentColor;
  width: 18px;
  height: 14px;
  box-sizing: border-box;
  margin-left: 8px;
  vertical-align: center;
}

.dockIcon:hover {
  color: black;
}

.dockIcon--active {
  color: #00bcd4 !important;
}

.dockIcon--left {
  border-left-width: 6px;
}

.dockIcon--right {
  border-right-width: 6px;
}

.dockIcon--bottom {
  border-bottom-width: 6px;
}

.dockIcon--external {
  box-shadow: -1px 1px 0 white, -3px 3px 0 currentColor;
  margin-left: 12px;
  width: 15px;
  height: 12px;
  margin-bottom: 2px;
}

.tryout-form * {
  box-sizing: border-box;
}

.tryout-form {
  padding: 10px;
}

.tryout-form .moveBox__expand {
  background-color: #81c14b;
}

.tryout-form .moveBox__content {
  background-color: #1c869d;
}

.tryout-form .moveBox__expand svg {
  fill: white;
}

.tryout-form--response .moveBox__expand {
  background-color: #8f2d56;
}

.tryout-form--response .moveBox__expand svg {
  fill: white;
}

.tryout-form table {
  width: 100%;
  table-layout: fixed;
}

.tryout-form .param-required {
  position: relative;
  font-size: 14px;
}

.tryout-form .param-required::after {
  content: "*";
  color: #fff77d;
}

.tryout-form .param-required::before {
  content: "required";
  opacity: 0;
  top: 50%;
  position: absolute;
  left: -20px;
  color: #e53935;
  -webkit-transform: translate(-100%, -50%) scale(0.2);
  transform: translate(-100%, -50%) scale(0.2);
  background-color: rgba(229, 57, 53, 0.1);
  -webkit-transition: opacity 0.2s, -webkit-transform 0.1s ease-out;
  transition: opacity 0.2s, -webkit-transform 0.1s ease-out;
  transition: opacity 0.2s, transform 0.1s ease-out;
  transition: opacity 0.2s, transform 0.1s ease-out, -webkit-transform 0.1s ease-out;
  transition: opacity 0.2s, transform 0.1s ease-out,
    -webkit-transform 0.1s ease-out;
}

.tryout-form .param-required:hover::before {
  opacity: 1;
  border-radius: 10px;
  padding: 1px 6px;
  -webkit-transform: translate(-100%, -50%) scale(1);
  transform: translate(-100%, -50%) scale(1);
}

.tryout-form__top {
  flex: 0 0 auto;
  padding: 10px 20px;
  border-radius: 4px 4px 0 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  -webkit-transition: border-color 0.25s ease;
  transition: border-color 0.25s ease;
  border-bottom: 1px solid rgba(255, 255, 255, 0.22);
}

.tryout-form__top:hover {
  text-overflow: initial;
  word-break: break-all;
  white-space: normal;
  white-space: initial;
}

.tryout-form form {
  flex: 1 1;
  padding: 10px 20px;
  font-family: inherit;
  font-size: inherit;
}

.tryout-form .cancel-request {
  margin-left: auto;
  margin-right: 20px;
  outline-color: lightgray !important;
}

.tryout-form .cancel-request:before,
.tryout-form .cancel-request:after {
  background: lightgray;
}

/**************************************************************/

.tryout-form--horizontal form {
  display: flex;
}

.tryout-form--horizontal form > * {
  flex: 1 1 50%;
  min-width: 300px;
}

.tryout-form--horizontal form > table {
  height: 100% !important;
}

.tryout-form--horizontal .moveBox__content {
  overflow: visible !important;
  overflow: initial !important;
}

.tryout-form--horizontal .tryout-form__content {
  overflow: auto;
}

/**************************************************************/

.tryout-form form thead {
}

.tryout-form form thead th {
  /*border-bottom: 1px solid rgba(255, 255, 255, 0.22);*/
  text-align: left;
  text-transform: uppercase;
  line-height: 20px;
  font-size: 90%;
  font-weight: 300;
  color: white;
  padding: 10px 0;
}

.tryout-form form body th {
  color: #263238;
  text-align: left;
  padding-top: 10px;
  line-height: 1;
  font-weight: 700;
  font-size: 0.9rem;
}

.tryout-form form tbody td {
  /*vertical-align: bottom;*/
  padding-top: 8px;
}

.tryout-form form input[type="text"],
.tryout-form form textarea {
  width: 100%;
  /* border: 2px solid #aaa; */
  /* border-radius: 4px; */
  font-size: 12px;
  border: none;
  /* margin: 8px 0; */
  outline: none;
  padding: 10px 40px 10px 12px;
  box-sizing: border-box;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background: rgba(255, 255, 255, 0.13);
}
.tryout-form.response textarea {
  color: white;
}

.tryout-form .form-params {
  padding: 10px;
}

.tryout-form .has-error {
  /*background-color: rgba(229, 57, 53, 0.1) !important;*/
  border: 1px solid #e53935 !important;
  /*outline: none !important;*/
}

.try-out {
  border-radius: 3px;
  color: #006e80;
  font-family: inherit;
  text-transform: uppercase;
  margin: 10px 0 !important;
}

.parameters-container label {
  font-size: 14px;
  color: white;
}

.tryout-form .moveBox__window {
  top: 10%;
  left: 22%;
}

.tryout-form--edit .moveBox__window {
  top: 12%;
  left: 24%;
}

.tryout-form .moveBox__actions {
  display: flex;
  align-items: center;
}

.tryout-form .moveBox__actions .simple_btn button {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 7px 30px;
}

.tryout-form--response .moveBox__window {
  top: 10%;
  left: 52%;
}

.tryout-form--response .moveBox__content {
  /*background: black;*/
}

/**************************************************************/

.tryout-form .tryOutDescription {
  padding: 1rem 1rem 0 1rem;

  /*width: 100%;*/
}

.tryout-form .tryOutDescription > div:focus {
  background: transparent;
}

/**************************************************************/

.tryout-form__show-history {
  margin: 5px 10px 5px auto;
  border-radius: 5px;
  background: #006e80;
  border: none;
  cursor: pointer;
  padding: 3px 10px;
  display: inline-block;
  line-height: 20px;
  font-size: 0.929em;
  color: white;
}

.tryout-form__content {
  display: flex;
  height: 100%;
}

/**************************************************************/

.tryout-form__btn {
  margin: 0.5rem 0;
  margin-right: 0.5rem;
  /*border-radius: 3px;*/
  border: none;
  cursor: pointer;
  padding: 0.4rem 0.8rem;
  /*padding: 3px 10px;*/
  display: inline-block;
  /*line-height: 20px;*/
  box-sizing: border-box;
}

.tryout-form__btn--send {
  background: #81c14b;
  color: white;
}

.tryout-form__btn--move {
  color: white;
  background: #024a6c;
}
.tryout-form__btn--move:hover {
  color: white;
  background: #1c869d;
}

.tryout-form__btn--move-prev,
.tryout-form__btn--move-next {
  margin-left: auto;
}

.tryout-form__btn--move-prev + .tryout-form__btn--move-next {
  margin-left: 0;
  margin-left: initial;
}

/**************************************************************/
.tryout-form__pin {
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
}

.tryout-form__orientation {
}

.tryout-form__pin--pinned {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.tryout-form__pin svg {
  fill: #e53935 !important;
}

.tryout-form__pin--pinned svg {
  fill: #00bcd4 !important;
}

.tryout-form__pin svg {
  height: 16px;
  width: 10px;
  fill: white;
}

@media screen and (max-width: 1024px) {
  .tryout-form .moveBox__window {
    top: 10%;
    left: 2%;
  }

  .tryout-form--response .moveBox__window {
    top: 10%;
    left: 52%;
  }
}

@media screen and (max-width: 900px) {
  .tryout-form .moveBox__window {
    top: 10%;
    left: 5%;
    padding: 10px;
  }

  .tryout-form--response .moveBox__window {
    top: 50%;
    left: 5%;
  }
}

.redoc_containerInner_noHeight {
  height: 100%;
  /*margin-bottom: 40px!important;*/
}

.redoc_containerInner_noHeight + hr {
  /*margin-top: 4px!important;*/
  box-sizing: border-box !important;
  /*margin-bottom: 40px!important;*/
}

.react-autosuggest__container {
  position: relative;
  color: black;
}

.react-autosuggest__input {
  padding: 10px 20px;
  border: 1px solid #aaa;
  border-radius: 4px;
  -webkit-appearance: none;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input::-ms-clear {
  display: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  border: 1px solid #aaa;
  background-color: #fff;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 5px 8px;
}

.react-autosuggest__suggestion div {
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
}

.redoc-param-button-input {
  display: flex;
  position: relative;
}

.redoc-param-button-input div {
  width: 100%;
}
.redoc-param-button-input button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: none;
}
.redoc-param-button-input:hover button {
  display: block;
}

.react-autosuggest__suggestion--highlighted {
  background-color: rgba(0, 0, 0, 0.02);
}

.token-button {
  width: 96%;
  display: flex;
  justify-content: space-around;
  padding: 8px !important;
}

.client-select-popup-table {
  max-height: 390px;
  overflow: auto;
}
.client-select-popup-table .cell {
  display: flex;
  align-items: center;
}

.client-select-popup-login {
  text-align: center;
  margin: auto;
  width: 30%;
}

.client-select-popup-login-button {
  padding: 12px;
  margin-top: 12px;
  border: 1px solid #fff;
  border-radius: 4px;
}

.selection-cell {
  word-break: break-word;
  border: 1px dotted transparent;
  padding: 4px;
  margin-bottom: 4px;
}
.selection-cell:hover {
  cursor: pointer;
  border: 1px dotted white;
}

.selection-cell-disabled {
  color: lightgray;
  word-break: break-word;
  padding: 8px;
  margin-bottom: 4px;
}

/*# sourceMappingURL=clientIdSelect.css.map */

.redoc-param-clientId {
  position: relative;
}
.redoc-param-clientId:hover button {
  display: block;
}
.redoc-param-clientId button {
  display: none;
  position: absolute;
  cursor: pointer;
  background: white;
  color: black;
  padding: 4px;
  border: none;
  cursor: pointer;
}

.product-select-popup-modal {
  max-width: 700px;
}

.product-select-popup-modal-container {
  margin-top: 24px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.product-select-popup-modal-container div:hover {
  color: #fff77d;
  cursor: pointer;
}

.product-select-popup-modal-title {
  font-family: "Oswald";
  font-weight: 600;
}

.product-select-popu-modal-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/*# sourceMappingURL=redocParametersClientId.css.map */

.radio * {
  box-sizing: border-box;
}

.radio__label {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  margin-bottom: 0;
}

.radio__input {
  display: none;
}

.radio__input:checked {
}

.radio__input:checked + .radio__icon {
  border-color: #fff77d;
}

.radio__input:checked + .radio__icon svg {
}

.radio__input:checked + .radio__icon svg circle {
  fill: #fff77d;
}

.radio__input:checked + .radio__icon svg polyline {
  stroke-dashoffset: 0;
}

.radio__label:hover .radio__icon svg circle {
  stroke-dashoffset: 0;
}

.radio__icon {
  position: relative;
  top: 2px;
  float: left;
  margin-right: 8px;
  width: 20px;
  height: 20px;
  border: 2px solid #5090ad;
  border-radius: 50%;
}

.radio__icon svg {
  position: absolute;
  top: -2px;
  left: -2px;
}

.radio__icon svg circle {
  fill: none;
  stroke: #fff77d;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 71px;
  stroke-dashoffset: 71px;
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
}

.radio__icon svg polyline {
  fill: none;
  stroke: #024a6c;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 18px;
  stroke-dashoffset: 18px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.radio__label > span {
  color: white;
  /*pointer-events: none;*/
  vertical-align: sub;
  cursor: pointer;
}

.tryout-form__top {
  color: white;
}

.tryout-form__top .method {
  font-size: 0.929em;
  line-height: 20px;
  padding: 3px 10px;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  margin: 0;
  flex: 0 0 auto;
}

.tryout-form__top .path {
  padding: 4px 10px;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  color: white;
}

.tryout-form__top .method-post {
  color: #ffffff;
  background-color: #3a7ca5;
}

.tryout-form__top .method-get {
  background-color: #81c14b;
  color: #ffffff;
}

.tryout-form__top .method-put {
  background-color: #7b5e7b;
  color: #ffffff;
}

.tryout-form__top .method-delete {
  background-color: #ac3931;
  color: #ffffff;
}

.orientationIcon {
  display: inline-block;
  color: darkgrey;
  border: 2px solid transparent;
  width: 14px;
  height: 10px;
  margin-left: 8px;
  margin-right: 8px;
  vertical-align: center;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}

.orientationIcon::after,
.orientationIcon::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: currentColor;
}

.orientationIcon::after {
  border: 2px solid currentColor;
}

.orientationIcon::before {
  top: 2px;
  left: 2px;
  border: 2px solid currentColor;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: left;
  transform-origin: left;
}

.orientationIcon:hover {
  color: black;
}

.orientationIcon--horizontal::after,
.orientationIcon--vertical::before {
  color: #ffdb7a;
  z-index: 2;
}

.tryOutDescription {
  box-sizing: border-box;
  text-align: left;
  margin-left: auto;
  color: white;
  font-size: 0.9rem;
  width: 100%;
}

.tryOutDescription * {
  box-sizing: inherit;
}

.tryOutDescription pre {
  margin: 0;
}

.tryOutDescription > div {
  padding: calc(0.5rem + 1px);
}

.tryOutDescription > div:hover {
  border: 1px dashed white;
  padding: 0.5rem;
}

.tryOutDescription > div:focus {
  padding: 0.5rem;
  outline: none;
  border: 1px solid white;
}

.tryOutDescription > div:empty::before {
  content: "Enter Description";
  /*content: "Description";*/
  z-index: -1;
  left: 2px;
  cursor: text;
}

/*.tryOutDescription > div:empty:focus::before,*/
/*.tryOutDescription > div:empty:hover::before {*/
/*}*/

.redocTimeline {
  z-index: 9999;
  background: #f5f5f5;
  border-radius: 6px;
  margin-left: 1px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -webkit-animation: openHistory 1s ease;
  animation: openHistory 1s ease;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.redocTimeline .box-head__title {
  color: white;
}

.redocTimeline__sidebar {
  min-width: 240px;
}

.redocTimeline__close {
  background: none;
  border: none;
  display: flex;
}

.redocTimeline__close span {
  font-size: 18px;
  color: white;
}

.redocTimeline__sidebar-menuitem {
  color: white;
  font-size: 0.875rem;
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 1rem;
  /*margin-bottom: -1px;*/
  box-sizing: border-box;
  border-bottom: 1px solid transparent;
  min-width: 300px;
  margin: 8px;
}

.redocTimeline__sidebar-menuitem:hover {
  border-color: #015978;
}

.redocTimeline__history {
  color: black;
  padding: 0;
}

.redocTimeline__sidebar-container {
  max-width: 600px;
}

.redocTimeline__sidebar-container--open {
  background: #1c869d;
}

.redocTimeline__sidebar-container--open .redocTimeline__sidebar-menuitem,
.redocTimeline__sidebar-container--open
  .redocTimeline__sidebar-menuitem
  .tryout-form__top
  .path {
  color: white;
}

.redocTimeline__sidebar-menuitem:hover {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  box-shadow: none;
  border-bottom: 1px solid #015978;
}

.redocTimeline__history form:first-child .tryout-form-body textarea {
  min-height: 0;
  min-height: initial;
}

.redocTimeline__sidebar-menuitem .tag {
  margin-left: 10px;
  flex: 0 1 auto;
}

.redocTimeline__item-ago {
  flex: 0 0 auto;
  margin-right: 24px;
  margin-left: 16px;
  width: 100px;
}

.redocTimeline__sidebar-menuitem .btn {
  margin-right: 0.5rem;
}

.redocTimeline__sidebar-container .tryoutButton--favoriteButton {
  margin-left: 0;
  padding-left: 0;
}

.redocTimeline__sidebar-menuitem .tryout-form__top {
  border: none;
  padding: 0;
  margin-right: auto;
  flex: 1 1 auto;
  display: inline;
  display: initial;
  overflow: hidden;
}

.redocTimeline__sidebar-menuitem .tryout-form__top:hover {
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: initial;
}

.redocTimeline__sidebar-container--open
  .redocTimeline__sidebar-menuitem
  .tryout-form__top {
  display: flex;
  text-overflow: initial;
  word-break: break-word;
  white-space: normal;
  white-space: initial;
}

.redocTimeline__sidebar-container--open
  .redocTimeline__sidebar-menuitem
  .tryout-form__top
  .method {
  line-height: normal;
  line-height: initial;
}

.redocTimeline__sidebar-menuitem .tryout-form__top .path {
  color: white;
}

.redocTimeline__fab {
  background: #00bcd4;
}

.redocTimeline__fab svg {
  width: 16px;
  height: 16px;
  fill: white;
}

.deleteAllHistory {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.deleteAllHistory .btn span {
  color: white;
}

.buttonDelete {
  outline: none;

  display: inline-block;
  cursor: pointer;
  font-weight: 400;

  background: none;
  padding: 0.5rem 1rem;
  display: inline-flex;
  margin-right: 10px;
  align-items: center;
  line-height: 1;
  box-sizing: border-box;
  /*color: #024a6c;*/
  background: transparent;
  -webkit-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
  font-size: 0.8rem;
  border: 1px solid transparent;
}

.buttonDelete:hover {
  background-color: #ac3931;
  color: white;
  border: 1px solid #ac3931;
}

@media screen and (max-width: 900px) {
  .redocTimeline,
  .redocTimeline__fab {
    display: none;
  }
}

@-webkit-keyframes openHistory {
  from {
    right: 0;
    left: 0;
  }
  to {
    left: 100%;
    right: -100%;
  }
}

@keyframes openHistory {
  from {
    right: 0;
    left: 0;
  }
  to {
    left: 100%;
    right: -100%;
  }
}

.box-head {
  position: relative;
  margin-bottom: 10px;
  align-items: center;
  display: flex;
  color: #404040;
}

.box-head__left {
  display: flex;
  align-items: center;
}

.box-head__left .tag {
  margin-top: 0;
}

.box-head__right {
  margin-left: auto;
}

.box-head__action > * {
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  color: #006e80;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.box-head__action > *:not(:first-child) {
  margin-left: 20px;
}

.box-head__action svg {
  width: 1em;
  margin-right: 6px;
}

.box-head__title {
  color: inherit;
  margin-right: 10px;
  display: inline-block;
  font-size: 40px;
}

@media screen and (max-width: 1270px) {
  .box-head__action > * {
    display: block;
    text-align: center;
  }

  .box-head__action > *:not(:first-child) {
    margin-left: 0;
    margin-top: 10px;
  }
}

/*--------------------------------*/

.box-head--action-left .box-head__right {
  margin-left: 0;
  margin-left: initial;
}

/*--------------------------------*/

.box-head--primary {
  padding: 10px 10px 10px 30px;
  color: black;
}

.box-head--primary .box-head__title {
}

@media screen and (max-width: 1270px) {
  .box-head--primary {
    padding: 0 8px;
    color: black;
  }
}

/*--------------------------------*/

.box-head--secondary {
  padding: 15px 20px;
  background-color: rgba(155, 155, 155, 0.1);
  box-sizing: border-box;
  border-bottom: 1px solid #b1bacc;
}

.box-head--secondary .box-head__title {
  color: inherit;
  font-size: 1.2em;
  font-weight: 700;
}

/*--------------------------------*/

.box-head--tertiary {
  border: 1px solid #b1bacc;
  margin: 40px 16px;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: rgba(155, 155, 155, 0.05);
}

.box-head--tertiary .box-head__title {
  font-size: 1.1em;
  font-weight: 400;
}

/*--------------------------------*/

.box-head--center {
  min-height: 40px;
}

.box-head--center .box-head__title {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/*--------------------------------*/

.box-head--bold .box-head__title {
  font-family: "Oswald";
  font-weight: 600;
  letter-spacing: -1px;
  margin: 0px 0px 10px 0px;
}

.tryoutButton {
  padding: 6px;
  flex: 0 1;
  display: flex;
  border: none;
  justify-content: center;
  align-items: center;
}

.tryoutButton svg {
  color: inherit;
  fill: black;
  width: 16px !important;
  height: 16px !important;
}

.tryoutButton--edit {
  /*font-size: 16px!important;*/
  background: black;
}
.tryoutButton--edit svg path {
  fill: white;
}

.tryoutButton--eyeButton {
  background: #f5f5f5;
}

.tryoutButton--resendButton {
  background: #ffdb7a;
}

.tryoutButton--deleteButton {
  background: #ac3931;
}

.tryoutButton--deleteButton svg {
  fill: white;
}

.tryoutButton--favoriteButton {
  background: transparent;
  box-shadow: none;
}

.tryoutButton--favoriteButton svg {
  fill: #ffdb7a;
}

.selectCList {
}

.selectCList__buttons {
  margin-top: 1rem;
  display: flex;
  margin-bottom: 15px;
}

.selectCList__buttons button {
  background: none;
  border: none;
  padding: 0;
  margin-right: 1rem;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.selectCList__buttons div:nth-child(2) button {
  margin-left: 60px;
}

.selectCList__buttons button:hover {
  /*color: #00bcd4;*/
}

.selectCList__btn-all {
}

.selectCList__actions {
  padding-top: 1rem;
  /*border-top: 1px solid #015978;*/
  display: flex;
}

.selectCList__list {
  max-height: 450px;
  overflow: auto;
}

.selectCList__list-item {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  /*border-bottom: 1px solid lightgray;*/
}

.selectCList__list-item--selected {
  background: #1c869d !important;
}

.selectCList__checkbox {
  margin: 0 1rem;
  cursor: pointer;
}

.selectCList__item {
  flex: 1 1;
}

.fileImportExport__fileItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1rem;
  padding: 0.6rem 0;
}

.fileImportExport__fileItem .tryout-form__top {
  border: none;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  width: 300px;
  text-overflow: ellipsis;
}

.fileImportExport__head {
  text-align: right;
  margin-bottom: 1rem;
}

.fileImportExport__button {
  padding: 0.5rem 1rem;
  display: inline-flex;
  margin-right: 10px;
  align-items: center;
  line-height: 1;
  box-sizing: border-box;
  color: white;
  background: transparent;
  /*border: 2px solid #024a6c;*/
  border: 1px solid white;
  -webkit-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
  font-size: 0.8rem;
}

.fileImportExport__button:hover {
  color: white;
  background: #024a6c;
  border-color: #024a6c;
}

.fileImportExport__button--disabled {
  -webkit-transition: none;
  transition: none;
}

.fileImportExport__button--disabled:hover {
  cursor: not-allowed;
  -webkit-transform: none;
  transform: none;
  box-shadow: none;
}

.fileImportExport__button--hidden {
  display: none;
}

.fileImportExport__button:active {
  -webkit-transform: translateY(1px);
  transform: translateY(1px);
}

.fileImportExport__button svg {
  width: 1rem;
  height: 1rem;
  margin-right: 10px;
  fill: currentColor;
}

.download__modal .modal__title {
  font-size: medium;
  font-size: initial;
  font-weight: normal;
  font-weight: initial;
  color: #000;
  color: initial;
}

.download__modal .modal__content {
  max-height: 80vh;
  overflow-y: auto;
}

.fileImportExport__fileItem .tryOutDescription {
  box-sizing: border-box;
  text-align: left;
  margin-left: auto;
  margin-right: 1rem;
  color: white;
  flex: 1 1 auto;
  max-width: 100%;
}

.text-field {
  width: 80%;
  height: 65px;
  font-size: 12px;
  padding: 15px 40px 15px 20px;
  box-sizing: border-box;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background: rgba(255, 255, 255, 0.13);
}

.slack_button {
  display: flex;
  position: absolute;
  width: 33%;
  margin-top: 10px;
  margin-bottom: 16px;
  font-size: 14px;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  align-items: center;
}

.response-request {
  display: block;
  margin-top: 7px;
  margin-bottom: 7px;
}

.slack-logo > g:nth-child(1) {
  fill: #e01e5a !important;
}

.slack-logo > g:nth-child(2) {
  fill: #36c5f0 !important;
}

.slack-logo > g:nth-child(3) {
  fill: #2eb67d !important;
}

.slack-logo > g:nth-child(4) {
  fill: #ecb22e !important;
}

.slack-logo path {
  fill: inherit;
}

@media screen and (max-width: 575px) {
  div[role="search"] {
    visibility: hidden;
    padding: 0;
  }
  div[role="search"] > div {
    display: none;
  }
}

#redoc ul[role="navigation"] {
  background: #005782;
}
#redoc ul[role="navigation"] + div {
  margin: 0;
  opacity: 1;
  background: #005782;
}

.api-content table thead tr,
.api-content table tbody tr {
  background: transparent;
}

.api-content table {
  margin: 0 0 40px 0;
  padding: 0;
  width: 100%;
  display: table;
  position: relative;
  border: none;
  display: table !important;
}

.api-content table div {
  background: transparent !important;
}

.api-content table thead {
  font-weight: 900;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.13);
  -webkit-font-smoothing: antialiased;
}

.api-content table thead tr {
  border: none;
}

.api-content table thead tr th:not([class]) {
  border: none;
  padding: 20px 12px 20px 9px;
  text-transform: uppercase;
  font-weight: 500;
}

.api-content table thead:after {
  content: "";
  position: absolute;
  border-left: 20px solid transparent;
  border-top: 20px solid #ffffff40;
  width: 0px;
  height: 0px;
  right: 0px;
  top: 0px;
}

.api-content table tbody {
  position: relative;
}

.api-content table tbody tr:not([class]) {
  background: transparent;
  border: none;
  padding-top: 5px;
  border-bottom: 1px solid #ffffff40;
}

.api-content table tbody tr:not([class]) {
  position: relative;
}

.api-content table tbody tr td:not([class]) {
  border: none;
  padding: 4px 10px 4px 9px;
  background: #ffffff0a;
  color: rgba(255, 255, 255, 0.7);
}

.api-content table tbody tr td[kind*="field"] div {
  color: #fff77d;
  font-size: 0.8em;
}

.api-content table tbody tr td[kind*="field"] {
  color: white;
}

.api-content table tbody tr td:nth-child(2) div div {
  color: white;
}

.api-content table tbody tr td:nth-child(2) div .Dropdown-root {
  display: block !important;
}

.api-content table tbody tr td:nth-child(2) div .Dropdown-control {
  border: none;
}

.api-content table tbody tr td:nth-child(2) div .Dropdown-menu {
  background: white !important;
}

.api-content
  table
  tbody
  tr
  td:nth-child(2)
  div
  .Dropdown-menu
  .Dropdown-option {
  color: #263238;
  word-break: break-all;
}

.api-content
  table
  tbody
  tr
  td:nth-child(2)
  div
  .Dropdown-menu
  .Dropdown-option:hover {
  background-color: rgba(38, 50, 56, 0.12) !important;
}

.api-content
  table
  tbody
  tr
  td:nth-child(2)
  div
  .Dropdown-menu
  .Dropdown-option.is-selected {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.api-content table tbody tr td code {
  color: #fff77d;
}

.api-content table th:not([class]) {
  padding: 0;
  border: none;
  font-weight: normal;
  font-weight: initial;
  background-color: #ffffff0a;
}

.api-content table tr:not([class]) {
  border-top: none;
}

.api-content table td:not([class]) {
  padding: 0;
  border: none;
}

.api-content table tbody tr[class*="last expanded"] + tr td div {
  background-color: transparent;
}

a::before {
  background-image: url("/static/media/link icon.d23c05d1.svg") !important;
}

.api-content
  div[data-section-id]
  div
  div:nth-child(1)
  div
  h3
  + div
  div
  svg
  polygon {
  fill: #81c14b;
}

.api-content table tbody tr td[kind*="field"] svg polygon {
  fill: white !important;
}

.api-content h1,
.api-content h2 {
  font-family: "Oswald";
  font-weight: 600;
  letter-spacing: -1px;
  margin: 0px 0px 10px 0px;
}

.api-content h3 {
  font-weight: 500;
}

.api-content h1,
.api-content h2,
.api-content h3,
.api-content h4,
.api-content h5,
.api-content h6 {
  color: white;
}

.menu-content {
  border-right: 1px solid rgba(255, 255, 255, 0.22);
}

.menu-content li[data-item-id] label.active,
.menu-content li[data-item-id] label:hover {
  background: #024a6c;
  color: white;
}

.api-content div[data-section-id] div:after {
  border-bottom: 1px solid rgba(255, 255, 255, 0.22);
}

.api-content div[data-section-id]:last-child div:after {
  border-bottom: none;
}

.api-content .property.token.string {
  color: white;
}

span.token.string + span.token.string {
  color: #ffdb7a !important;
}

.api-content .token.boolean {
  color: #81c14b;
}

.api-content .token.number {
  color: #81c14b;
}

.api-content
  div[data-section-id]
  div
  div:nth-child(2)
  div[class^="simple_btn"]
  button
  + div {
  background-color: #024a6c;
}

.api-content
  div[data-section-id]
  div
  div:nth-child(2)
  div[class^="simple_btn"]
  div
  div {
  background-color: #024a6c;
  color: white;
}

.api-content
  div[data-section-id]
  div
  div:nth-child(2)
  div[class^="simple_btn"]
  span:not([class]) {
  color: white;
}

.react-tabs__tab-panel {
  background: black !important;
}

.react-tabs__tab {
  border: none !important;
  border-radius: 0 !important;
  font-weight: normal !important;
  font-weight: initial !important;
  background: rgba(255, 255, 255, 0.13) !important;
  color: white !important;
}

li[role="tab"] {
  border-radius: 0 !important;
  border: none !important;
}

.react-tabs__tab--selected {
  border: none !important;
  border-radius: 0 !important;
  font-weight: normal !important;
  font-weight: initial !important;
  background: rgba(255, 255, 255, 0.13) !important;
  color: white !important;
  outline-offset: 3px !important;
  outline: 1px solid rgba(255, 255, 255, 0.25) !important;
  background: #024a6c !important;
}

.react-tabs__tab-list {
  margin-bottom: 0.3rem !important;
}

.api-content .Dropdown-root .Dropdown-control .Dropdown-placeholder {
  color: #fff77d;
}

.search-input {
  font-weight: normal;
}

div[data-role="search:results"] {
  background-color: transparent;
}

.api-content div[data-section-id] div div:nth-child(1) div h3 + div {
  border-radius: unset;
  color: white;
}

.api-content
  div[data-section-id]
  div
  div:nth-child(2)
  div[class^="simple_btn"]
  div
  button {
  background-color: #024a6c;
  color: #fff;
}

.api-content h5 {
  border-bottom: none !important;
}

.api-content h5 span {
  color: white;
}

.operation-type {
  border-radius: 0 !important;
}

.redoc-markdown pre {
  background: rgba(255, 255, 255, 0.13) !important;
}

li.tab-error,
li.tab-error.react-tabs__tab--selected {
  color: #fb3640 !important;
  background-color: rgba(41, 49, 50, 0.7) !important;
  border-radius: 0 !important;
}

@media screen and (max-width: 767px) {
  .api-content table {
    display: block;
    overflow: auto;
  }

  .api-content table thead:after {
    display: none;
  }
}
.tab-success {
  color: #81c14b !important;
  background: white !important;
}

@media (max-width: 50rem) {
  .menu-content {
    background: #024a6c;
    z-index: 100000000001;
    left: 0;
  }

  .menu-content + div {
    background: #1c869d;
    border-radius: 0;
    z-index: 1001;
    width: 30px;
    height: 30px;
    padding: 4px;
  }

  .menu-content + div div svg {
    width: 10px;
  }

  .menu-content + div div svg:nth-child(2) {
    width: 10px;
  }

  .menu-content + div div {
    margin-left: 4px;
  }
}
.auth-icon {
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 4px;
}

.expiration-token-info {
  position: absolute;
  bottom: -20px;
  width: 100%;
  font-size: 10px;
  -webkit-transform: translatex(-50%);
          transform: translatex(-50%);
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
}

/*# sourceMappingURL=redoc.css.map */

.start_steps {
  float: right;
  padding: 10px;
}

.start_step {
  float: left;
  position: relative;
  width: 31%;
  min-width: 288px;
  padding-bottom: 61%;
  border: 1px solid #5090ad;
  overflow: hidden;
}

.lines_bg {
  background-image: linear-gradient(
    135deg,
    #ffffff00 44.44%,
    #5090ad36 44.44%,
    #5090ad36 50%,
    #ffffff00 50%,
    #ffffff00 94.44%,
    #5090ad36 94.44%,
    #5090ad36 100%
  );
  background-size: 25.46px 25.46px;
}

.start_content {
  position: absolute;
  height: 90%;
  width: 90%;
  padding: 5%;
}

.start_table {
  display: table;
  width: 100%;
  height: 100%;
}

.start_table-cell {
  display: table-cell;
  vertical-align: middle;
}

.start_num {
  font-family: "Oswald";
  font-weight: 500;
  font-size: 160px;
  text-align: right;
  line-height: 1.2em;
}

.start_title h3 {
  text-align: right;
  text-transform: uppercase;
  font-family: "Oswald";
  font-weight: 500;
  font-size: 24px;
  /* margin: 8px; */
}

.start_descr {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  text-align: right;
  line-height: 1.5em;
}

.start_descr span p {
  height: 91px;
}

.fill_white_bg {
  background-color: #ffffff12;
}

.graphic-circuit-wrapper {
  width: 272px;
  position: absolute;
  top: -160px;
  left: -200px;
}

@media screen and (max-width: 1400px) {
  .start_step {
    min-width: 249px;
  }
}

@media screen and (max-width: 1199px) {
  .start_step {
    width: calc(50% - 2px);
  }
}

@media screen and (max-width: 991px) {
  .graphic-circuit-wrapper {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .start_step {
    padding-bottom: 120%;
    margin: 0px 0;
    width: 100%;
  }
}

@media screen and (max-width: 575px) {
  .start_title h3 {
    font-size: 36px;
    margin: 8px;
  }
}

@media screen and (max-width: 374px) {
  .start_title h3 {
    font-size: 24px;
    margin: 10px;
  }

  .start_num {
    font-size: 120px;
  }
}

.news {
  margin: 10px 0;
}

.filtersButton-wrapper {
  position: relative;
  float: left;
  margin: 6px 0;
  width: 100%;
}

.article a {
  color: #fff77d !important;
}

.theFiltersBtn {
  position: relative;
  background: rgba(255, 255, 255, 0);
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  font-family: "Oswald", serif;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  margin: 0;
  outline: 0;
  padding: 20px 60px 20px 0;
  width: 100%;
  text-align: left;
}

.filterBlogBorder {
  position: relative;
  background: rgba(255, 255, 255, 0);
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  font-family: "Oswald", serif;
  font-weight: 500;
  color: #ffffff;
  outline: 0;
  width: 100%;
  margin-top: 25px;
}

@media screen and (max-width: 991px) {
  #custom_mobile_position_link_2 {
    text-align: right;
    position: relative;
  }
}

@media screen and (max-width: 767px) {
  #filter_btn {
    display: block;
  }
}

@media screen and (max-width: 480px) {
  #custom_mobile_position_link_2 {
    text-align: right;
  }
}

.news.nLeft {
  width: 25%;
}

.aName {
  /* float: right; */
  width: 100%;
  /* display: inline-block; */
  text-align: right;
  margin: 4px 0;
  font-size: 13px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
}

.aDate {
  /* float: right; */
  width: 100%;
  /* display: inline-block; */
  text-align: right;
  /* margin: 8px; */
  font-size: 10px;
  color: #fff77d;
  height: 4px;
}

.news.nRight {
  width: 75%;
}

.post.thumbnail {
  width: 92px;
  float: left;
  display: inline-block;
}

.thump_box {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.thump_box .thump_content {
  margin: 0px;
  padding: 4px;
  border: 2px solid #ffffff30;
  font-size: 14px;
  line-height: 1.3;
}

.thumb_post {
  width: 80px;
}
.thumb_post img {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.thump_box .thump_corners.top {
  top: 0px;
}

.thump_box .thump_corners {
  position: absolute;
  width: 100%;
  /* transition-duration: 0.3s; */
}

.thump_box .thump_corners.top:before {
  border-width: 2px 0 0 2px;
}

.thump_box .thump_corners.bottom {
  bottom: 8px;
}

.thump_box .thump_corners:before,
.thump_box .thump_corners:after {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  border-color: rgba(255, 255, 255, 0.7);
  border-style: solid;
}

.thump_box .thump_corners.top:after {
  border-width: 2px 2px 0 0;
}

.thump_box .thump_corners:after {
  right: 0px;
}

.thump_box .thump_corners:before {
  left: 0px;
}

.thump_box .thump_corners.bottom:before {
  border-width: 0 0 2px 2px;
}

.thump_box .thump_corners.bottom:after {
  border-width: 0 2px 2px 0;
}

.post.info {
  width: calc(100% - 92px);
  float: left;
  display: inline-block;
}

.pTitle {
  margin: 8px;
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
}

.pCategory {
  margin-left: 8px;
}

.pDescription {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  margin: 8px;
}

/*# sourceMappingURL=newSummary.css.map */

.author.thumbnail {
  /* width: 100%; */ /* display: inline-block; */
  float: right;
  margin: 2px 0;
  padding: 0 8px;
  width: calc(100% - 16px);
}
.thumb_auth {
  float: right;
  padding: 2px;
  border: 1px solid rgba(255, 255, 255, 0.7);
}
.author.info {
  /* width: 100%; */
  float: right; /* margin: 4px 8px; */
  margin: 2px 0;
  padding: 0 8px;
  width: calc(100% - 16px);
}
.aName {
  /* float: right; */
  width: 100%; /* display: inline-block; */
  text-align: right;
  margin: 4px 0;
  font-size: 13px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
}
.aDate {
  /* float: right; */
  width: 100%; /* display: inline-block; */
  text-align: right; /* margin: 8px; */
  font-size: 10px;
  color: #fff77d;
}

.thumb_auth img {
  min-width: 28px;
  min-height: 28px;
  max-width: 28px;
  max-height: 28px;
}

.chip {
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.87);
  height: 32px;
  cursor: default;
  border: none;
  display: inline-flex;
  outline: none;
  font-size: 0.8125rem;
  -webkit-transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    -webkit-box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -webkit-transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-sizing: border-box;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  align-items: center;
  white-space: nowrap;
  border-radius: 16px;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  background-color: #ffdb7a;
  color: #006e80;
  padding: 4px;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.chip__filter {
  cursor: inherit;
  display: flex;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
}

.chip__close {
  background-color: rgba(255, 255, 255, 0.7);
  color: inherit;
  font-size: 1rem;
  padding: 4px;

  height: 24px;
  width: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 50%;
}

.faq_category {
  font-family: "Oswald";
  font-weight: 500;
  font-size: 15px;
  line-height: 1;
  position: relative;
  width: 100%;
  display: inline-block;
  margin-bottom: 1rem;
}

.panel-wrapper.open .accordion::after {
  /*animation: half-spin .2s 1 linear;*/
  -webkit-transform: rotate(315deg);
  transform: rotate(315deg);
}

.accordion {
  background-color: rgba(255, 255, 255, 0.13);
  color: #ffffff;
  cursor: pointer;
  padding: 18px 50px 18px 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  position: relative;
  margin-bottom: 14px;
  font-family: "CF Asty Std";
  font-weight: 500;
}

.accordion:after {
  /* content: '\002B'; */
  color: #ced9de;
  /* font-weight: bold; */
  /* float: right; */
  /* margin-left: 5px; */
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-right: 2px solid currentColor;
  border-top: 2px solid currentColor;
  /* border-bottom: 2px solid currentColor; */
  /* border-left: 2px solid currentColor; */
  top: 0px;
  right: 0;
  top: calc(50% - 2px);
  right: 20px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  /* transform-origin: 50% 25%; */
  -webkit-transition: -webkit-transform 150ms;
  transition: -webkit-transform 150ms;
  transition: transform 150ms;
  transition: transform 150ms, -webkit-transform 150ms;
}

.panel {
  padding: 0 18px;
  color: rgba(255, 255, 255, 0.7);
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.2s ease-out;
  transition: max-height 0.2s ease-out;
  font-family: "CF Asty Std";
  font-weight: 400;
}

.faq h1,
.faq h2 {
  color: white;
  font-size: 30px;
}
.custom_table {
  box-sizing: border-box;
  width: 100% !important;
  border-radius: 10px;
  padding-bottom: 20px;
  border: 1px solid rgba(255, 255, 255, 0.7);
  overflow: hidden;
  background: #000000 !important;
  color: #ddd;
  font-family: "DejaVu Sans Mono", monospace, sans-serif;
  font-size: inherit;
  line-height: 20px;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  pointer-events: none;
}

.custom_table td {
  border: solid rgba(255, 255, 255, 0.7);
  border-width: 0 0 1px 0;
  padding: 0.5em;
  vertical-align: top;
}

.faq table {
  table-layout: fixed;
  border: solid rgba(255, 255, 255, 0.7);
  border-collapse: collapse;
  border-width: 1px 1px 0 1px;
  margin: 1em 0;
  width: 100%;
}

.faq th,
.faq td {
  border: solid #e4e2e0;
  border-width: 0 0 1px 0;
  padding: 0.5em;
  vertical-align: top;
}

.faq a {
  color: #fff77d;
}

.faq :not(img) {
  box-sizing: border-box;
  overflow-wrap: break-word;
  word-wrap: break-word;
  max-width: 100% !important;
  width: 100%;
  overflow-y: auto;
  _overflow: auto;
  margin: 0 0 1em;
}

pre {
  white-space: normal;
  white-space: initial;
}

td[width] {
  width: auto !important;
  width: initial !important;
}

/*.block__started_container {*/
/*    counter-reset: steps;*/
/*}*/
/*.block__elem_start_quote {*/
/*    padding: 10px 40px;*/
/*    border-radius: 20px;*/
/*    background-color: rgba(155, 155, 155, 0.1);*/
/*    display: inline-block;*/
/*    color: #9B9B9B;*/
/*    font-weight: 900;*/
/*}*/
/*.block__getting_started_header {*/
/*    color: #404040;*/
/*    padding: 10px 10px 10px 0px;*/
/*    margin-bottom: 10px;*/
/*    text-align: center*/
/*}*/
.timeline {
  color: #fff;
}

.dots_section {
  width: 100%;
  height: 160px;
  left: 0;
  top: 400px;
  overflow-x: hidden;
  position: absolute;
}

.dots_bg {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAQCAYAAAAmlE46AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFMzRFRkEyRUFFMTFFOTk5OUY5RkI3NEJBMjg0MkEiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFMzRFRjkyRUFFMTFFOTk5OUY5RkI3NEJBMjg0MkEiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpGOEM0OTM3RDI2RDYxMUU5QTgzMEZGNjMyNzgyNTQ1MCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpGOEM0OTM3RTI2RDYxMUU5QTgzMEZGNjMyNzgyNTQ1MCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PqbhQtEAAAAlSURBVHjaYvz//z8DOYCJgUwwqpFMjXpQTF0bGUcTwGDSCBBgACs0BnVccRmNAAAAAElFTkSuQmCC) repeat;
}

.timeline .dots_section {
  width: calc(100% + 100px);
  left: -50px;
}

.lines_section {
  width: 400px;
  height: 400px;
  left: 200px;
  top: 1000px;
  overflow-x: hidden;
  position: absolute;
  border: 2px solid #ffffff0f;
}

.timeline ul {
  /* background: #faf8eb; */
  padding: 50px 0;
}

.timeline ul li {
  background: #4e8ba1;
  position: relative;
  margin: 0 auto;
  width: 1px;
  padding-bottom: 40px;
  list-style-type: none;
  height: 250px;
}

.timeline ul li:before {
  content: "";
  /* background: #ffffffc9; */
  /* background: #083ad0; */
  background: -webkit-gradient(linear, left top, right bottom, color-stop(50%, #0b698e), color-stop(50%, #24789a));
  background: linear-gradient(to right bottom, #0b698e 50%, #24789a 50%);
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  width: 50px;
  height: 50px;
  border: 1px solid #4e8ba1;
  /* -webkit-border-radius: 50%; */
  -moz-border-radius: 50%;
  /* border-radius: 50%; */
  /* outline: 4px solid #fff77d; */
}

.timeline .number {
  position: absolute;
  left: -8px;
  top: -2px;
  font-family: "Oswald";
  font-weight: 600;
  font-size: 34px;
}

.timeline ul li:first-child .number {
  left: -6px;
}

.timeline ul li:nth-child(odd) .content {
  left: 70px;
  /* background: #67CC8E; */
  /* background: -webkit-linear-gradient(-45deg, #56BC83, #67CC8E); */
  /* background: linear-gradient(-45deg, #56BC83, #67CC8E); */
}

.timeline ul li .content,
.timeline ul li .getting_started_item {
  /* background: #ff8d13; */
  position: relative;
  top: 0px;
  width: calc(370px - 40px);
  padding: 0 20px;
}

.timeline ul li .getting_started_item {
  left: 70px;
}

.timeline ul li .getting_started_item::before {
  left: -20px;
}

.timeline ul li .content h3,
.timeline ul li .getting_started_item h3 {
  color: #fff;
  padding-bottom: 10px;
  padding-top: 5px;
  text-align: left;
  font-size: 24px;
  font-family: "Oswald";
  font-weight: 500;
  margin: 0;
}

.timeline ul li:nth-child(even) .content {
  left: calc(-370px - 70px);
}

.timeline ul li:after {
  display: block;
  content: "";
  width: 12px;
  height: 12px;
  position: absolute;
  top: -6px;
  right: -30px;
  border-top: 6px solid #fff77d;
  border-right: 6px solid #fff77d;
}

.timeline ul li:nth-child(even) .content h3 {
  text-align: right;
}

.timeline ul li:nth-child(odd) .content:before {
  left: -20px;
}

.timeline ul li:nth-child(even) .content:before {
  right: -20px;
}

.timeline ul li:nth-child(even) .content p {
  text-align: right;
}

.timeline ul li:last-child {
  padding-bottom: 7px;
  background: none;
}

.timeline ul li .content p {
  text-align: left;
  color: rgba(255, 255, 255, 0.7);
}

.timeline ul li .content:before,
.timeline ul li .getting_started_item:before {
  content: "";
  background: rgba(255, 255, 255, 0.4);
  position: absolute;
  top: 24px;
  width: 20px;
  height: 1px;
}

@media screen and (max-width: 1399px) {
  .timeline ul li .content {
    width: calc(240px - 40px);
  }

  .timeline ul li:nth-child(even) .content {
    left: calc(-240px - 70px);
  }
}
@media screen and (max-width: 767px) {
  .lines_section {
    width: 150px;
    height: 150px;
    left: 120px;
    top: 930px;
    overflow-x: hidden;
    position: absolute;
  }

  .timeline ul li {
    margin-left: 25px;
    height: 150px;
  }

  .timeline ul li:nth-child(odd) .content {
    left: 0px;
  }

  .timeline ul li .content {
    width: calc(370px - 80px);
    padding: 0 40px;
    width: 200px;
  }

  .timeline ul li .content h3 {
    text-align: left;
    text-align: initial;
    font-size: 15px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .timeline ul li .content p {
    font-size: 13px;
  }

  .timeline ul li:nth-child(even) .content {
    left: 0px;
  }

  .timeline ul li:nth-child(even) .content h3 {
    text-align: left;
  }

  .timeline ul li:nth-child(even) .content p {
    text-align: left;
  }

  .timeline ul li:nth-child(odd) .content {
    left: 0px;
  }

  .timeline ul li:nth-child(even) .content:before {
    left: -20px;
  }
}
@media screen and (max-width: 575px) {
  .timeline .dots_section {
    width: calc(100% + 20px);
    left: -10px;
  }

  .timeline ul li {
    height: 190px;
  }

  .timeline ul li .content {
    width: calc(310px - 100px);
    padding: 0 50px;
  }
}
@media screen and (max-width: 374px) {
  .timeline ul li .content {
    width: calc(230px - 80px);
  }
}
.allDone {
  text-align: center;
  color: #fff77d;
  font-size: 80px;
  font-family: "Oswald";
  font-weight: 500;
  margin-top: 20px;
}

.allDoneDescr {
  text-align: center;
  font-size: 14px;
  margin-bottom: 50px;
}

@media screen and (max-width: 767px) {
  .allDone {
    text-align: center;
    color: #fff77d;
    font-size: 41px;
  }
}

/*# sourceMappingURL=gettingStarted.css.map */

.step {
  margin-top: 25px;
  border-top: 2px solid #ffdb7a;
  padding: 30px 0 30px 0;
  position: relative;
}

.step::after {
  counter-increment: steps;
  content: counter(steps);
  text-align: center;
  color: white;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  position: absolute;
  background-color: #006e80;
  border: 4px solid #ffdb7a;
  font-weight: 900;
  font-size: 1.5em;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.step--bullet::after {
  content: "";
  width: 10px;
  height: 10px;
}

.step h3 {
  margin-top: 2px;
  font-weight: 900;
  color: #006e80;
}

.step p {
  margin-bottom: 30px;
  color: black;
}

.step a {
  text-decoration: none;
}

.step--auth::after {
  content: "Auth";
  font-size: 1rem;
}

@media screen and (max-width: 767px) {
  .timeline ul li .content:before {
    display: none;
  }
}

.authorization {
  /* float: left; */
  position: relative;
  width: 100%;
  /* min-width: 295px; */
  /* min-height: 0; */
  /* padding-bottom: 20%; */
  margin: 10px 0;
  padding: 20px 0;
  /* background-color: #1E1E1E; */
  border-left: 1px solid rgba(255, 255, 255, 0.22);
  border-bottom: 1px solid rgba(255, 255, 255, 0.22);
  overflow: hidden;
}

.authorization:before {
  right: 20px;
  width: calc(100% - 20px);
  border-top: 1px solid rgba(255, 255, 255, 0.22);
}

.authorization:before,
.authorization:after {
  position: absolute;
  content: "";
  top: 0px;
  height: 100%;
}

.authorization h3 {
  font-family: "Oswald";
  font-weight: 600;
  padding: 0 20px;
  font-size: 22px;
}

.authorization p {
  padding: 0 20px;
  color: rgba(255, 255, 255, 0.7);
}

.authorization .simple_btn {
  padding: 0 20px;
}

.simple_btn.left {
  margin-right: 30px;
}

.simple_btn.left {
  float: left;
}

.authorization .icon-arrow_classic_right {
  color: #fff77d;
}

.authorization:after {
  right: 0px;
  top: 20px;
  width: 20px;
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom;
  -webkit-transform: skewY(45deg);
  transform: skewY(45deg);
  border-top: 1px solid rgba(255, 255, 255, 0.22);
  border-right: 1px solid rgba(255, 255, 255, 0.22);
}

@media screen and (max-width: 767px) {
  .authorization .simple_btn {
    padding: 0 20px;
    width: calc(100% - 40px);
  }

  .authorization .simple_btn.left {
    margin-right: 0;
  }
}

.st0 {
  fill: #859096;
}
.st1 {
  fill: #fff77d;
}
.st2 {
  fill: #ffffff;
}
.st3 {
  opacity: 0.1;
  fill: #ffffff;
}
.st4 {
  fill: #33b3bf;
}
.st5 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ffffff;
}
.st6 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #007580;
}
.st7 {
  fill: #231f20;
}
.st8 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #231f20;
}
.st9 {
  fill: #c49958;
}
.st10 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 0.9789;
  stroke-miterlimit: 10;
}
.st11 {
  opacity: 0.3;
}
.st12 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 1.0001;
  stroke-miterlimit: 10;
}
.st13 {
  fill: none;
  stroke: #fff77d;
  stroke-width: 1.0001;
  stroke-miterlimit: 10;
}
.st14 {
  fill: none;
  stroke: #fff77d;
  stroke-width: 0.5;
  stroke-miterlimit: 10;
}
.st15 {
  fill: #024a6c;
}
.st16 {
  fill: #1c869d;
}
.st17 {
  fill: #1b2d4c;
}
.st18 {
  opacity: 0.5;
  fill: #1b2d4c;
}
.st19 {
  opacity: 0.2;
  fill: #1b2d4c;
}
.st20 {
  fill: url(#SVGID_1_);
}
.st21 {
  fill: url(#SVGID_2_);
}
.st22 {
  fill: url(#SVGID_3_);
}
.st23 {
  opacity: 0.7;
  fill: url(#SVGID_4_);
}
.st24 {
  opacity: 0.5;
  fill: #ffffff;
}
.st25 {
  opacity: 0.5;
}
.st26 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 1.0014;
  stroke-miterlimit: 10;
}
.st27 {
  fill: none;
  stroke: #fff77d;
  stroke-width: 1.0014;
  stroke-miterlimit: 10;
}
.st28 {
  opacity: 0.15;
}
.st29 {
  -webkit-clip-path: url(#SVGID_6_);
  clip-path: url(#SVGID_6_);
}
.st30 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 0.9738;
  stroke-miterlimit: 10;
}
.st31 {
  opacity: 0.05;
  fill: #ffffff;
}
.st32 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 0.5;
  stroke-miterlimit: 10;
}
.st33 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 2;
  stroke-miterlimit: 10;
}
.st34 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #1c869d;
}
.st35 {
  fill: #c1272d;
}
.st36 {
  fill: #0b698e;
}
.st37 {
  opacity: 0.1;
}
.st38 {
  -webkit-clip-path: url(#SVGID_8_);
  clip-path: url(#SVGID_8_);
}
.st39 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 1.0005;
  stroke-miterlimit: 10;
  stroke-opacity: 0.3;
}
.st40 {
  opacity: 0.3;
  fill: #ffffff;
}

.result {
  width: calc(100% - 20px);
  padding: 0 10px;
  position: relative;
}

.sidebar.right .result .rInfo {
  display: inline-block;
  float: left;
  width: calc(100% - 20px);
  padding: 0 10px;
  margin: 6px 0;
}

.rTitle {
  font-size: 12px;
  color: #ffffff;
  font-weight: 500;
}

.sidebar.right .result .rInfo > div {
  margin: 8px 0;
}

.rDescription {
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
}

.sidebar.right .result .rInfo > div {
  margin: 8px 0;
}

.rDate {
  width: 100%;
  font-size: 10px;
  color: #fff77d;
}

.sidebar.right .result .rInfo > div {
  margin: 8px 0;
}

.searchBar,
.searchDropdown {
  float: left;
  display: inline-block;
  padding: 0 10px;
}

.searchBar {
  width: calc(70% - 20px);
}

.searchArea .sTitle {
  width: calc(100% - 20px);
  padding: 10px;
  font-size: 13px;
}

.searchArea .sTitle {
  width: calc(100% - 20px);
  padding: 10px;
  font-size: 13px;
}

input[type="text"].body_field {
  width: 100%;
  /* border: 2px solid #aaa; */
  /* border-radius: 4px; */
  font-size: 12px;
  border: none;
  /* margin: 8px 0; */
  outline: none;
  padding: 15px 40px 15px 20px;
  box-sizing: border-box;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background: rgba(255, 255, 255, 0.13);
}

.searchArea input[type="text"].body_field {
  margin: 0;
  padding: 14px 50px 14px 15px;
}
.searchArea input[type="text"].body_field:focus {
  cursor: pointer;
}

.searchArea .search_field,
.searchArea .side_field {
  width: 100%;
  margin: 0;
}

.inputWithIcon {
  position: relative;
}

.searchDropdown {
  width: calc(30% - 20px);
}

@media screen and (max-width: 991px) {
  .searchBar {
    width: calc(100% - 20px);
  }

  .searchDropdown {
    width: calc(100% - 20px);
  }
}

@media screen and (max-width: 575px) {
  .searchBar {
    width: calc(100% - 20px);
  }

  .searchDropdown {
    width: calc(100% - 20px);
  }
}

.search {
  position: absolute;
  /* position: relative; */
  /* margin-top: 96px; */
  /* left: 50%; */
  /* background: red; */
  /* -webkit-transform: translateX(-100%) translateY(-50%); */
  /* transform: translateX(-100%) translateY(-50%); */
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.search * {
  outline: none;
  box-sizing: border-box;
}

.search__wrapper {
  position: relative;
}

.search__field {
  width: 0;
  height: 0;
  color: #fff;
  background: #8bb8ca00;
  font-family: Lato, sans-serif;
  /* font-size: 1.35em !important; */
  padding: 30px 20px 30px 20px;
  /* border: 1px solid transparent; */
  /* border-radius: 0; */
  border: none;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.search__field:focus {
  border-bottom-color: #fff0;
  width: 60vw;
  color: #ffffff;
  cursor: default;
  /*   background: #0088a0; */
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1d6d94+0,318fa5+100 */
  background: #1d6d94; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(
    linear,
    left top, right top,
    from(#1d6d94),
    to(#318fa5)
  );
  background: linear-gradient(
    to right,
    #1d6d94 0%,
    #318fa5 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1d6d94', endColorstr='#318fa5',GradientType=1 ); /* IE6-9 */
}

.search__field:focus .search__icon {
  background-color: transparent;
  cursor: pointer;
  pointer-events: auto;
}

.search__field::-webkit-input-placeholder {
  position: relative;
  top: 0;
  left: 0;
  -webkit-transition-property: top, color;
  transition-property: top, color;
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
}
.search__field:-moz-placeholder {
  position: relative;
  top: 0;
  left: 0;
  -webkit-transition-property: top, color;
  -moz-transition-property: top, color;
  transition-property: top, color;
  transition-duration: 0.1s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
}
.search__field::-moz-placeholder {
  position: relative;
  top: 0;
  left: 0;
  -webkit-transition-property: top, color;
  -moz-transition-property: top, color;
  transition-property: top, color;
  transition-duration: 0.1s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
}
.search__field:-ms-input-placeholder {
  position: relative;
  top: 0;
  left: 0;
  -webkit-transition-property: top, color;
  -ms-transition-property: top, color;
  transition-property: top, color;
  transition-duration: 0.1s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
}
.search__field::-webkit-input-placeholder[style*="hidden"] {
  color: #83b0c1;
  font-size: 0.65em;
  font-weight: normal;
  top: -20px;
  opacity: 1;
  visibility: visible !important;
}
.search__field:-moz-placeholder[style*="hidden"] {
  color: #83b0c1;
  font-size: 0.65em;
  font-weight: normal;
  top: -20px;
  opacity: 1;
  visibility: visible !important;
}
.search__field::-moz-placeholder[style*="hidden"] {
  color: #83b0c1;
  font-size: 0.65em;
  font-weight: normal;
  top: -20px;
  opacity: 1;
  visibility: visible !important;
}
.search__field:-ms-input-placeholder[style*="hidden"] {
  color: #83b0c1;
  font-size: 0.65em;
  font-weight: normal;
  top: -20px;
  opacity: 1;
  visibility: visible !important;
}

.cookiePopup {
}

.cookiePopup__content {
}

.cookiePopup__body {
}

.cookiePopup__body p:first-child {
  margin-top: 0;
}

.cookiePopup__actions {
  margin-top: 16px;
}

.cookiePopup__checkbox {
  display: inline-block;
  margin-bottom: 1rem;
}

.cookiePopup__checkbox-input {
  margin-right: 1rem;
}

.cookiePopup__checkbox-label {
  margin-left: 8px;
}

.cookiePopup__actions .btn:not(:last-child) {
  margin-right: 8px;
}

.cookiePopup a:link,
.cookiePopup a:visited {
  text-decoration: underline;
  color: #fff77d;
}

.cookiePopup a:hover {
  color: white;
}

.scrollspy {
  padding-bottom: 8rem;
  /*margin-bottom: 8rem;*/
}

.scrollspy * {
  box-sizing: border-box;
  /*color: white;*/
}

.scrollspy__html {
  /*!*max-width: 85%;*!*/
  /*margin: 25px auto 65px;*/
  /*padding: 0 0 0 0;*/
  /*width: 70%;*/
}

.scrollspy__html .box {
  /*margin: 0 0 0 40px;*/
  /*width: 100%;*/
}

.scrollspy__html * {
  max-width: 100%;
  box-sizing: border-box;
}

.scrollspy__html table * {
  word-break: break-all;
}

.scrollspy__html table {
  table-layout: fixed !important;
  margin-left: 0 !important;
  width: 100%;
  display: block;
}

.scrollspy__html table td {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.scrollspy__list {
  /*-webkit-box-flex: 1;*/
  /*-ms-flex: 1 0 15%;*/
  /*flex: 1 0 15%;*/
  /*position: fixed;*/
  /*-ms-flex-item-align: start;*/
  /*align-self: flex-start;*/
  /*margin-top: 25px;*/
  padding: 0;
  /*position: ;*/
}

.goTop {
  top: 0;
}

.scrollspy__list-element--active {
  display: block;
  border-left: 3px solid #006e80;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.26);
  background: #006e80;
  color: #fff;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .scrollspy__list {
    /*top: 3rem;*/
    position: sticky;
    top: 0;
    position: -webkit-sticky;
  }
}

.scrollspy__list-element {
  display: none;
  margin-bottom: 8px;
  cursor: pointer;
  padding: 8px;
}

.scrollspy__list-element--h1 {
  font-weight: 900;
  font-size: 1.1rem;
}

.scrollspy__list-element--h2 {
  font-weight: 700;
  font-size: 0.9rem;
  margin-left: 4px;
}

.scrollspy__list-element--h3 {
  font-weight: 400;
  font-size: 0.8rem;
  margin-left: 6px;
}

.scrollspy__list-element--inactive {
  opacity: 1;
  display: block;
  color: white;
}

.scrollspy__list-element--active {
  /*padding: 10px 60px;*/
  padding-top: 10px;
  padding-bottom: 10px;
  background: #024a6c;
  border: none;
  outline-offset: 3px;
  outline: 1px solid rgba(255, 255, 255, 0.25);
  margin: 4px;
  display: block;
  /*font-family: 'CF Asty Std';*/
  /*font-weight: 500;*/
  /*font-size: 14px;*/
  color: #fff;
  cursor: pointer;
  position: relative;
}

@media screen and (max-width: 1250px) {
  .scrollspy__above,
  .scrollspy__list {
    display: none;
  }

  .scrollspy__html {
    width: 100%;
  }

  .scrollspy__html .box {
    margin: 0 0 0 0;
  }
}

.article--google {
  background: transparent;
}
.article--google p:not(.subtitle):not(.title) {
  background-color: transparent !important;
  margin-bottom: 12px !important;
}
.article--google p:not(.subtitle):not(.title) span {
  background: transparent;
  color: #ffffff !important;
  font-size: 13px !important;
  font-weight: 100 !important;
  color: var(--light-title-color) !important;
  font-family: "CF Asty Std" !important;
}
.article--google .subtitle {
  background-color: transparent !important;
  margin-bottom: 12px !important;
}
.article--google .subtitle span {
  color: var(--light-title-color) !important;
}
.article--google .title {
  background-color: transparent !important;
  margin-bottom: 12px !important;
}
.article--google .title span {
  color: var(--light-title-color) !important;
}

/*# sourceMappingURL=googleDrive.css.map */

@media print {
  .sidebar.left {
    display: none;
  }

  header {
    display: none;
  }

  .sidebar.right {
    width: 100%;
  }

  p,
ol,
h1,
h2 {
    color: black;
  }
}

/*# sourceMappingURL=basicPage.css.map */

.article {
  font-optical-sizing: auto;
  font-variation-settings: "wdth" 100;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  font-family: "Inconsolata", monospace;
  max-width: 750px;
  margin: auto;
  color: rgba(255, 255, 255, 0.7);
}

.article h1, h2, h3, h4, h5, h6 {
  font-family: "Inconsolata", monospace;
}

.scrollspy__html table *{
  word-break: keep-all;
}

.scrollspy__html table td{
  vertical-align: top;
}

.scrollspy__list {
  font-family: "Inconsolata", monospace;
}

@media screen and (max-width: 767px) {
  .article {
    max-width: 500px;
    width: 100%;
  }
}
.article ul {
  list-style-image: none;
  margin: 0.25em 0 0.25em 1.5em;
}
.article ul li {
  list-style: disc;
}
.article--google * {
  line-height: 1.35;
}
.article--google ul {
  list-style: none !important;
}
.article--google ul li {
  list-style: none !important;
}
.article--google ul li:not(:first-child):not(:last-child) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.article--google ul li:nth-last-child(2) + li {
  padding-top: 0 !important;
}
.article--google ul li:first-child:not(:only-child) {
  padding-bottom: 0 !important;
}

/*# sourceMappingURL=article.css.map */

/*.support_page__started_container {*/
/*    -webkit-box-sizing: border-box;*/
/*            box-sizing: border-box;*/
/*    color: black;*/
/*    text-align: left;*/
/*    margin-left: 40px;*/
/*    margin-top: 20px;*/
/*    position: relative;*/
/*    z-index: 50;*/
/*    border-radius: 10px;*/
/*    -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.26);*/
/*            box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.26);*/
/*    !*overflow: hidden;*!*/
/*    margin: 25px auto;*/
/*    margin-bottom: 65px;*/
/*    padding: 10px 40px 40px 40px;*/
/*    top: -15px;*/
/*    z-index: 299;*/
/*    background-color: rgba(255, 255, 255, 0.7);*/
/*    width: 80%;*/
/*}*/

/*!* FIX position sticky issue *!*/
/*.support_page--visible-overflow,*/
/*.support_page--visible-overflow *{*/
/*    overflow: visible!important;*/
/*    }*/

/*.support_page__started_container h3 {*/
/*    font-weight: 900;*/
/*    color: #006E80;*/
/*    font-size: 1.4em;*/
/*}*/

.double-box {
  width: 600px;
  /* float: right; */
  /* width: calc(50% - 62px); */
  border: 1px solid rgba(255, 255, 255, 0.13);
  display: table-cell;
  vertical-align: top;
  padding: 30px;
  width: 33%;
}

.double-box h4 {
  font-family: "Oswald";
  font-weight: 500;
  font-size: 50px;
  line-height: 50px;
  margin: 0px 0px;
  /* float: right; */
  width: 100%;
  text-align: right;
}

.double-box p {
  float: right;
  width: 100%;
  text-align: right;
  line-height: 28px;
  /* padding: 20px 0; */
}

.double-box-button-wrapper {
  float: right;
  width: 100%;
  text-align: right;
}

.opacity-white_bg {
  background-color: rgba(255, 255, 255, 0.13);
  padding: 31px 30px;
}

.opacity-white_bg p {
  /*margin-top: 4.2rem;*/
}

@media screen and (max-width: 991px) {
  .double-box {
    display: inline-block;
    width: calc(100% - 60px);
    float: left;
  }

  .double-box a.movingBrackets.right {
    text-align: right;
  }
}

@media screen and (max-width: 767px) {
  .double-box h4 {
    font-size: 25px;
    line-height: 1.2em;
  }

  .double-box p {
    font-size: 13px;
    line-height: 1.2em;
  }
}

.form-contact input[type="text"].body_field {
  margin: 8px 0;
}

.simple_btn.right {
  float: right;
}

.slack_field {
  margin-bottom: 16px;
  font-size: 14px;
  outline: none;
  padding: 0;
  box-sizing: border-box;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border: 1px solid rgba(255, 255, 255, 0.13);
  display: flex;
  align-items: center;
}

.apiMethod {
  font-weight: normal;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 15px;
}

ul.table {
  margin: 0;
}

.row {
}

.support_accord_title {
  /*display: flex;*/
  /*justify-content: space-between;*/
  /*text-align: center;*/
}

.cell {
  flex: 1 1 100%;
}

/*.cell .arr_line_1 a svg{*/
/*    padding-right: 5rem;*/
/*    }*/

.block__forum_table {
  box-sizing: border-box;
  color: black;
  text-align: left;
  margin-left: 40px;
  margin-top: 20px;
  position: relative;
  z-index: 50;
  border-radius: 10px;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.26);
  overflow: hidden;
  margin: 25px auto;
  margin-bottom: 65px;
  width: 95%;
  background-color: rgba(255, 255, 255, 0.7);
}
.block__forum_table th,
.block__forum_table td {
  padding: 14px 24px;
}
.block__forum_table a {
  color: #006e80;
}
.block__forum_table thead th {
  background-color: #006e80;
  font-weight: 900;
  color: white;
}
.block__forum_table thead th:nth-child(1) {
  width: 10%;
}
.block__forum_table thead th:nth-child(2) {
  width: 45%;
}
.block__forum_table thead th:nth-child(3) {
  width: 15%;
  text-align: center;
}
.block__forum_table thead th:nth-child(4) {
  width: 15%;
  text-align: center;
}
.block__forum_table thead th:nth-child(5) {
  width: 15%;
  text-align: center;
}
.block__forum_table tbody td:nth-child(3) {
  text-align: center;
}
.block__forum_table tbody td:nth-child(4) {
  text-align: center;
}
.block__forum_table tbody td:nth-child(5) {
  text-align: center;
}
.block__forum_table tbody tr:nth-child(odd) {
  background-color: rgba(184, 184, 184, 0.1);
}
.block__forum_table .folder_img {
  width: 60px;
}
.block__forum_table .subscribe {
  padding: 2px 10px;
  border: 1px solid #b1bacc;
  background: rgba(155, 155, 155, 0.1);
  border-radius: 4px;
  font-size: 0.8rem;
  display: inline-block;
  float: right;
}
.block__plans a {
  text-decoration: none;
}
.block__plans i {
  float: right;
}
.block__elem_plan_name {
  width: 60% !important;
}
.block__elem_plan_sub {
  width: 40%;
  text-align: center;
}
.block__plans tbody tr td {
  text-align: center;
}

.padding_container {
  color: gray;
  box-sizing: border-box;
  width: 100%;
  padding: 20px 0px 20px 20px;
}
.padding_container h5 {
  text-indent: 5px;
  margin-bottom: 10px;
  font-weight: 900;
}
.padding_container .endpoint {
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #b1bacc;
}
.padding_container .btn--production {
  float: right;
  padding: 7px 10px;
  background-color: #ffdb7a;
  margin-left: 20px;
  border-radius: 10px;
  color: black;
  -webkit-transform: translateY(-7px);
  transform: translateY(-7px);
}
.padding_container .btn--development {
  float: right;
  padding: 7px 10px;
  background-color: #006e80;
  margin-left: 10px;
  border-radius: 10px;
  color: white;
  -webkit-transform: translateY(-7px);
  transform: translateY(-7px);
}
.padding_container h4 {
  padding: 10px;
  line-height: 20px;
  height: 20px;
  margin-top: 20px;
  border: none;
}

.user_edit .padding_container {
  padding: 20px;
}
.user_edit .padding_container h4 {
  display: inline-block;
  margin: 0;
  padding: 0;
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid #b1bacc;
}

.support_page__started_container {
  /*-webkit-box-sizing: border-box;*/
  /*        box-sizing: border-box;*/
  /*color: black;*/
  /*text-align: left;*/
  /*margin-left: 40px;*/
  /*margin-top: 20px;*/
  /*position: relative;*/
  /*z-index: 50;*/
  /*border-radius: 10px;*/
  /*-webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.26);*/
  /*        box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.26);*/
  /*overflow: hidden;*/
  /*margin: 25px auto;*/
  /*margin-bottom: 65px;*/
  /*padding: 10px 40px 40px 40px;*/
  /*top: -15px;*/
  /*z-index: 299;*/
  /*background-color: rgba(255, 255, 255, 0.7);*/
  /*width: 80%;*/
}

.support_page__started_container h3 {
  /*font-weight: 900;*/
  /*color: #006E80;*/
  /*font-size: 1.4rem;*/
}


.overlayLoader {
    height: calc(100% + 10px);
    width: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
    z-index: 2;
    text-shadow: white 0 0 1px;
}

.overlayLoader-content {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.tag-action {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.tag-action .tag {
  cursor: auto;
  cursor: initial;
}

.messages-history__content {
  flex: 0 1 640px;
  margin-right: 24px;
}

/*# sourceMappingURL=supportTicketId.css.map */

/*!* MESSAGE-BOX *!*/

/*.message-box {*/
/*    padding: 0 10px 1rem 10px;*/
/*    border-radius: 6px;*/
/*    margin: 2rem 0;*/
/*    color: black;*/
/*    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1), 0 1px 5px rgba(0, 0, 0, .15);*/
/*    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1), 0 1px 5px rgba(0, 0, 0, .15);*/
/*    }*/

/*.message-box__top {*/
/*    padding-top: .5rem;*/
/*    border-bottom: 2px solid #006E80;*/
/*    }*/

/*.message-box__top::after {*/
/*    display: table;*/
/*    content: '';*/
/*    clear: both;*/
/*    }*/

/*.message-box__title {*/
/*    font-weight: 700;*/
/*    color: #006E80;*/
/*    !*font-size: 1.4em;*!*/
/*    float: left;*/
/*    }*/

/*h1.message-box__title {*/
/*    font-size: 1.4rem;*/
/*    }*/

/*.message-box__subtitle {*/
/*    float: right;*/
/*    color: #9B9B9B;*/
/*    }*/

/*.message-box__body {*/
/*    font-size: 1rem;*/
/*    line-height: 1.5;*/
/*    padding: 8px;*/
/*    margin: 0;*/
/*    }*/

/*!* MODIFIERS *!*/

/*.message-box--answer {*/
/*    margin-left: 10%;*/
/*    background: rgba(0, 110, 128, 1);*/
/*    color: white;*/
/*    position: relative;*/
/*    }*/

/*.message-box--answer .message-box__title,*/
/*.message-box--answer .message-box__subtitle {*/
/*    color: white;*/
/*    }*/

/*.message-box--answer .message-box__top {*/
/*    border-bottom: 2px solid #F8E71C;*/
/*    }*/

/*.message-box--answer::before {*/
/*    content: '';*/
/*    background: url("../../../src/assets/assets/images/ibank.png") no-repeat;*/
/*    background-size: contain;*/
/*    width: 50px;*/
/*    height: 50px;*/
/*    position: absolute;*/
/*    left: 0;*/
/*    top: 50px;*/
/*    -webkit-transform: translateY(-50%) translateX(-100%);*/
/*    -ms-transform: translateY(-50%) translateX(-100%);*/
/*    transform: translateY(-50%) translateX(-100%);*/
/*    margin-left: -2.5%;*/
/*    font-size: 2rem;*/
/*    font-weight: 700;*/
/*    }*/

/*@media screen and (max-width: 450px) {*/
/*    .message-box--answer::before {*/
/*        width: 30px;*/
/*        }*/
/*    }*/

/* ------------------------------------- */

.support.main_comment {
  display: flex;
  width: 100%;
  padding: 20px 0;
}

.support {
  font-family: "CF Asty Std";
  /*font-weight: 900;*/
  color: #ffffff;
  background: rgba(255, 255, 255, 0.13);
  -webkit-font-smoothing: antialiased;
  width: 100%;
  position: relative;
}

.support:after {
  content: "";
  position: absolute;
  border-left: 20px solid transparent;
  /* border-right: 20px solid transparent; */
  border-top: 20px solid rgba(255, 255, 255, 0.22);
  /* border-bottom: 20px solid #1a4663; */
  width: 0px;
  height: 0px;
  /* transform: rotate(45deg); */
  right: 0px;
  top: 0px;
}

.title_table {
  display: table;
  width: 100%;
}
.support.comment_details {
  display: inline-block;
  float: left;
  width: 140px;
  padding: 0 10px;
}

.support_comment_user {
  text-align: left;
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  font-weight: 500;
}

.support_comment_date {
  text-align: left;
  font-size: 12px;
  color: #fff77d;
}

.support.comment_body {
  display: inline-block;
  float: left;
  width: calc(100% - 180px);
  padding: 0 10px;
}

.comment_txt {
  font-size: 13px;
  padding: 20px 20px;
  background: #ffffff08;
  /* margin: 0; */
  border-bottom: 6px solid rgba(255, 255, 255, 0.22);
}

.comment_txt__attachment {
  margin-left: 10px;
  vertical-align: sub;
}

.comment_txt div {
  color: white;
}

.message--answer {
  margin-left: 10%;
  color: white;
  position: relative;
}

.message--answer::before {
  content: "";
  background-size: contain;
  width: 50px;
  height: 50px;
  position: absolute;
  left: 0;
  top: 50px;
  -webkit-transform: translateY(-50%) translateX(-100%);
  transform: translateY(-50%) translateX(-100%);
  margin-left: -2.5%;
  font-size: 2rem;
  font-weight: 700;
}

.message--answer .support_comment_user {
  color: #fff77d;
}

.message--answer .support_comment_date {
  color: #33b3bf;
}

.file-icon {
  width: 20px;
  height: 20px;
  margin-right: 6px;
  margin-top: 6px;
  fill: white;
}

@media screen and (max-width: 767px) {
  .support.main_comment {
    display: inline-block;
    padding: 20px 0 10px 0;
  }
}

.sticky-row {
  margin: 1rem 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.sticky-row__content {
  /*max-width: 800px;*/
}

.sticky-row__sticky {
  /*-webkit-box-flex: 1;*/
  /*-ms-flex: 1 1 auto;*/
  /*flex: 1 1 auto;*/
  /*padding: 0 2rem;*/
  position: -webkit-sticky;
  position: sticky;
  top: 2rem;
}

.usr {
  display: inline-flex;
  align-items: center;
}

.loged {
  display: inline-block;
  margin: 5px 0;
  padding: 0px 10px;
}
.loged .blueTxt {
  color: #1c869d;
  font-size: 14px;
}
.loged .usr_name {
  font-family: "CF Asty Std";
  font-size: 16px;
}

.usr_choise {
  align-items: center;
  display: inline-flex;
  padding: 0px 10px;
  font-size: 14px;
  font-weight: 500;
}

.login {
  border-right: 1px solid #fff77d;
}
.login i {
  margin-left: 5px;
  color: #1c869d;
}

.signup i {
  margin-left: 5px;
  color: #1c869d;
}

.usr_arrow {
  background: #fff0;
  border: none;
  color: #fff;
  cursor: pointer;
  display: contents;
}
.usr_arrow .icon-arrow_down {
  color: #809597;
}

.usr_drop {
  position: absolute;
  /*    width: 100px;
  height: 100px;*/
  background: #013942;
  top: 25px;
  right: 80px;
  z-index: 100000;
}
.usr_drop hr {
  width: 70%;
  border: 0;
  border-top: 1px solid #1c869d;
}

.profile {
  display: block;
  margin: 8px 20px;
}
.profile .prof-icon {
  margin: 4px 20px 4px 0px;
}
.profile .icon-profile_settings {
  color: #1c869d;
}
.profile .icon-profile_account {
  color: #1c869d;
}
.profile .icon-profile_signout {
  color: #c1272d;
}

@media screen and (max-width: 1199px) {
  .mobile-nav-container .login {
    border-right: none;
  }
  .mobile-nav-container .usr_choise,
.mobile-nav-container .loged {
    padding: 0px;
    margin: 0px;
    font-size: 10px;
    width: inherit;
  }
  .mobile-nav-container .mobile_signUp_icon {
    font-size: 22px;
    width: inherit;
  }
  .mobile-nav-container .mobile_login_icon {
    width: inherit;
    font-size: 22px;
  }
}
@media screen and (max-width: 575px) {
  .mobile-nav-container .usr {
    margin-right: 10px;
  }
  .mobile-nav-container .usr_drop {
    right: 10px;
  }

  .usr_choise {
    font-size: 12px;
  }
}
.cookie-settings {
  display: inline-flex;
  align-items: center;
}
.cookie-settings img {
  width: 24px;
  height: auto;
}
.cookie-settings span {
  font-size: 14px;
  margin-left: 4px;
}

/*# sourceMappingURL=accountMenu.css.map */

.st0 {
  fill: #859096;
}
.st1 {
  fill: #fff77d;
}
.st2 {
  fill: #ffffff;
}
.st3 {
  opacity: 0.1;
  fill: #ffffff;
}
.st4 {
  fill: #33b3bf;
}
.st5 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ffffff;
}
.st6 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #007580;
}
.st7 {
  fill: #231f20;
}
.st8 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #231f20;
}
.st9 {
  fill: #c49958;
}
.st10 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 0.9789;
  stroke-miterlimit: 10;
}
.st11 {
  opacity: 0.3;
}
.st12 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 1.0001;
  stroke-miterlimit: 10;
}
.st13 {
  fill: none;
  stroke: #fff77d;
  stroke-width: 1.0001;
  stroke-miterlimit: 10;
}
.st14 {
  fill: none;
  stroke: #fff77d;
  stroke-width: 0.5;
  stroke-miterlimit: 10;
}
.st15 {
  fill: #024a6c;
}
.st16 {
  fill: #1c869d;
}
.st17 {
  fill: #1b2d4c;
}
.st18 {
  opacity: 0.5;
  fill: #1b2d4c;
}
.st19 {
  opacity: 0.2;
  fill: #1b2d4c;
}
.st20 {
  fill: url(#SVGID_1_);
}
.st21 {
  fill: url(#SVGID_2_);
}
.st22 {
  fill: url(#SVGID_3_);
}
.st23 {
  opacity: 0.7;
  fill: url(#SVGID_4_);
}
.st24 {
  opacity: 0.5;
  fill: #ffffff;
}
.st25 {
  opacity: 0.5;
}
.st26 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 1.0014;
  stroke-miterlimit: 10;
}
.st27 {
  fill: none;
  stroke: #fff77d;
  stroke-width: 1.0014;
  stroke-miterlimit: 10;
}
.st28 {
  opacity: 0.15;
}
.st29 {
  -webkit-clip-path: url(#SVGID_6_);
  clip-path: url(#SVGID_6_);
}
.st30 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 0.9738;
  stroke-miterlimit: 10;
}
.st31 {
  opacity: 0.05;
  fill: #ffffff;
}
.st32 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 0.5;
  stroke-miterlimit: 10;
}
.st33 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 2;
  stroke-miterlimit: 10;
}
.st34 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #1c869d;
}
.st35 {
  fill: #c1272d;
}
.st36 {
  fill: #0b698e;
}
.st37 {
  opacity: 0.1;
}
.st38 {
  -webkit-clip-path: url(#SVGID_8_);
  clip-path: url(#SVGID_8_);
}
.st39 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 1.0005;
  stroke-miterlimit: 10;
  stroke-opacity: 0.3;
}
.st40 {
  opacity: 0.3;
  fill: #ffffff;
}

.fa-sign-in-alt {
  color: #1c869d;
}

.fa-user-plus {
  color: #1c869d;
}

.paths {
  position: absolute;
  left: 320px;
  margin-top: 12px;
  color: #2a9da8;
  font-size: 14px;
  z-index: 2;
}

.paths a,
.paths a:visited {
  color: inherit;
}
.paths a:hover {
  color: #fff77d;
}

@media screen and (max-width: 1199px) {
  .paths {
    left: 240px;
    margin-top: 12px;
  }
}

@media screen and (max-width: 575px) {
  .paths {
    left: 20px;
    margin-top: 16px;
    display: none;
  }
}

@media screen and (max-width: 575px) {
  .back-to-top {
    /*margin-right: 30px;*/
  }
}

.banner {
  display: flex;
  align-items: center;
  background: white;
  padding: 1rem 18%;
  margin: 4rem 10px 1rem;
}

.banner__image {
  flex: 0 1 20%;
}

.banner__image img {
  max-width: 100%;
  height: auto;
}

.banner__content {
  color: #4d4e50;
  flex: 1 1 auto;
  margin-left: 10%;
}

.banner h3 {
  font-size: 1.3rem;
  font-weight: 900;
}

.banner a {
  vertical-align: middle;
  display: inline-block;
}

@media screen and (max-width: 900px) {
  .banner {
    display: flex;
    padding: 2rem 5%;
  }
}

.terminal-list {
}

.terminal-table {
  width: 100%;
  text-align: left;
}

.terminal-table {
  border: 1px dashed rgba(255, 255, 255, 0.3);
  border-collapse: collapse !important;
}

.terminal-table thead th,
.terminal-table tbody td {
  border: 1px dashed rgba(255, 255, 255, 0.3);
  padding-left: 5px;
}

.terminal-form {
  /*background: red;*/
}

.terminal-form__item {
  margin-top: 1rem;
}

.terminal-form__item > div:first-child {
  font-weight: bold;
  color: #ffdb7a;
}

.terminal-form__type {
  margin-top: 0.5rem;
}

.terminal-form__type span {
  margin-top: 0.5rem;
  color: limegreen;
}

.terminal-form__error {
  display: block;
  margin-top: 0.5rem;
}

.terminal-form__error span {
  color: red;
}

.terminal-form__allowed {
  color: white;
  padding: 0.5rem 0;
  margin-top: 0.2rem;
  font-size: 13px;
}

.terminal-form__allowed small {
  color: white;
  font-size: inherit;
  margin-bottom: 0.2rem;
  margin-right: 0.2rem;
  /*font-weight: 900;*/
}

.terminal-form__allowed div {
  display: inline;
  color: white;
}

.terminal-form__allowed span {
  color: #ffdb7a;
  vertical-align: middle;
}

.terminal-form__suggested {
  color: white;
  font-weight: normal;
  font-weight: initial;
}

.snake-game {
}

.snake-game__modal {
  padding: 0;
  border: none;
  background: transparent;
  box-shadow: none;
}
.snake-game__modal .modal__close {
  color: white;
  top: 0;
  border-radius: 50%;
  padding: 0;
}

.snake-game canvas {
  /*border-top: 1px solid whitesmoke;*/
  width: 100%;
}

.snake-game__head {
  /*padding-bottom: 4px;*/
  /*border-bottom: 5px solid lightgrey;*/
}
.snake-game__head > div {
  padding-top: 10px;
  display: flex;
}

.snake-game__head {
  /*position: absolute;*/
  background: black;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
}

.snake-game__score {
  color: lime;
  font-size: 25px;
  padding: 10px;
}

.snake-game__over {
  opacity: 0;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.snake-game button[disabled] {
  cursor: not-allowed;
}

.snake-game__over button {
  color: white;
  font-weight: bold;
  font-size: 20px;
}

.snake-game--end .snake-game__over {
  opacity: 1;
}

.snake-game button {
  background: none;
  cursor: pointer;
  border: none;
}

.snake-game button:hover,
.snake-game button:focus {
  outline: none;
}

.o-play-btn {
  position: relative;
  background: transparent;
  border: none;
  outline: none;
}
.o-play-btn__icon {
  height: 30px;
  width: 30px;
  line-height: 30px;
  position: relative;
  z-index: 0;
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
}
.o-play-btn__icon:before,
.o-play-btn__icon:after {
  content: "";
  position: absolute;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background: #fff;
  height: 100%;
  width: 50%;
  top: 0;
}

.o-play-btn:hover .o-play-btn__icon:before,
.o-play-btn:hover .o-play-btn__icon:after {
  background: orangered;
}

.o-play-btn__icon:before {
  left: 0;
}
.o-play-btn__icon:after {
  right: 0;
}
.o-play-btn__mask {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
}
.o-play-btn__mask:before,
.o-play-btn__mask:after {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 150%;
  background: black;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.o-play-btn__mask:before {
  top: -100%;
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-transform: rotate(26.5deg);
  transform: rotate(26.5deg);
}
.o-play-btn__mask:after {
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
  -webkit-transform: rotate(-26.5deg);
  transform: rotate(-26.5deg);
  top: 100%;
}
.o-play-btn--playing .o-play-btn__icon:before {
  -webkit-transform: translateX(-25%);
  transform: translateX(-25%);
}
.o-play-btn--playing .o-play-btn__icon:after {
  -webkit-transform: translateX(25%);
  transform: translateX(25%);
}
.o-play-btn--playing .o-play-btn__mask:before,
.o-play-btn--playing .o-play-btn__mask:after {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.danceText {
  font-size: 3rem;
  font-weight: bold;
  font-family: sans-serif;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.danceText span {
  display: inline-block;
  -webkit-animation: float 0.5s ease-in-out infinite;
  animation: float 0.5s ease-in-out infinite;
}

.danceText span:empty {
  box-sizing: border-box;
  margin-right: 15px;
}

.danceText:hover span {
  -webkit-animation: bounce 0.6s;
  animation: bounce 0.6s;
}

@-webkit-keyframes float {
  0%,
  100% {
    -webkit-transform: none;
    transform: none;
  }
  33% {
    -webkit-transform: translateY(-1px) rotate(-2deg);
    transform: translateY(-1px) rotate(-2deg);
  }
  66% {
    -webkit-transform: translateY(1px) rotate(2deg);
    transform: translateY(1px) rotate(2deg);
  }
}

@keyframes float {
  0%,
  100% {
    -webkit-transform: none;
    transform: none;
  }
  33% {
    -webkit-transform: translateY(-1px) rotate(-2deg);
    transform: translateY(-1px) rotate(-2deg);
  }
  66% {
    -webkit-transform: translateY(1px) rotate(2deg);
    transform: translateY(1px) rotate(2deg);
  }
}

@-webkit-keyframes bounce {
  0%,
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  25% {
    -webkit-transform: rotateX(20deg) translateY(2px) rotate(-3deg);
    transform: rotateX(20deg) translateY(2px) rotate(-3deg);
  }
  50% {
    -webkit-transform: translateY(-20px) rotate(3deg) scale(1.1);
    transform: translateY(-20px) rotate(3deg) scale(1.1);
  }
}

@keyframes bounce {
  0%,
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  25% {
    -webkit-transform: rotateX(20deg) translateY(2px) rotate(-3deg);
    transform: rotateX(20deg) translateY(2px) rotate(-3deg);
  }
  50% {
    -webkit-transform: translateY(-20px) rotate(3deg) scale(1.1);
    transform: translateY(-20px) rotate(3deg) scale(1.1);
  }
}

.danceText span:nth-of-type(4n) {
  color: hsl(50, 75%, 55%);
  text-shadow: 1px 1px hsl(50, 75%, 45%), 2px 2px hsl(50, 45%, 45%),
    3px 3px hsl(50, 45%, 45%), 4px 4px hsl(50, 75%, 45%);
}

.danceText span:nth-of-type(4n-1) {
  color: hsl(135, 35%, 55%);
  text-shadow: 1px 1px hsl(135, 35%, 45%), 2px 2px hsl(135, 35%, 45%),
    3px 3px hsl(135, 35%, 45%), 4px 4px hsl(135, 35%, 45%);
}

.danceText span:nth-of-type(4n-2) {
  color: hsl(155, 35%, 60%);
  text-shadow: 1px 1px hsl(155, 25%, 50%), 2px 2px hsl(155, 25%, 50%),
    3px 3px hsl(155, 25%, 50%), 4px 4px hsl(140, 25%, 50%);
}

.danceText span:nth-of-type(4n-3) {
  color: hsl(30, 65%, 60%);
  text-shadow: 1px 1px hsl(30, 45%, 50%), 2px 2px hsl(30, 45%, 50%),
    3px 3px hsl(30, 45%, 50%), 4px 4px hsl(30, 45%, 50%);
}

.danceText span:nth-of-type(2) {
  -webkit-animation-delay: 0.05s;
  animation-delay: 0.05s;
}

.danceText span:nth-of-type(3) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.danceText span:nth-of-type(4) {
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}

.danceText span:nth-of-type(5) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.danceText span:nth-of-type(6) {
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}

.danceText > div:nth-child(2) span:nth-child(1) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.danceText > div:nth-child(2) span:nth-child(2) {
  -webkit-animation-delay: 0.35s;
  animation-delay: 0.35s;
}

.danceText > div:nth-child(2) span:nth-child(3) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.danceText > div:nth-child(2) span:nth-child(4) {
  -webkit-animation-delay: 0.45s;
  animation-delay: 0.45s;
}

.danceText > div:nth-child(2) span:nth-child(5) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.danceText > div:nth-child(2) span:nth-child(6) {
  -webkit-animation-delay: 0.55s;
  animation-delay: 0.55s;
}

.danceText > div:nth-child(2) span:nth-child(7) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.danceText > div:nth-child(2) span:nth-child(8) {
  -webkit-animation-delay: 0.65s;
  animation-delay: 0.65s;
}

.terminal-wrapper .moveBox__navbar {
  background: silver;
}

.terminal-wrapper .moveBox__expand {
  background: black;
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.terminal-wrapper .moveBox__window {
  top: 20%;
  left: 25%;
  height: 300px;
}

.terminal,
.terminal * {
  box-sizing: border-box;
}

.terminal {
  font-family: monospace !important;
  font-size: 14px !important;

  background: black;
  color: white;
  padding: 16px 10px 4px;
  border-top: 1px solid lightgrey;
  min-height: 200px;
  /*max-height: 200px;*/
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
}

.terminal__response {
  margin: 0;
  padding: 0;
}

.terminal__command {
  /*display: flex;*/
}

.terminal__command span {
  font-size: 1em;
}

.terminal-input {
  flex: 1 1 auto;
}

.terminal-input__input {
  overflow: hidden;
  opacity: 0;
  height: 0;
  width: 0;
  display: inline;
}

.terminal__command-suggestions {
  -webkit-column-width: 160px;
  column-width: 160px;
}

.terminal__command-suggestions div {
  color: lightblue;
  padding: 4px;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

.terminal-input__caret {
  background-color: transparent;
  box-sizing: content-box;
  border: 1px solid white;
  text-align: center;
  align-self: stretch;
  display: inline-block;
  vertical-align: bottom;
}

.terminal-input__caret:empty {
  box-sizing: border-box;
  width: 8px;
  height: 16px;
}

.terminal-input--active .terminal-input__caret {
  background-color: white;
  color: black;
  border: none;
  -webkit-animation: terminal-blink 1s infinite steps(1, start);
  animation: terminal-blink 1s infinite steps(1, start);
}

.command-line {
  margin: 4px 0;
}

.command-line__command {
}

.command-line__response {
  margin: 8px 0;
}

.command-line--skipped {
  display: none;
}

.command-prefix {
  margin-right: 8px;
  color: lightgreen;
}

@-webkit-keyframes terminal-blink {
  0% {
    background-color: white;
    color: black;
  }
  50% {
    background-color: transparent;
    color: white;
  }
  100% {
    color: black;
    background-color: white;
  }
}

@keyframes terminal-blink {
  0% {
    background-color: white;
    color: black;
  }
  50% {
    background-color: transparent;
    color: white;
  }
  100% {
    color: black;
    background-color: white;
  }
}

.terminal ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(67, 127, 122, 0.3);
  border-radius: 10px;
  background: #f5f5f5;
}

.terminal ::-webkit-scrollbar {
  width: 8px;
  border-radius: 10px;
}

.terminal ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: lightgrey;
}

.getting-started-button {
  width: 150px !important;
  border-radius: 24px;
  padding: 4px 12px;
  background: #024a6c;
  z-index: 1000;
}
.getting-started-button svg {
  width: 18px;
  height: 18px;
}

.getting-started-button-inner {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

button:disabled {
  background: rgba(255, 255, 255, 0.35);
}

.quick-start-title {
  position: absolute;
  width: 100%;
  bottom: 0px;
  text-align: center;
  cursor: pointer;
}

.product_tour_accordion {
  text-align: center;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

@media screen and (max-width: 1200px) {
  .getting-started-button {
    display: none;
  }
}

/*# sourceMappingURL=gettingStartedButtons.css.map */

.product_tour_container {
  padding: 8px;
}

.product_tour_closeButton {
  width: 15px;
  height: 15px;
  cursor: pointer;
  fill: white;
}

/*# sourceMappingURL=productTour.css.map */


.check-mark-icon-animated svg {
    width: 52px;
    display: block;
    position: absolute;
}

.path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
&.circle {
     -webkit-animation: dash .9s ease-in-out;
     animation: dash .9s ease-in-out;
 }
&.line {
     stroke-dashoffset: 1000;
     -webkit-animation: dash .9s .35s ease-in-out forwards;
     animation: dash .9s .35s ease-in-out forwards;
 }
&.check {
     stroke-dashoffset: -100;
     -webkit-animation: dash-check .9s .35s ease-in-out forwards;
     animation: dash-check .9s .35s ease-in-out forwards;
 }
}


@-webkit-keyframes dash {
    0% {
        stroke-dashoffset: 1000;
    }
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: 1000;
    }
    100% {
        stroke-dashoffset: 0;
    }
}

@-webkit-keyframes dash-check {
    0% {
        stroke-dashoffset: -100;
    }
    100% {
        stroke-dashoffset: 900;
    }
}

@keyframes dash-check {
    0% {
        stroke-dashoffset: -100;
    }
    100% {
        stroke-dashoffset: 900;
    }
}

.product_tour_sidebar_container {
  position: fixed;
  bottom: 0px;
  height: 100%;
  background-color: #024a6c;
  box-shadow: 0 4px 8px -2px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.31);
}

.product_tour_sidebar_inner {
  margin-top: 32px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 24px;
  padding-right: 22px;
  padding-left: 12px;
}

.product_tour_sidebar_header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 45px;
}
.product_tour_sidebar_header h2 {
  font-size: 42px;
  margin-bottom: 0px;
}

/*# sourceMappingURL=layout.css.map */

.onboarding-modal-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 14px;
}
.onboarding-modal-content p {
  font-weight: 500;
  color: white;
}

.onboarding-modal-footer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.onboarding-modal-footer p {
  font-weight: 500;
  color: white;
  font-size: 14px;
  margin-top: 18px;
}

.stepper-form-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.steps-counter {
  margin: auto;
}

.stepper-form-action-buttons {
  margin-top: 24px;
  display: flex;
}

.step-form {
  height: 100%;
  width: 100%;
  margin-top: 12px;
  margin-right: 22px;
}
.step-form form {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.onboarding-api-selectable {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  width: 100%;
}
.onboarding-api-selectable div {
  cursor: pointer;
}

.stepper-container {
  width: 25%;
  /* regular stepper-step */
}
.stepper-container .stepper-step {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: cream;
}
.stepper-container .v-stepper {
  position: relative;
  /*   visibility: visible; */
}
.stepper-container .stepper-step .stepper-circle {
  background-color: white;
  border: 3px solid gray;
  border-radius: 100%;
  width: 20px;
  /* +6 for border */
  height: 20px;
  display: inline-block;
}
.stepper-container .stepper-step .line {
  top: 23px;
  left: 12px;
  /*   height: 120px; */
  height: 100%;
  position: absolute;
  border-left: 3px solid gray;
}
.stepper-container .stepper-step.completed .stepper-circle {
  visibility: visible;
  background-color: #0696d7;
  border-color: #0696d7;
}
.stepper-container .stepper-step.completed .line {
  border-left: 3px solid #0696d7;
}
.stepper-container .stepper-step.active .stepper-circle {
  visibility: visible;
  border-color: #0696d7;
}
.stepper-container .stepper-step.empty .stepper-circle {
  visibility: hidden;
}
.stepper-container .stepper-step.empty .line {
  /*     visibility: hidden; */
  /*   height: 150%; */
  top: 0;
  height: 150%;
}
.stepper-container .stepper-step:last-child .line {
  border-left: 3px solid white;
  z-index: -100;
  /* behind the stepper-circle to completely hide */
}
.stepper-container .content {
  margin-left: 20px;
  display: inline-block;
}

/*# sourceMappingURL=styles.css.map */

@font-face {
  font-family: "CF Asty Std";
  src: url(/static/media/CFAstyStd-MediumOblique.8963bb9e.woff2) format("woff2"), url(/static/media/CFAstyStd-MediumOblique.d175a1cb.woff) format("woff"), url(/static/media/CFAstyStd-MediumOblique.d7be3bc1.ttf) format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "CF Asty Std";
  src: url(/static/media/CFAstyStd-Book.e8b0505e.woff2) format("woff2"), url(/static/media/CFAstyStd-Book.15e87499.woff) format("woff"), url(/static/media/CFAstyStd-Book.d213afe2.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "CF Asty Std";
  src: url(/static/media/CFAstyStd-Medium.b76e29d5.woff2) format("woff2"), url(/static/media/CFAstyStd-Medium.0e279517.woff) format("woff"), url(/static/media/CFAstyStd-Medium.f3352a1f.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}
body {
  font-family: "CF Asty Std";
  overflow-x: hidden;
  position: relative;
  padding: 0;
  margin: 0;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body::before {
  content: "";
  background: #015978;
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(linear, left top, right top, from(#015978), color-stop(28%, #005782), to(#1c729d));
  background: linear-gradient(to right, #015978 0%, #005782 28%, #1c729d 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#015978", endColorstr="#1c729d", GradientType=1);
  /* IE6-9 */
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
}

::selection {
  color: white;
  background: #ffdb7a;
}

::-moz-selection {
  color: white;
  background: #ffdb7a;
}

#connection_point {
  opacity: 0;
}

.react-tagsinput-tag {
  background-color: #fff77d;
  border-color: #ffffff4f;
}

.react-tagsinput-remove {
  color: #024a6c;
}

.text--highlighted {
  background: #d9ac33;
}

body::-webkit-scrollbar {
  background: #1c729d;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  height: 10px;
  background: #005782;
}

::-webkit-scrollbar-thumb:hover {
  background: #3a7ca5;
}

label {
  cursor: pointer;
}

.main_cms {
  border-radius: 20px;
  position: relative;
  padding: 20px 0px 120px 0px;
  background-color: rgba(255, 255, 255, 0.95);
  margin: 0 8px;
  /*overflow: hidden;*/
  box-shadow: 0 2px 74px 0 rgba(0, 0, 0, 0.29);
}

.responsive-table {
  overflow-x: auto;
  overflow-y: hidden;
}

.responsive-table table {
  width: 100%;
}

/************* FOR CKEditor *****************/
.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-align-justify {
  text-align: justify;
}

.spinner-mini {
  vertical-align: middle;
  display: inline-block !important;
  margin-bottom: 0 !important;
}

.spinner-mini svg {
  width: 25px !important;
  height: 25px !important;
}

.page--news input {
  display: block;
}

.page--chips {
  width: 100%;
  display: flex;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px rgba(255, 219, 122, 0.4) inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

textarea {
  resize: vertical;
  width: 100%;
  font-size: 14px;
  border: none;
  margin: 8px 0;
  outline: none;
  padding: 15px 40px 15px 20px;
  box-sizing: border-box;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background: rgba(255, 255, 255, 0.13);
  color: #ced9de;
  -webkit-text-fill-color: rgba(255, 255, 255, 0.8);
  font-family: "CF Asty Std";
}

input {
  caret-color: #fff;
  font-family: "CF Asty Std";
  -webkit-text-fill-color: white !important;
  font-size: 14px;
}

input:not([type=radio]),
input[type=text],
textarea {
  -webkit-appearance: none !important;
  border-radius: 0;
  outline: none;
}

a {
  color: #fff;
}
a:visited {
  text-decoration: none;
}
a:link {
  text-decoration: none;
}

ul {
  list-style-type: none;
}

img {
  width: 100%;
  height: auto;
  display: block;
}

#root > div > section > .container > *:first-child {
  min-height: 40vh;
}
#root > div > section > .container .container {
  width: auto;
  width: initial;
  border-left: none;
  border-right: none;
}
#root > div > section > .container .container:last-child {
  border-bottom: none;
}

.hidden {
  display: none;
}

.container.menu {
  border-left: none;
}

@media screen and (max-width: 575px) {
  .container .menu {
    border-left: none;
    width: calc(100% - 19px);
  }
}
@media screen and (max-width: 900px) {
  html {
    /*font-size: 10px;*/
  }

  .main_wrapper {
    max-width: none;
    max-width: initial;
    padding: 8px;
  }

  .main_cms {
    padding: 0 !important;
  }

  .home_main {
    background-image: none;
  }

  .page--chips {
    display: block;
  }
}

/*# sourceMappingURL=base.css.map */

.pagePreloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 100000000001;
}

.pagePreloader__wrap {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.pagePreloader__logo img {
  width: 357px;
  position: static;
  position: initial;
}

@media screen and (max-width: 900px) {
  .pagePreloader__logo img {
    width: 200px;
  }
}

.pagePreloader__text {
  color: white;
  display: inline-block;
  margin-left: 16px;
}

.pagePreloader__bounceball {
  position: relative;
  display: inline-block;
  height: 37px;
  width: 16px;
}

.pagePreloader__bounceball::before {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: white;
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: bounce 500ms alternate infinite ease;
  animation: bounce 500ms alternate infinite ease;
}

.pagePreloader__bounceball::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 16px;
  height: 16px;
  /*background-image: -webkit-gradient(linear, left top, right top, color-stop(-40%, #020809), to(#437F7A));*/
  /*background-image: -webkit-linear-gradient(left, #020809 -40%, #437F7A 100%);*/
  /*background-image: -o-linear-gradient(left, #020809 -40%, #437F7A 100%);*/
  /*background-image: linear-gradient(-270deg, #020809 -40%, #437F7A 100%);*/
  background-image: -webkit-gradient(
    linear,
    left top, right top,
    from(#015978),
    color-stop(28%, #005782),
    to(#1c869d)
  );
  background-image: linear-gradient(
    -270deg,
    #015978 0%,
    #005782 28%,
    #1c869d 100%
  );
  -webkit-transform: scale(250) translateZ(0);
  transform: scale(250) translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  z-index: -1;
}

.pagePreloader--complete {
  border-radius: 50%;
  -webkit-transition: opacity 0.4s ease-in 0.8s, height 0.1s linear 1.2s,
    width 0.1s linear 1.2s;
  transition: opacity 0.4s ease-in 0.8s, height 0.1s linear 1.2s,
    width 0.1s linear 1.2s;
  opacity: 0;

  width: 0;
  height: 0;
}

.pagePreloader--complete .pagePreloader__bounceball {
  -webkit-transform: scale(0.01);
  transform: scale(0.01);
  -webkit-transition: -webkit-transform 0.1s ease-in 0.8s;
  transition: -webkit-transform 0.1s ease-in 0.8s;
  transition: transform 0.1s ease-in 0.8s;
  transition: transform 0.1s ease-in 0.8s, -webkit-transform 0.1s ease-in 0.8s;
  -webkit-transform-origin: 8px 8px;
  transform-origin: 8px 8px;
}

.pagePreloader--complete .pagePreloader__bounceball::after {
  -webkit-transition: -webkit-transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
  transition: -webkit-transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1),
    -webkit-transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transform: scale(1) translateZ(0);
  transform: scale(1) translateZ(0);
  background: #437f7a;

  border-radius: 50%;
}

.pagePreloader--complete .pagePreloader__bounceball::before {
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;

  background-color: #437f7a;
}

.pagePreloader--complete .pagePreloader__text,
.pagePreloader--complete .pagePreloader__logo {
  opacity: 0;
  -webkit-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

@-webkit-keyframes bounce {
  0% {
    top: 30px;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    -webkit-transform: scaleX(2);
    transform: scaleX(2);
  }
  35% {
    height: 16px;
    border-radius: 50%;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  100% {
    top: 0;
  }
}

@keyframes bounce {
  0% {
    top: 30px;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    -webkit-transform: scaleX(2);
    transform: scaleX(2);
  }
  35% {
    height: 16px;
    border-radius: 50%;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  100% {
    top: 0;
  }
}

.logo {
  position: relative;
  z-index: 299;
}

.logo__text {
  opacity: 1;
  background: rgba(155, 155, 155, 0.15);
  box-shadow: inset 0 1px 10px 0 rgba(0, 0, 0, 0.61);
  border-radius: 15px;
  position: absolute;
  left: 100px;
  top: 30px;
  width: 100px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 10px;
  font-weight: 600;
  padding: 15px 8px 15px 38px;
  z-index: 199;
}

.logo__image {
  z-index: 299;
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 15px;
  width: 200px;
}

@media screen and (max-width: 900px) {
  .logo__image {
    top: 24px;
    left: 16px;
    width: 180px;
  }
}

.unsupported {
  padding: 10px;
  border: 10px solid whitesmoke;
  max-width: 50%;
  margin: 150px auto;
  text-align: center;
}

@media screen and (max-width: 900px) {
  .unsupported a img {
    width: 50px;
  }
}

