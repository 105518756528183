.orientationIcon {
  display: inline-block;
  color: darkgrey;
  border: 2px solid transparent;
  width: 14px;
  height: 10px;
  margin-left: 8px;
  margin-right: 8px;
  vertical-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}

.orientationIcon::after,
.orientationIcon::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: currentColor;
}

.orientationIcon::after {
  border: 2px solid currentColor;
}

.orientationIcon::before {
  top: 2px;
  left: 2px;
  border: 2px solid currentColor;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  transform-origin: left;
}

.orientationIcon:hover {
  color: black;
}

.orientationIcon--horizontal::after,
.orientationIcon--vertical::before {
  color: #ffdb7a;
  z-index: 2;
}
