.paths {
  position: absolute;
  left: 320px;
  margin-top: 12px;
  color: #2a9da8;
  font-size: 14px;
  z-index: 2;
}

.paths a,
.paths a:visited {
  color: inherit;
}
.paths a:hover {
  color: #fff77d;
}

@media screen and (max-width: 1199px) {
  .paths {
    left: 240px;
    margin-top: 12px;
  }
}

@media screen and (max-width: 575px) {
  .paths {
    left: 20px;
    margin-top: 16px;
    display: none;
  }
}
