.thumb {
  width: 80px;
  height: 80px;
  float: left;
  background-size: 60%;
  background-position: center;
  background-repeat: no-repeat !important;
}

.thumb--yellow {
  background-color: #ffdb7a;
}

.thumb--purple {
  background-color: #a863a8;
}

.thumb--blue {
  background-color: #6f99e0;
}

.thumb--green {
  background-color: #4bbb69;
}

.thumb--brown {
  background-color: #b18b32;
}

.thumb--round {
  border-radius: 50%;
}

.thumb--text-circle {
  shape-outside: circle();
  -webkit-shape-outside: circle();
}

.thumb--stacked {
  background-image: url("../../assets/assets/images/api_thumb.svg");
}

.thumb--mobile {
  background-image: url("../../assets/assets/images/mobile-phone.png");
}
