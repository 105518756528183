.react-autosuggest__container {
  position: relative;
  color: black;
}

.react-autosuggest__input {
  padding: 10px 20px;
  border: 1px solid #aaa;
  border-radius: 4px;
  -webkit-appearance: none;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input::-ms-clear {
  display: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  border: 1px solid #aaa;
  background-color: #fff;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 5px 8px;
}

.react-autosuggest__suggestion div {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
}

.redoc-param-button-input {
  display: flex;
  position: relative;
}

.redoc-param-button-input div {
  width: 100%;
}
.redoc-param-button-input button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: none;
}
.redoc-param-button-input:hover button {
  display: block;
}

.react-autosuggest__suggestion--highlighted {
  background-color: rgba(0, 0, 0, 0.02);
}

.token-button {
  width: 96%;
  display: flex;
  justify-content: space-around;
  padding: 8px !important;
}
