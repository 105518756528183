.forum_rightSide .bracketButton {
  float: right;
}

.topicDropdowns {
  width: 100%;
  min-width: 280px;
}

.topicDropdowns .side_field {
  width: 30%;
  min-width: 240px;
  display: inline-block;
}

@media screen and (max-width: 767px) {
  .topicDropdowns .side_field {
    width: 100%;
    min-width: 240px;
  }
}
