.apiMethod {
  font-weight: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
}

ul.table {
  margin: 0;
}

.row {
}

.support_accord_title {
  /*display: flex;*/
  /*justify-content: space-between;*/
  /*text-align: center;*/
}

.cell {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
}

/*.cell .arr_line_1 a svg{*/
/*    padding-right: 5rem;*/
/*    }*/
