.app_credentials__form-button__copy {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-left: 15px;
}

.app_credentials__form-button__copy button {
  padding: 5px;
  border: none;
}
.app_credentials__form-button__copy svg {
  fill: white;
  width: 16px;
  height: auto;
}
