.cornersButton-wrapper:hover .cornersButton .btn,
.cornersButton-wrapper.enable .cornersButton .btn {
  background: #1c869d;
  color: #ffffff;
}

.cornersButton-wrapper:hover:before,
.cornersButton-wrapper.enable:before {
  content: "";
  position: absolute;
  border-top: 1px solid rgba(255, 255, 255, 0.7);
  border-left: 1px solid rgba(255, 255, 255, 0.7);
  top: -4px;
  left: -4px;
  width: 10px;
  height: 10px;
  z-index: 1;
}

.cornersButton .btn {
  position: relative;
  padding: 10px 130px;
  background: rgba(255, 255, 255, 0.22);
  border: none;
  /* outline-offset: 3px; */
  /* outline: 1px solid #4e8ba1; */
  margin: 10px 4px;
  font-family: "CF Asty Std";
  font-weight: 500;
  font-size: 14px;
  color: #015978;
  cursor: pointer;
}

.cornersButton .btn:after {
  content: "";
  position: absolute;
  border-top: 2px solid #015978;
  border-right: 2px solid #015978;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 18px;
  right: 30px;
  width: 7px;
  height: 7px;
  z-index: 1;
}

.cornersButton .btn {
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}

.cornersButton.left {
  float: left;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .cornersButton .btn:after {
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
  }
}
