.box-head {
  position: relative;
  margin-bottom: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #404040;
}

.box-head__left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.box-head__left .tag {
  margin-top: 0;
}

.box-head__right {
  margin-left: auto;
}

.box-head__action > * {
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  color: #006e80;
  -webkit-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.box-head__action > *:not(:first-child) {
  margin-left: 20px;
}

.box-head__action svg {
  width: 1em;
  margin-right: 6px;
}

.box-head__title {
  color: inherit;
  margin-right: 10px;
  display: inline-block;
  font-size: 40px;
}

@media screen and (max-width: 1270px) {
  .box-head__action > * {
    display: block;
    text-align: center;
  }

  .box-head__action > *:not(:first-child) {
    margin-left: 0;
    margin-top: 10px;
  }
}

/*--------------------------------*/

.box-head--action-left .box-head__right {
  margin-left: initial;
}

/*--------------------------------*/

.box-head--primary {
  padding: 10px 10px 10px 30px;
  color: black;
}

.box-head--primary .box-head__title {
}

@media screen and (max-width: 1270px) {
  .box-head--primary {
    padding: 0 8px;
    color: black;
  }
}

/*--------------------------------*/

.box-head--secondary {
  padding: 15px 20px;
  background-color: rgba(155, 155, 155, 0.1);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-bottom: 1px solid #b1bacc;
}

.box-head--secondary .box-head__title {
  color: inherit;
  font-size: 1.2em;
  font-weight: 700;
}

/*--------------------------------*/

.box-head--tertiary {
  border: 1px solid #b1bacc;
  margin: 40px 16px;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: rgba(155, 155, 155, 0.05);
}

.box-head--tertiary .box-head__title {
  font-size: 1.1em;
  font-weight: 400;
}

/*--------------------------------*/

.box-head--center {
  min-height: 40px;
}

.box-head--center .box-head__title {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/*--------------------------------*/

.box-head--bold .box-head__title {
  font-family: "Oswald";
  font-weight: 600;
  letter-spacing: -1px;
  margin: 0px 0px 10px 0px;
}
