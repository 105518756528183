.corWrapper {
  width: 100%;
  text-align: center;
}

.corButton {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin: 8px;
}

.corButton span {
  display: block;
  padding: 7px;
  font-size: 14px;
  color: #fff77d;
  font-weight: 500;
  text-transform: uppercase;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.corButton.in .corners {
  position: absolute;
  width: 100%;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
}

.corButton.in .corners.bottom {
  bottom: 14px;
}

.corButton.in .corners.bottom:before {
  /* border-width: 0 0 1px 1px; */
  border: none;
}

.corButton.in .corners:before,
.corButton.in .corners:after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-color: #fff77d;
  border-style: solid;
  -webkit-transition-duration: 0.1s;
  -o-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.corButton.in .corners.bottom:after {
  border-width: 0 2px 2px 0;
}

.corButton.in .corners:after {
  right: 0px;
}

.corButton.in:hover .corners.bottom {
  bottom: 12px;
}

@media only screen and (max-width: 480px) {
  .corButton span {
    font-size: 15px;
  }
}
