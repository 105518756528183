.block__form_element_label {
  display: block;
  /*font-size: 1.077rem;*/
  /*margin-bottom: 0.2em;*/
  /*padding-top: 5px;*/
  /*font-weight: 400;*/
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 8px;
}

.field_checkbox div:nth-child(1) {
  margin-top: 0.7rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 900px) {
  .block__form_element_label {
    /*padding-top: 16px;*/
  }

  .block__form_text_input {
    width: initial;
    margin-bottom: 8px;
  }
}
