.app_credentials {
  margin: 45px auto;
}

.app_credentials__form {
  /*width: 100%;*/
  /*padding: 0 20px 25px;*/
}

.app_credentials__form label,
.app_credentials__form input {
  margin-bottom: 8px;
  display: block;
}

.app_credentials__form input {
  /*max-width: 440px;*/
  /*margin-right: 25px;*/
  /*display: inline-block;*/
  /*margin-bottom: 10px;*/
}

.app_credentials__form #show {
  /*margin: 0;*/
  /*padding: 0;*/
  /*width: auto;*/
  /*-webkit-appearance: checkbox;*/
}

.app_credentials__form #secret {
  /*margin-right: 25px;*/
}

.app_credentials__form .show_span {
  /*color: #006E80;*/
  /*margin-right: 35px;*/
}

.body_field {
  width: 100%;
  /* border: 2px solid #aaa; */
  /* border-radius: 4px; */
  font-size: 12px;
  border: none;
  margin: 5px 0;
  outline: none;
  padding: 15px 40px 15px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  background: rgba(255, 255, 255, 0.13);
}

.body_field.tags-input {
  padding-top: 8px;
  padding-bottom: 8px;
}

.application-info__credentials {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.application-info__credentials > div {
  width: 100%;
  margin-bottom: 8px;
}

.application-info__credentials > div:not(:first-child) {
  margin-left: 8px;
}

.application-info__credentials > div input {
  margin-bottom: 8px;
}

.app_credentials__form .client_info {
  position: relative;
}

.app_credentials__form .app_credentials__form-button__copy {
  position: absolute;
  right: 4px;
  top: 8px;
}

@media screen and (max-width: 900px) {
  .application-info__credentials {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
