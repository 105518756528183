.start_steps {
  float: right;
  padding: 10px;
}

.start_step {
  float: left;
  position: relative;
  width: 31%;
  min-width: 288px;
  padding-bottom: 61%;
  border: 1px solid #5090ad;
  overflow: hidden;
}

.lines_bg {
  background-image: -webkit-linear-gradient(
    315deg,
    #ffffff00 44.44%,
    #5090ad36 44.44%,
    #5090ad36 50%,
    #ffffff00 50%,
    #ffffff00 94.44%,
    #5090ad36 94.44%,
    #5090ad36 100%
  );
  background-image: -o-linear-gradient(
    315deg,
    #ffffff00 44.44%,
    #5090ad36 44.44%,
    #5090ad36 50%,
    #ffffff00 50%,
    #ffffff00 94.44%,
    #5090ad36 94.44%,
    #5090ad36 100%
  );
  background-image: linear-gradient(
    135deg,
    #ffffff00 44.44%,
    #5090ad36 44.44%,
    #5090ad36 50%,
    #ffffff00 50%,
    #ffffff00 94.44%,
    #5090ad36 94.44%,
    #5090ad36 100%
  );
  background-size: 25.46px 25.46px;
}

.start_content {
  position: absolute;
  height: 90%;
  width: 90%;
  padding: 5%;
}

.start_table {
  display: table;
  width: 100%;
  height: 100%;
}

.start_table-cell {
  display: table-cell;
  vertical-align: middle;
}

.start_num {
  font-family: "Oswald";
  font-weight: 500;
  font-size: 160px;
  text-align: right;
  line-height: 1.2em;
}

.start_title h3 {
  text-align: right;
  text-transform: uppercase;
  font-family: "Oswald";
  font-weight: 500;
  font-size: 24px;
  /* margin: 8px; */
}

.start_descr {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  text-align: right;
  line-height: 1.5em;
}

.start_descr span p {
  height: 91px;
}

.fill_white_bg {
  background-color: #ffffff12;
}

.graphic-circuit-wrapper {
  width: 272px;
  position: absolute;
  top: -160px;
  left: -200px;
}

@media screen and (max-width: 1400px) {
  .start_step {
    min-width: 249px;
  }
}

@media screen and (max-width: 1199px) {
  .start_step {
    width: calc(50% - 2px);
  }
}

@media screen and (max-width: 991px) {
  .graphic-circuit-wrapper {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .start_step {
    padding-bottom: 120%;
    margin: 0px 0;
    width: 100%;
  }
}

@media screen and (max-width: 575px) {
  .start_title h3 {
    font-size: 36px;
    margin: 8px;
  }
}

@media screen and (max-width: 374px) {
  .start_title h3 {
    font-size: 24px;
    margin: 10px;
  }

  .start_num {
    font-size: 120px;
  }
}
