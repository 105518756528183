.step {
  margin-top: 25px;
  border-top: 2px solid #ffdb7a;
  padding: 30px 0 30px 0;
  position: relative;
}

.step::after {
  counter-increment: steps;
  content: counter(steps);
  text-align: center;
  color: white;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  position: absolute;
  background-color: #006e80;
  border: 4px solid #ffdb7a;
  font-weight: 900;
  font-size: 1.5em;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.step--bullet::after {
  content: "";
  width: 10px;
  height: 10px;
}

.step h3 {
  margin-top: 2px;
  font-weight: 900;
  color: #006e80;
}

.step p {
  margin-bottom: 30px;
  color: black;
}

.step a {
  text-decoration: none;
}

.step--auth::after {
  content: "Auth";
  font-size: 1rem;
}

@media screen and (max-width: 767px) {
  .timeline ul li .content:before {
    display: none;
  }
}
