.author.thumbnail {
  /* width: 100%; */ /* display: inline-block; */
  float: right;
  margin: 2px 0;
  padding: 0 8px;
  width: calc(100% - 16px);
}
.thumb_auth {
  float: right;
  padding: 2px;
  border: 1px solid rgba(255, 255, 255, 0.7);
}
.author.info {
  /* width: 100%; */
  float: right; /* margin: 4px 8px; */
  margin: 2px 0;
  padding: 0 8px;
  width: calc(100% - 16px);
}
.aName {
  /* float: right; */
  width: 100%; /* display: inline-block; */
  text-align: right;
  margin: 4px 0;
  font-size: 13px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
}
.aDate {
  /* float: right; */
  width: 100%; /* display: inline-block; */
  text-align: right; /* margin: 8px; */
  font-size: 10px;
  color: #fff77d;
}

.thumb_auth img {
  min-width: 28px;
  min-height: 28px;
  max-width: 28px;
  max-height: 28px;
}
