.popupMessage {
  position: fixed;
  z-index: 100001;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#015978),
    to(#005782)
  );
  background: -webkit-linear-gradient(left, #015978 0%, #005782 100%);
  background: -o-linear-gradient(left, #015978 0%, #005782 100%);
  background: linear-gradient(to right, #015978 0%, #005782 100%);
}

.popupMessage__content {
  width: 400px;
  max-width: 80vw;
  background: rgba(255, 255, 255, 0.13);
  padding: 16px;
  font-size: 13px;
  -webkit-box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.18);
}

.popupMessage__content__closeButton {
  width: 11px;
  height: 11px;
  position: absolute;
  cursor: pointer;
  right: 8px;
  top: 8px;
  fill: white;
}

.blurred-background {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
