.modal {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 800px;
  width: 100%;
  background-color: #005782;
  -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
  padding: 15px 25px 20px;
}

.modal:focus {
  outline: none;
}

.modal__overlay {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.modal__title {
  font-size: 1.3rem;
  font-weight: 500;
  color: white;
  font-family: "Oswald";
}

.modal__content {
  /*color: rgba(255, 255, 255, 0.8);*/
  color: rgba(255, 255, 255, 0.8);
  max-height: 800px;

}
.modal__header_container {
  position: absolute;
  top: 5px;
  right: 10px;
  display: flex;
  width: 50%;
  justify-content: flex-end;
}

.modal__input {
  width: 80%;
}
.modal__close {
  border: none;
  background: transparent;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal__close:focus,
.modal__close:hover,
.modal__close:active {
  border: none;
  outline: none;
}

@media screen and (max-height: 650px) {
  .modal {
    height: 100%;
    overflow-y: auto;
  }
}
