@import url("https://fonts.googleapis.com/css?family=Share+Tech+Mono");

.terminal-wrapper .moveBox__navbar {
  background: silver;
}

.terminal-wrapper .moveBox__expand {
  background: black;
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.terminal-wrapper .moveBox__window {
  top: 20%;
  left: 25%;
  height: 300px;
}

.terminal,
.terminal * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.terminal {
  font-family: monospace !important;
  font-size: 14px !important;

  background: black;
  color: white;
  padding: 16px 10px 4px;
  border-top: 1px solid lightgrey;
  min-height: 200px;
  /*max-height: 200px;*/
  overflow: auto;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.terminal__response {
  margin: 0;
  padding: 0;
}

.terminal__command {
  /*display: flex;*/
}

.terminal__command span {
  font-size: 1em;
}

.terminal-input {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.terminal-input__input {
  overflow: hidden;
  opacity: 0;
  height: 0;
  width: 0;
  display: inline;
}

.terminal__command-suggestions {
  -webkit-column-width: 160px;
  column-width: 160px;
}

.terminal__command-suggestions div {
  color: lightblue;
  padding: 4px;
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
}

.terminal-input__caret {
  background-color: transparent;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  border: 1px solid white;
  text-align: center;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  display: inline-block;
  vertical-align: bottom;
}

.terminal-input__caret:empty {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 8px;
  height: 16px;
}

.terminal-input--active .terminal-input__caret {
  background-color: white;
  color: black;
  border: none;
  -webkit-animation: terminal-blink 1s infinite steps(1, start);
  animation: terminal-blink 1s infinite steps(1, start);
}

.command-line {
  margin: 4px 0;
}

.command-line__command {
}

.command-line__response {
  margin: 8px 0;
}

.command-line--skipped {
  display: none;
}

.command-prefix {
  margin-right: 8px;
  color: lightgreen;
}

@-webkit-keyframes terminal-blink {
  0% {
    background-color: white;
    color: black;
  }
  50% {
    background-color: transparent;
    color: white;
  }
  100% {
    color: black;
    background-color: white;
  }
}

@keyframes terminal-blink {
  0% {
    background-color: white;
    color: black;
  }
  50% {
    background-color: transparent;
    color: white;
  }
  100% {
    color: black;
    background-color: white;
  }
}

.terminal ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(67, 127, 122, 0.3);
  border-radius: 10px;
  background: #f5f5f5;
}

.terminal ::-webkit-scrollbar {
  width: 8px;
  border-radius: 10px;
}

.terminal ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: lightgrey;
}
