.tryOutDescription {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: left;
  margin-left: auto;
  color: white;
  font-size: 0.9rem;
  width: 100%;
}

.tryOutDescription * {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

.tryOutDescription pre {
  margin: 0;
}

.tryOutDescription > div {
  padding: calc(0.5rem + 1px);
}

.tryOutDescription > div:hover {
  border: 1px dashed white;
  padding: 0.5rem;
}

.tryOutDescription > div:focus {
  padding: 0.5rem;
  outline: none;
  border: 1px solid white;
}

.tryOutDescription > div:empty::before {
  content: "Enter Description";
  /*content: "Description";*/
  z-index: -1;
  left: 2px;
  cursor: text;
}

/*.tryOutDescription > div:empty:focus::before,*/
/*.tryOutDescription > div:empty:hover::before {*/
/*}*/
