.subscribe-icon {
  color: #fff77d;
}

.subscribe-icon.subscribed {
  color: #81c14b;
}

.subscribe-icon.disabled {
  color: grey !important;
  cursor: not-allowed;
}
