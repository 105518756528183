.tryout-form * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.tryout-form {
  padding: 10px;
}

.tryout-form .moveBox__expand {
  background-color: #81c14b;
}

.tryout-form .moveBox__content {
  background-color: #1c869d;
}

.tryout-form .moveBox__expand svg {
  fill: white;
}

.tryout-form--response .moveBox__expand {
  background-color: #8f2d56;
}

.tryout-form--response .moveBox__expand svg {
  fill: white;
}

.tryout-form table {
  width: 100%;
  table-layout: fixed;
}

.tryout-form .param-required {
  position: relative;
  font-size: 14px;
}

.tryout-form .param-required::after {
  content: "*";
  color: #fff77d;
}

.tryout-form .param-required::before {
  content: "required";
  opacity: 0;
  top: 50%;
  position: absolute;
  left: -20px;
  color: #e53935;
  -webkit-transform: translate(-100%, -50%) scale(0.2);
  -ms-transform: translate(-100%, -50%) scale(0.2);
  transform: translate(-100%, -50%) scale(0.2);
  background-color: rgba(229, 57, 53, 0.1);
  -webkit-transition: opacity 0.2s, -webkit-transform 0.1s ease-out;
  transition: opacity 0.2s, -webkit-transform 0.1s ease-out;
  -o-transition: opacity 0.2s, transform 0.1s ease-out;
  transition: opacity 0.2s, transform 0.1s ease-out;
  transition: opacity 0.2s, transform 0.1s ease-out,
    -webkit-transform 0.1s ease-out;
}

.tryout-form .param-required:hover::before {
  opacity: 1;
  border-radius: 10px;
  padding: 1px 6px;
  -webkit-transform: translate(-100%, -50%) scale(1);
  -ms-transform: translate(-100%, -50%) scale(1);
  transform: translate(-100%, -50%) scale(1);
}

.tryout-form__top {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding: 10px 20px;
  border-radius: 4px 4px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
  overflow-x: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  -webkit-transition: border-color 0.25s ease;
  -o-transition: border-color 0.25s ease;
  transition: border-color 0.25s ease;
  border-bottom: 1px solid rgba(255, 255, 255, 0.22);
}

.tryout-form__top:hover {
  -o-text-overflow: initial;
  text-overflow: initial;
  word-break: break-all;
  white-space: initial;
}

.tryout-form form {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 10px 20px;
  font-family: inherit;
  font-size: inherit;
}

.tryout-form .cancel-request {
  margin-left: auto;
  margin-right: 20px;
  outline-color: lightgray !important;
}

.tryout-form .cancel-request:before,
.tryout-form .cancel-request:after {
  background: lightgray;
}

/**************************************************************/

.tryout-form--horizontal form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.tryout-form--horizontal form > * {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  min-width: 300px;
}

.tryout-form--horizontal form > table {
  height: 100% !important;
}

.tryout-form--horizontal .moveBox__content {
  overflow: initial !important;
}

.tryout-form--horizontal .tryout-form__content {
  overflow: auto;
}

/**************************************************************/

.tryout-form form thead {
}

.tryout-form form thead th {
  /*border-bottom: 1px solid rgba(255, 255, 255, 0.22);*/
  text-align: left;
  text-transform: uppercase;
  line-height: 20px;
  font-size: 90%;
  font-weight: 300;
  color: white;
  padding: 10px 0;
}

.tryout-form form body th {
  color: #263238;
  text-align: left;
  padding-top: 10px;
  line-height: 1;
  font-weight: 700;
  font-size: 0.9rem;
}

.tryout-form form tbody td {
  /*vertical-align: bottom;*/
  padding-top: 8px;
}

.tryout-form form input[type="text"],
.tryout-form form textarea {
  width: 100%;
  /* border: 2px solid #aaa; */
  /* border-radius: 4px; */
  font-size: 12px;
  border: none;
  /* margin: 8px 0; */
  outline: none;
  padding: 10px 40px 10px 12px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  background: rgba(255, 255, 255, 0.13);
}
.tryout-form.response textarea {
  color: white;
}

.tryout-form .form-params {
  padding: 10px;
}

.tryout-form .has-error {
  /*background-color: rgba(229, 57, 53, 0.1) !important;*/
  border: 1px solid #e53935 !important;
  /*outline: none !important;*/
}

.try-out {
  border-radius: 3px;
  color: #006e80;
  font-family: inherit;
  text-transform: uppercase;
  margin: 10px 0 !important;
}

.parameters-container label {
  font-size: 14px;
  color: white;
}

.tryout-form .moveBox__window {
  top: 10%;
  left: 22%;
}

.tryout-form--edit .moveBox__window {
  top: 12%;
  left: 24%;
}

.tryout-form .moveBox__actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.tryout-form .moveBox__actions .simple_btn button {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 7px 30px;
}

.tryout-form--response .moveBox__window {
  top: 10%;
  left: 52%;
}

.tryout-form--response .moveBox__content {
  /*background: black;*/
}

/**************************************************************/

.tryout-form .tryOutDescription {
  padding: 1rem 1rem 0 1rem;

  /*width: 100%;*/
}

.tryout-form .tryOutDescription > div:focus {
  background: transparent;
}

/**************************************************************/

.tryout-form__show-history {
  margin: 5px 10px 5px auto;
  border-radius: 5px;
  background: #006e80;
  border: none;
  cursor: pointer;
  padding: 3px 10px;
  display: inline-block;
  line-height: 20px;
  font-size: 0.929em;
  color: white;
}

.tryout-form__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}

/**************************************************************/

.tryout-form__btn {
  margin: 0.5rem 0;
  margin-right: 0.5rem;
  /*border-radius: 3px;*/
  border: none;
  cursor: pointer;
  padding: 0.4rem 0.8rem;
  /*padding: 3px 10px;*/
  display: inline-block;
  /*line-height: 20px;*/
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.tryout-form__btn--send {
  background: #81c14b;
  color: white;
}

.tryout-form__btn--move {
  color: white;
  background: #024a6c;
}
.tryout-form__btn--move:hover {
  color: white;
  background: #1c869d;
}

.tryout-form__btn--move-prev,
.tryout-form__btn--move-next {
  margin-left: auto;
}

.tryout-form__btn--move-prev + .tryout-form__btn--move-next {
  margin-left: initial;
}

/**************************************************************/
.tryout-form__pin {
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
}

.tryout-form__orientation {
}

.tryout-form__pin--pinned {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.tryout-form__pin svg {
  fill: #e53935 !important;
}

.tryout-form__pin--pinned svg {
  fill: #00bcd4 !important;
}

.tryout-form__pin svg {
  height: 16px;
  width: 10px;
  fill: white;
}

@media screen and (max-width: 1024px) {
  .tryout-form .moveBox__window {
    top: 10%;
    left: 2%;
  }

  .tryout-form--response .moveBox__window {
    top: 10%;
    left: 52%;
  }
}

@media screen and (max-width: 900px) {
  .tryout-form .moveBox__window {
    top: 10%;
    left: 5%;
    padding: 10px;
  }

  .tryout-form--response .moveBox__window {
    top: 50%;
    left: 5%;
  }
}

.redoc_containerInner_noHeight {
  height: 100%;
  /*margin-bottom: 40px!important;*/
}

.redoc_containerInner_noHeight + hr {
  /*margin-top: 4px!important;*/
  box-sizing: border-box !important;
  /*margin-bottom: 40px!important;*/
}
