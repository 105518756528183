.pagePreloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 100000000001;
}

.pagePreloader__wrap {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.pagePreloader__logo img {
  width: 357px;
  position: initial;
}

@media screen and (max-width: 900px) {
  .pagePreloader__logo img {
    width: 200px;
  }
}

.pagePreloader__text {
  color: white;
  display: inline-block;
  margin-left: 16px;
}

.pagePreloader__bounceball {
  position: relative;
  display: inline-block;
  height: 37px;
  width: 16px;
}

.pagePreloader__bounceball::before {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: white;
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: bounce 500ms alternate infinite ease;
  animation: bounce 500ms alternate infinite ease;
}

.pagePreloader__bounceball::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 16px;
  height: 16px;
  /*background-image: -webkit-gradient(linear, left top, right top, color-stop(-40%, #020809), to(#437F7A));*/
  /*background-image: -webkit-linear-gradient(left, #020809 -40%, #437F7A 100%);*/
  /*background-image: -o-linear-gradient(left, #020809 -40%, #437F7A 100%);*/
  /*background-image: linear-gradient(-270deg, #020809 -40%, #437F7A 100%);*/
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(28%, #005782),
    to(#1c869d)
  );
  background-image: -webkit-linear-gradient(left, #005782 28%, #1c869d 100%);
  background-image: -o-linear-gradient(left, #005782 28%, #1c869d 100%);
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#015978),
    color-stop(28%, #005782),
    to(#1c869d)
  );
  background-image: -webkit-linear-gradient(
    left,
    #015978 0%,
    #005782 28%,
    #1c869d 100%
  );
  background-image: -o-linear-gradient(
    left,
    #015978 0%,
    #005782 28%,
    #1c869d 100%
  );
  background-image: linear-gradient(
    -270deg,
    #015978 0%,
    #005782 28%,
    #1c869d 100%
  );
  -webkit-transform: scale(250) translateZ(0);
  transform: scale(250) translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  z-index: -1;
}

.pagePreloader--complete {
  border-radius: 50%;
  -webkit-transition: opacity 0.4s ease-in 0.8s, height 0.1s linear 1.2s,
    width 0.1s linear 1.2s;
  -o-transition: opacity 0.4s ease-in 0.8s, height 0.1s linear 1.2s,
    width 0.1s linear 1.2s;
  transition: opacity 0.4s ease-in 0.8s, height 0.1s linear 1.2s,
    width 0.1s linear 1.2s;
  opacity: 0;

  width: 0;
  height: 0;
}

.pagePreloader--complete .pagePreloader__bounceball {
  -webkit-transform: scale(0.01);
  -ms-transform: scale(0.01);
  transform: scale(0.01);
  -webkit-transition: -webkit-transform 0.1s ease-in 0.8s;
  transition: -webkit-transform 0.1s ease-in 0.8s;
  -o-transition: transform 0.1s ease-in 0.8s;
  transition: transform 0.1s ease-in 0.8s;
  transition: transform 0.1s ease-in 0.8s, -webkit-transform 0.1s ease-in 0.8s;
  -webkit-transform-origin: 8px 8px;
  -ms-transform-origin: 8px 8px;
  transform-origin: 8px 8px;
}

.pagePreloader--complete .pagePreloader__bounceball::after {
  -webkit-transition: -webkit-transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
  transition: -webkit-transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
  -o-transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1),
    -webkit-transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transform: scale(1) translateZ(0);
  transform: scale(1) translateZ(0);
  background: #437f7a;

  border-radius: 50%;
}

.pagePreloader--complete .pagePreloader__bounceball::before {
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;

  background-color: #437f7a;
}

.pagePreloader--complete .pagePreloader__text,
.pagePreloader--complete .pagePreloader__logo {
  opacity: 0;
  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

@-webkit-keyframes bounce {
  0% {
    top: 30px;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    -webkit-transform: scaleX(2);
    transform: scaleX(2);
  }
  35% {
    height: 16px;
    border-radius: 50%;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  100% {
    top: 0;
  }
}

@keyframes bounce {
  0% {
    top: 30px;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    -webkit-transform: scaleX(2);
    transform: scaleX(2);
  }
  35% {
    height: 16px;
    border-radius: 50%;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  100% {
    top: 0;
  }
}
