.news {
  margin: 10px 0;
}

.filtersButton-wrapper {
  position: relative;
  float: left;
  margin: 6px 0;
  width: 100%;
}

.article a {
  color: #fff77d !important;
}

.theFiltersBtn {
  position: relative;
  background: rgba(255, 255, 255, 0);
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  font-family: "Oswald", serif;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  margin: 0;
  outline: 0;
  padding: 20px 60px 20px 0;
  width: 100%;
  text-align: left;
}

.filterBlogBorder {
  position: relative;
  background: rgba(255, 255, 255, 0);
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  font-family: "Oswald", serif;
  font-weight: 500;
  color: #ffffff;
  outline: 0;
  width: 100%;
  margin-top: 25px;
}

@media screen and (max-width: 991px) {
  #custom_mobile_position_link_2 {
    text-align: right;
    position: relative;
  }
}

@media screen and (max-width: 767px) {
  #filter_btn {
    display: block;
  }
}

@media screen and (max-width: 480px) {
  #custom_mobile_position_link_2 {
    text-align: right;
  }
}
