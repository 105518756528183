.padding_container {
  color: gray;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  padding: 20px 0px 20px 20px;
}
.padding_container h5 {
  text-indent: 5px;
  margin-bottom: 10px;
  font-weight: 900;
}
.padding_container .endpoint {
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #b1bacc;
}
.padding_container .btn--production {
  float: right;
  padding: 7px 10px;
  background-color: #ffdb7a;
  margin-left: 20px;
  border-radius: 10px;
  color: black;
  -webkit-transform: translateY(-7px);
  -ms-transform: translateY(-7px);
  transform: translateY(-7px);
}
.padding_container .btn--development {
  float: right;
  padding: 7px 10px;
  background-color: #006e80;
  margin-left: 10px;
  border-radius: 10px;
  color: white;
  -webkit-transform: translateY(-7px);
  -ms-transform: translateY(-7px);
  transform: translateY(-7px);
}
.padding_container h4 {
  padding: 10px;
  line-height: 20px;
  height: 20px;
  margin-top: 20px;
  border: none;
}

.user_edit .padding_container {
  padding: 20px;
}
.user_edit .padding_container h4 {
  display: inline-block;
  margin: 0;
  padding: 0;
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid #b1bacc;
}
