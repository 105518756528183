.banner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: white;
  padding: 1rem 18%;
  margin: 4rem 10px 1rem;
}

.banner__image {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 20%;
  flex: 0 1 20%;
}

.banner__image img {
  max-width: 100%;
  height: auto;
}

.banner__content {
  color: #4d4e50;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin-left: 10%;
}

.banner h3 {
  font-size: 1.3rem;
  font-weight: 900;
}

.banner a {
  vertical-align: middle;
  display: inline-block;
}

@media screen and (max-width: 900px) {
  .banner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 2rem 5%;
  }
}
