@font-face {
  font-family: "icomoon";
  src: url("./fonts/icomoon/icomoon.eot?gwisd");
  src: url("./fonts/icomoon/icomoon.eot?gwisd#iefix")
      format("embedded-opentype"),
    url("./fonts/icomoon/icomoon.ttf?gwisd") format("truetype"),
    url("./fonts/icomoon/icomoon.woff?gwisd") format("woff"),
    url("./fonts/icomoon/icomoon.svg?gwisd#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icon_open:before {
  content: "\e937";
}
.icon-btn_captcha:before {
  content: "\e904";
  color: #024a6c;
}
.icon-icon_technologies_01:before {
  content: "\e91d";
  color: #fff77d;
}
.icon-icon_technologies_02:before {
  content: "\e91e";
  color: #fff77d;
}
.icon-icon_technologies_03:before {
  content: "\e91f";
  color: #fff77d;
}
.icon-icon_technologies_04:before {
  content: "\e920";
  color: #fff77d;
}
.icon-icon_technologies_05:before {
  content: "\e921";
  color: #fff77d;
}
.icon-icon_technologies_06:before {
  content: "\e922";
  color: #fff77d;
}
.icon-arrow_classic_down:before {
  content: "\e923";
}
.icon-arrow_classic_left:before {
  content: "\e924";
}
.icon-arrow_classic_right:before {
  content: "\e925";
}
.icon-arrow_classic_up:before {
  content: "\e926";
}
.icon-arrow_down:before {
  content: "\e927";
}
.icon-arrow_left:before {
  content: "\e928";
}
.icon-arrow_right:before {
  content: "\e929";
}
.icon-arrow_up:before {
  content: "\e92a";
}
.icon-btn_dislike:before {
  content: "\e92b";
}
.icon-btn_like:before {
  content: "\e92c";
}
.icon-icon_chat:before {
  content: "\e92d";
  color: #fff77d;
}
.icon-icon_folder:before {
  content: "\e92e";
  color: #fff77d;
}
.icon-profile_account:before {
  content: "\e92f";
}
.icon-profile_settings:before {
  content: "\e930";
}
.icon-profile_signout:before {
  content: "\e931";
}
.icon-search:before {
  content: "\e932";
}
.icon-social_fb:before {
  content: "\e933";
}
.icon-social_in:before {
  content: "\e934";
}
.icon-social_tw:before {
  content: "\e935";
}
.icon-social_yt:before {
  content: "\e936";
}

@font-face {
  font-family: "icomoon";
  src: url("./fonts/icon_filter/icomoon.eot?ft4tur");
  src: url("./fonts/icon_filter/icomoon.eot?ft4tur#iefix")
      format("embedded-opentype"),
    url("./fonts/icon_filter/icomoon.ttf?ft4tur") format("truetype"),
    url("./fonts/icon_filter/icomoon.woff?ft4tur") format("woff"),
    url("./fonts/icon_filter/icomoon.svg?ft4tur#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icon_filter:before {
  content: "\e900";
  color: #fff77d;
}

.icon-icon_filter:before {
  content: "\e900";
  color: #fff77d;
}
