.checkbox * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.checkbox__label {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  margin-bottom: 0;
}

.checkbox__input {
  display: none;
}

.checkbox__input:checked {
}

.checkbox__input:checked + .checkbox__icon {
  border-color: #fff77d;
}

.checkbox__input:checked + .checkbox__icon svg {
}

.checkbox__input:checked + .checkbox__icon svg path {
  fill: #fff77d;
}

.checkbox__input:checked + .checkbox__icon svg polyline {
  stroke-dashoffset: 0;
}

.checkbox__label:hover .checkbox__icon svg path {
  stroke-dashoffset: 0;
}

.checkbox__icon {
  position: relative;
  top: 2px;
  float: left;
  margin-right: 8px;
  width: 20px;
  height: 20px;
  border: 2px solid #5090ad;
  border-radius: 3px;
}

.checkbox__icon svg {
  position: absolute;
  top: -2px;
  left: -2px;
}

.checkbox__icon svg path {
  fill: none;
  stroke: #fff77d;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 71px;
  stroke-dashoffset: 71px;
  -webkit-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
}

.checkbox__icon svg polyline {
  fill: none;
  stroke: #024a6c;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 18px;
  stroke-dashoffset: 18px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.checkbox__label > span {
  color: white;
  /*pointer-events: none;*/
  vertical-align: sub;
  cursor: pointer;
}
