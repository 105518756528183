.search_field,
.side_field {
  width: 100%;
  font-size: 14px;
  margin: 6px 6px 6px 0px;
}

@media screen and (max-width: 991px) {
  #custom_mobile_position_link {
    text-align: right;
    position: relative;
    margin-top: -35px;
  }
}

@media screen and (max-width: 480px) {
  #custom_mobile_position_link {
    text-align: right;
    margin-top: -35px;
  }
}
