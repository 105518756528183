.snake-game {
}

.snake-game__modal {
  padding: 0;
  border: none;
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.snake-game__modal .modal__close {
  color: white;
  top: 0;
  border-radius: 50%;
  padding: 0;
}

.snake-game canvas {
  /*border-top: 1px solid whitesmoke;*/
  width: 100%;
}

.snake-game__head {
  /*padding-bottom: 4px;*/
  /*border-bottom: 5px solid lightgrey;*/
}
.snake-game__head > div {
  padding-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.snake-game__head {
  /*position: absolute;*/
  background: black;
  top: 0;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  vertical-align: middle;
}

.snake-game__score {
  color: lime;
  font-size: 25px;
  padding: 10px;
}

.snake-game__over {
  opacity: 0;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.snake-game button[disabled] {
  cursor: not-allowed;
}

.snake-game__over button {
  color: white;
  font-weight: bold;
  font-size: 20px;
}

.snake-game--end .snake-game__over {
  opacity: 1;
}

.snake-game button {
  background: none;
  cursor: pointer;
  border: none;
}

.snake-game button:hover,
.snake-game button:focus {
  outline: none;
}

.o-play-btn {
  position: relative;
  background: transparent;
  border: none;
  outline: none;
}
.o-play-btn__icon {
  height: 30px;
  width: 30px;
  line-height: 30px;
  position: relative;
  z-index: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
}
.o-play-btn__icon:before,
.o-play-btn__icon:after {
  content: "";
  position: absolute;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  background: #fff;
  height: 100%;
  width: 50%;
  top: 0;
}

.o-play-btn:hover .o-play-btn__icon:before,
.o-play-btn:hover .o-play-btn__icon:after {
  background: orangered;
}

.o-play-btn__icon:before {
  left: 0;
}
.o-play-btn__icon:after {
  right: 0;
}
.o-play-btn__mask {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
}
.o-play-btn__mask:before,
.o-play-btn__mask:after {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 150%;
  background: black;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.o-play-btn__mask:before {
  top: -100%;
  -webkit-transform-origin: 0% 100%;
  -ms-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-transform: rotate(26.5deg);
  -ms-transform: rotate(26.5deg);
  transform: rotate(26.5deg);
}
.o-play-btn__mask:after {
  -webkit-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
  -webkit-transform: rotate(-26.5deg);
  -ms-transform: rotate(-26.5deg);
  transform: rotate(-26.5deg);
  top: 100%;
}
.o-play-btn--playing .o-play-btn__icon:before {
  -webkit-transform: translateX(-25%);
  -ms-transform: translateX(-25%);
  transform: translateX(-25%);
}
.o-play-btn--playing .o-play-btn__icon:after {
  -webkit-transform: translateX(25%);
  -ms-transform: translateX(25%);
  transform: translateX(25%);
}
.o-play-btn--playing .o-play-btn__mask:before,
.o-play-btn--playing .o-play-btn__mask:after {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}
