.forum .sidebar.right .array_descr {
  margin: 8px 0;
  padding: 0 16px;
  float: none;

}

.forum_post {
  font-family: "CF Asty Std";
  font-weight: 900;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.13);
  -webkit-font-smoothing: antialiased;
  width: 100%;
  position: relative;
}

.forum .bracketButton {
  margin: 17px 0;
  padding-right: 16px;
  float: right;
}

.forum_post:after {
  content: "";
  position: absolute;
  border-left: 20px solid transparent;
  border-top: 20px solid rgba(255, 255, 255, 0.22);
  width: 0px;
  height: 0px;
  right: 0px;
  top: 0px;
}

.title_table {
  display: table;
  width: 100%;
}

.title_cell {
  display: table-cell;
  padding: 20px;
  vertical-align: middle;
}

.post_title {
}

.title_right_side {
  font-weight: 100;
}

.post_user {
  text-align: right;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
}

.post_date {
  text-align: right;
  font-size: 10px;
  color: #fff77d;
  font-weight: 300;
}

.forum_txt {
  font-size: 13px;
  padding: 20px 20px;
  background: #ffffff08;
  /* margin: 0; */
  border-bottom: 6px solid rgba(255, 255, 255, 0.22);
}

.forum_tags span {
  font-family: "Oswald";
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
}

.forum_tags span.hastag {
  color: #fff77d;
  margin: 0 10px;
}

.comments {
}

.comment {
}

.main_comment {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 20px 0;
}

.comment_details {
  display: inline-block;
  float: left;
  width: 140px;
  padding: 0 10px;
}

.comment_author {
  text-align: right;
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  font-weight: 500;
}

.comment_date {
  text-align: right;
  font-size: 12px;
  color: #fff77d;
}

.comment_body {
  display: inline-block;
  float: left;
  width: calc(100% - 180px);
  padding: 0 10px;
}

.comment_title_likes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.comment_title {
  width: calc(100% - 200px);
  padding: 0;
  display: inline-block;
  float: left;
  font-weight: 500;
  font-size: 15px;
}

.comment_likes {
  width: 200px;
  padding: 0;
  display: inline-block;
  float: left;
}

.likes,
.dislikes {
  display: inline-block;
  float: left;
  margin: 0 10px;
  font-size: 18px;
}

.likesNum {
  font-size: 12px;
  padding: 4px;
}

.icon-btn_dislike:before {
  color: #0f5569;
  cursor: pointer;
}

.icon-btn_like:before {
  color: #33b3bf;
  cursor: pointer;
}

.icon-btn_dislike:hover:before {
  color: #fff77d;
}

.icon-btn_like:hover:before {
  color: #fff77d;
}

.comment_txt {
  color: rgba(255, 255, 255, 0.7);
}

.comment_txt a {
  color: #fff77d;
}

.sub_comment {
  /*display: flex;*/
  padding: 10px 0;
}

.sub_comment .comment_details {
  display: inline-block;
  float: left;
  width: 180px;
  padding: 0 10px;
}

.comment_author {
}

.sub_comment .comment_date {
  color: #33b3bf;
}

.sub_comment .comment_body {
  display: inline-block;
  float: left;
  width: calc(100% - 220px);
  padding: 0 10px;
}

.comment_txt span {
}

.post_comment {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100px;
  flex: 0 0 100px;
  border: 0;
  font-size: 16px;
  background: #2564c9;
  color: #fff;
  position: relative;
}

.sub_comment_form {
  display: inline-block;
  float: right;
  width: calc(100% - 220px);
  padding: 0 10px;
}

.sub_comment_form form {
  position: relative;
}

.sub_comment_form input.body_field {
  padding: 15px 120px 15px 20px;
  background: rgba(0, 0, 0, 0.13);
}

.sub_comment_form input.post_comment {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100px;
  flex: 0 0 100px;
  border: 0;
  font-size: 14px;
  background: none;
  color: #fff77d;
  -webkit-text-fill-color: #fff77d !important;
  top: 0;
  right: 0;
  padding: 12px;
  position: relative;
  position: absolute;
  font-family: "Oswald";
  cursor: pointer;
}

.leave_comment {
  width: calc(100% - 20px);
  padding: 20px 10px;
  display: inline-block;
}

.leave_comment label {
  font-weight: 500;
}

/*Small devices (landscape phones, less than 768px)*/
@media screen and (max-width: 767px) {
  .title_table {
    display: table;
    width: 100%;
  }

  .title_cell {
    display: inline-block;
    float: left;
    padding: 10px;
    width: calc(100% - 20px);
    font-size: 15px;
    font-weight: 500;
  }

  .post_user,
  .post_date {
    text-align: left;
    display: inline-block;
    font-size: 14px;
    margin-right: 7px;
  }

  .main_comment {
    display: inline-block;
    padding: 20px 0 10px 0;
  }

  .comment_details {
    display: inline-block;
    float: left;
    width: 100%;
    padding: 0;
  }

  .comment_author,
  .comment_date {
    text-align: left;
    display: inline-block;
    margin-right: 7px;
  }

  .comment_body {
    display: inline-block;
    float: left;
    width: 100%;
    padding: 0;
  }

  .comment_title {
    width: calc(100% - 40px);
    padding: 10px 0;
  }

  .sub_comment {
    display: inline-block;
  }

  .sub_comment .comment_details {
    display: inline-block;
    float: left;
    width: calc(100% - 40px);
    padding: 0 0 0 40px;
    margin: 4px 0;
  }

  .sub_comment .comment_body {
    display: inline-block;
    float: left;
    width: calc(100% - 40px);
    padding: 0 0 0 40px;
    margin: 4px 0;
  }

  .sub_comment_form {
    display: inline-block;
    float: right;
    width: calc(100% - 40px);
    padding: 0 0 0 40px;
  }

  .leave_comment {
    width: 100%;
    padding: 20px 0px;
    display: inline-block;
  }
}

.forum-topic--comment-answer {
  padding-left: 10px;
  font-size: 13px;
}
