.block__forum_table {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: black;
  text-align: left;
  margin-left: 40px;
  margin-top: 20px;
  position: relative;
  z-index: 50;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.26);
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.26);
  overflow: hidden;
  margin: 25px auto;
  margin-bottom: 65px;
  width: 95%;
  background-color: rgba(255, 255, 255, 0.7);
}
.block__forum_table th,
.block__forum_table td {
  padding: 14px 24px;
}
.block__forum_table a {
  color: #006e80;
}
.block__forum_table thead th {
  background-color: #006e80;
  font-weight: 900;
  color: white;
}
.block__forum_table thead th:nth-child(1) {
  width: 10%;
}
.block__forum_table thead th:nth-child(2) {
  width: 45%;
}
.block__forum_table thead th:nth-child(3) {
  width: 15%;
  text-align: center;
}
.block__forum_table thead th:nth-child(4) {
  width: 15%;
  text-align: center;
}
.block__forum_table thead th:nth-child(5) {
  width: 15%;
  text-align: center;
}
.block__forum_table tbody td:nth-child(3) {
  text-align: center;
}
.block__forum_table tbody td:nth-child(4) {
  text-align: center;
}
.block__forum_table tbody td:nth-child(5) {
  text-align: center;
}
.block__forum_table tbody tr:nth-child(odd) {
  background-color: rgba(184, 184, 184, 0.1);
}
.block__forum_table .folder_img {
  width: 60px;
}
.block__forum_table .subscribe {
  padding: 2px 10px;
  border: 1px solid #b1bacc;
  background: rgba(155, 155, 155, 0.1);
  border-radius: 4px;
  font-size: 0.8rem;
  display: inline-block;
  float: right;
}
.block__plans a {
  text-decoration: none;
}
.block__plans i {
  float: right;
}
.block__elem_plan_name {
  width: 60% !important;
}
.block__elem_plan_sub {
  width: 40%;
  text-align: center;
}
.block__plans tbody tr td {
  text-align: center;
}
