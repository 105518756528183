.overlayLoader {
    height: calc(100% + 10px);
    width: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
    z-index: 2;
    text-shadow: white 0 0 1px;
}

.overlayLoader-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
