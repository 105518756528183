.search {
  position: absolute;
  /* position: relative; */
  /* margin-top: 96px; */
  /* left: 50%; */
  /* background: red; */
  /* -webkit-transform: translateX(-100%) translateY(-50%); */
  /* transform: translateX(-100%) translateY(-50%); */
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.search * {
  outline: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.search__wrapper {
  position: relative;
}

.search__field {
  width: 0;
  height: 0;
  color: #fff;
  background: #8bb8ca00;
  font-family: Lato, sans-serif;
  /* font-size: 1.35em !important; */
  padding: 30px 20px 30px 20px;
  /* border: 1px solid transparent; */
  /* border-radius: 0; */
  border: none;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.search__field:focus {
  border-bottom-color: #fff0;
  width: 60vw;
  color: #ffffff;
  cursor: default;
  /*   background: #0088a0; */
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1d6d94+0,318fa5+100 */
  background: #1d6d94; /* Old browsers */ /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #1d6d94 0%,
    #318fa5 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#1d6d94),
    to(#318fa5)
  );
  background: -o-linear-gradient(left, #1d6d94 0%, #318fa5 100%);
  background: linear-gradient(
    to right,
    #1d6d94 0%,
    #318fa5 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1d6d94', endColorstr='#318fa5',GradientType=1 ); /* IE6-9 */
}

.search__field:focus .search__icon {
  background-color: transparent;
  cursor: pointer;
  pointer-events: auto;
}

.search__field::-webkit-input-placeholder {
  position: relative;
  top: 0;
  left: 0;
  -webkit-transition-property: top, color;
  -o-transition-property: top, color;
  transition-property: top, color;
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
}
.search__field:-moz-placeholder {
  position: relative;
  top: 0;
  left: 0;
  -webkit-transition-property: top, color;
  -o-transition-property: top, color;
  transition-property: top, color;
  transition-duration: 0.1s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
}
.search__field::-moz-placeholder {
  position: relative;
  top: 0;
  left: 0;
  -webkit-transition-property: top, color;
  -o-transition-property: top, color;
  transition-property: top, color;
  transition-duration: 0.1s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
}
.search__field:-ms-input-placeholder {
  position: relative;
  top: 0;
  left: 0;
  -webkit-transition-property: top, color;
  -o-transition-property: top, color;
  transition-property: top, color;
  transition-duration: 0.1s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
}
.search__field::-webkit-input-placeholder[style*="hidden"] {
  color: #83b0c1;
  font-size: 0.65em;
  font-weight: normal;
  top: -20px;
  opacity: 1;
  visibility: visible !important;
}
.search__field:-moz-placeholder[style*="hidden"] {
  color: #83b0c1;
  font-size: 0.65em;
  font-weight: normal;
  top: -20px;
  opacity: 1;
  visibility: visible !important;
}
.search__field::-moz-placeholder[style*="hidden"] {
  color: #83b0c1;
  font-size: 0.65em;
  font-weight: normal;
  top: -20px;
  opacity: 1;
  visibility: visible !important;
}
.search__field:-ms-input-placeholder[style*="hidden"] {
  color: #83b0c1;
  font-size: 0.65em;
  font-weight: normal;
  top: -20px;
  opacity: 1;
  visibility: visible !important;
}
