.application-info__credentials-clientSecret {
  height: 100px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.13);
  border: 1px solid rgba(255, 255, 255, 0.22);
  padding: 10px;
  padding-bottom: 30px;
  /*animation: border-dance 5s infinite linear;*/
}

@keyframes border-dance {
  0% {
    background: linear-gradient(90deg, blue 50%, transparent 50%),
      linear-gradient(90deg, blue 50%, transparent 50%),
      linear-gradient(0deg, blue 50%, transparent 50%),
      linear-gradient(0deg, blue 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 15px 4px, 15px 4px, 4px 15px, 4px 15px;
    background-position: 0px 0px, 100% 116px, 0px 116px, 100% 0px;
    background-position: 0px 0px, 100% 116px, 0px 150px, 100% 0px;
  }
  100% {
    background: linear-gradient(90deg, blue 50%, transparent 50%),
      linear-gradient(90deg, blue 50%, transparent 50%),
      linear-gradient(0deg, blue 50%, transparent 50%),
      linear-gradient(0deg, blue 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 15px 4px, 15px 4px, 4px 15px, 4px 15px;
    background-position: 0px 0px, 100% 116px, 0px 116px, 100% 0px;
    background-position: 100% 0px, 0px 116px, 0px 0px, 100% 150px;
  }
}

/*.status-message--application * {*/
/*    box-sizing: inherit;*/
/*}*/

/*.status-message--application input[type="text"] {*/
/*    -webkit-transition: border-color 0.7s ease;*/
/*    -o-transition: border-color 0.7s ease;*/
/*    transition: border-color 0.7s ease;*/
/*    outline: none;*/
/*    border: 1px solid #dddddd;*/
/*    padding: .5rem 1rem;*/
/*    width: 50%;*/
/*    vertical-align: middle;*/
/*}*/

/*.status-message--application input[type="text"] + label {*/
/*    padding: .8rem 2rem !important;*/
/*    font-size: 1rem;*/
/*}*/

/*.status-message--application input[type="text"]:focus {*/
/*    border: 1px solid #ffdb7a;*/
/*}*/
