.danceText {
  font-size: 3rem;
  font-weight: bold;
  font-family: sans-serif;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.danceText span {
  display: inline-block;
  -webkit-animation: float 0.5s ease-in-out infinite;
  animation: float 0.5s ease-in-out infinite;
}

.danceText span:empty {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-right: 15px;
}

.danceText:hover span {
  -webkit-animation: bounce 0.6s;
  animation: bounce 0.6s;
}

@-webkit-keyframes float {
  0%,
  100% {
    -webkit-transform: none;
    transform: none;
  }
  33% {
    -webkit-transform: translateY(-1px) rotate(-2deg);
    transform: translateY(-1px) rotate(-2deg);
  }
  66% {
    -webkit-transform: translateY(1px) rotate(2deg);
    transform: translateY(1px) rotate(2deg);
  }
}

@keyframes float {
  0%,
  100% {
    -webkit-transform: none;
    transform: none;
  }
  33% {
    -webkit-transform: translateY(-1px) rotate(-2deg);
    transform: translateY(-1px) rotate(-2deg);
  }
  66% {
    -webkit-transform: translateY(1px) rotate(2deg);
    transform: translateY(1px) rotate(2deg);
  }
}

@-webkit-keyframes bounce {
  0%,
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  25% {
    -webkit-transform: rotateX(20deg) translateY(2px) rotate(-3deg);
    transform: rotateX(20deg) translateY(2px) rotate(-3deg);
  }
  50% {
    -webkit-transform: translateY(-20px) rotate(3deg) scale(1.1);
    transform: translateY(-20px) rotate(3deg) scale(1.1);
  }
}

@keyframes bounce {
  0%,
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  25% {
    -webkit-transform: rotateX(20deg) translateY(2px) rotate(-3deg);
    transform: rotateX(20deg) translateY(2px) rotate(-3deg);
  }
  50% {
    -webkit-transform: translateY(-20px) rotate(3deg) scale(1.1);
    transform: translateY(-20px) rotate(3deg) scale(1.1);
  }
}

.danceText span:nth-of-type(4n) {
  color: hsl(50, 75%, 55%);
  text-shadow: 1px 1px hsl(50, 75%, 45%), 2px 2px hsl(50, 45%, 45%),
    3px 3px hsl(50, 45%, 45%), 4px 4px hsl(50, 75%, 45%);
}

.danceText span:nth-of-type(4n-1) {
  color: hsl(135, 35%, 55%);
  text-shadow: 1px 1px hsl(135, 35%, 45%), 2px 2px hsl(135, 35%, 45%),
    3px 3px hsl(135, 35%, 45%), 4px 4px hsl(135, 35%, 45%);
}

.danceText span:nth-of-type(4n-2) {
  color: hsl(155, 35%, 60%);
  text-shadow: 1px 1px hsl(155, 25%, 50%), 2px 2px hsl(155, 25%, 50%),
    3px 3px hsl(155, 25%, 50%), 4px 4px hsl(140, 25%, 50%);
}

.danceText span:nth-of-type(4n-3) {
  color: hsl(30, 65%, 60%);
  text-shadow: 1px 1px hsl(30, 45%, 50%), 2px 2px hsl(30, 45%, 50%),
    3px 3px hsl(30, 45%, 50%), 4px 4px hsl(30, 45%, 50%);
}

.danceText span:nth-of-type(2) {
  -webkit-animation-delay: 0.05s;
  animation-delay: 0.05s;
}

.danceText span:nth-of-type(3) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.danceText span:nth-of-type(4) {
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}

.danceText span:nth-of-type(5) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.danceText span:nth-of-type(6) {
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}

.danceText > div:nth-child(2) span:nth-child(1) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.danceText > div:nth-child(2) span:nth-child(2) {
  -webkit-animation-delay: 0.35s;
  animation-delay: 0.35s;
}

.danceText > div:nth-child(2) span:nth-child(3) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.danceText > div:nth-child(2) span:nth-child(4) {
  -webkit-animation-delay: 0.45s;
  animation-delay: 0.45s;
}

.danceText > div:nth-child(2) span:nth-child(5) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.danceText > div:nth-child(2) span:nth-child(6) {
  -webkit-animation-delay: 0.55s;
  animation-delay: 0.55s;
}

.danceText > div:nth-child(2) span:nth-child(7) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.danceText > div:nth-child(2) span:nth-child(8) {
  -webkit-animation-delay: 0.65s;
  animation-delay: 0.65s;
}
