.newsletter {
  text-transform: uppercase;
}

input[type="email"].body_field {
  width: 100%;
  font-size: 12px;
  border: none;
  outline: none;
  padding: 15px 40px 15px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  background: rgba(255, 255, 255, 0.13);
}
input.body_field {
  -webkit-text-fill-color: rgba(255, 255, 255, 0.8) !important;
}
