.nbg-organizations-wrapper .cell i.fa-ban {
  color: #fff77d;
  cursor: not-allowed;
}

.organization-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  word-break: break-word;
}
