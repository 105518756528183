.edd-multiple-root,
.edd-multiple-root *,
.edd-multiple-root *::before,
.edd-multiple-root *::after {
  /*margin: 0;*/
  /*padding: 0;*/
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.edd-multiple-root {
  display: inline-block;
  position: relative;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #333;
  width: 100%;
  border: none;
  background: #ffffff21;
  resize: vertical;
  color: #ffffff91;
  content: "/f107";
}

.edd-root-open .edd-body {
  opacity: 1;
  pointer-events: all;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: opacity 200ms,
    -webkit-transform 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: opacity 200ms,
    -webkit-transform 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition: opacity 200ms,
    transform 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: opacity 200ms,
    transform 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: opacity 200ms,
    transform 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
    -webkit-transform 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.edd-root-open-below .edd-body {
  top: 100%;
}

.edd-head {
  /*overflow: hidden;*/
}

.edd-head,
.edd-body {
  font-weight: 500;
}

.edd-multiple-value {
  width: calc(100% - 26px);
  padding: 14px 30px 14px 20px;
  font-size: 12px;
  color: #ffffff91;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
}

.edd-multiple-value,
.edd-option,
.edd-group-label {
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  /*overflow: hidden;*/
}

.edd-multiple-value,
.edd-option {
  cursor: pointer;
}

.edd-arrow {
  position: absolute;
  width: 14px;
  height: 10px;
  top: calc(50% - 5px);
  right: 24px;
  -webkit-transition: -webkit-transform 150ms;
  transition: -webkit-transform 150ms;
  -o-transition: transform 150ms;
  transition: transform 150ms;
  transition: transform 150ms, -webkit-transform 150ms;
  pointer-events: none;
  color: #ffffff;
}

.edd-arrow::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-right: 2px solid currentColor;
  border-bottom: 2px solid currentColor;
  top: 0;
  right: 2px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 50% 25%;
  -ms-transform-origin: 50% 25%;
  transform-origin: 50% 25%;
}

.edd-select {
  position: absolute;
  opacity: 0;
  /* width: 100%; */
  width: 0%;
  /* left: -100%; */
  top: 0;
}

.edd-head,
.edd-body {
  /* border-radius: 4px; */
  /* font-size: 1em; */
  font-weight: 500;
}

.edd-body {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  /* border: 1px solid #eee; */
  pointer-events: none;
  /*overflow: hidden;*/
  /* margin: 8px 0; */
  z-index: 999;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
  background: #024a6c;
}

.edd-items-list {
  overflow: auto;
  max-height: 0;
  -webkit-transition: max-height 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition: max-height 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: max-height 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -webkit-overflow-scrolling: touch;
}

.edd-multiple-option {
  padding: 8px 8px;
  font-size: 12px;
  display: flex;
}
.edd-multiple-option .checkbox {
  flex: 1 1 20%;
}

.edd-multiple-option span {
  margin-left: 8px;
  transform: translateY(20%);
  flex: 2 1 100%;
  margin-right: 40px;
}

.edd-gradient-top,
.edd-gradient-bottom {
  content: "";
  position: absolute;
  left: 2px;
  right: 2px;
  height: 32px;
  /* background-image: linear-gradient( 0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 40%, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 0) 100% ); */
  background-repeat: repeat-x;
  background-size: 100% 200%;
  pointer-events: none;
  -webkit-transition: opacity 100ms;
  -o-transition: opacity 100ms;
  transition: opacity 100ms;
  opacity: 0;
}

.edd-gradient-top {
  background-position: bottom;
  top: 0;
}

.edd-gradient-bottom {
  background-position: top;
  bottom: 0;
}
