.chatbot .moveBox__window {
  bottom: 92px;
  right: 99px;
}

.chatbot .moveBox__navbar {
  border-bottom: 1px solid lightgray;
  background-color: #366563;
  position: relative;
}

.chatbot .moveBox__content {
  margin-bottom: -6px;
}

.chatbot__iframe {
  min-height: 400px;
  width: 100%;
  height: 100%;
  margin-top: -40px;
  border: none;
}

.chatbot .moveBox__expand {
  background: white;
}

.chatbot .moveBox__expand svg {
  width: 25px;
}

@media screen and (max-width: 900px) {
  .chatbot .moveBox__window {
    bottom: 160px;
    right: 16px;
  }

  .chatbot__iframe {
    min-height: 200px;
  }

  .chatbot .moveBox__content {
    max-height: initial !important;
    overflow-y: auto;
  }
}
