.selectCList {
}

.selectCList__buttons {
  margin-top: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px;
}

.selectCList__buttons button {
  background: none;
  border: none;
  padding: 0;
  margin-right: 1rem;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.selectCList__buttons div:nth-child(2) button {
  margin-left: 60px;
}

.selectCList__buttons button:hover {
  /*color: #00bcd4;*/
}

.selectCList__btn-all {
}

.selectCList__actions {
  padding-top: 1rem;
  /*border-top: 1px solid #015978;*/
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.selectCList__list {
  max-height: 450px;
  overflow: auto;
}

.selectCList__list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /*border-bottom: 1px solid lightgray;*/
}

.selectCList__list-item--selected {
  background: #1c869d !important;
}

.selectCList__checkbox {
  margin: 0 1rem;
  cursor: pointer;
}

.selectCList__item {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
