.logo {
  position: relative;
  z-index: 299;
}

.logo__text {
  opacity: 1;
  background: rgba(155, 155, 155, 0.15);
  -webkit-box-shadow: inset 0 1px 10px 0 rgba(0, 0, 0, 0.61);
  box-shadow: inset 0 1px 10px 0 rgba(0, 0, 0, 0.61);
  border-radius: 15px;
  position: absolute;
  left: 100px;
  top: 30px;
  width: 100px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 10px;
  font-weight: 600;
  padding: 15px 8px 15px 38px;
  z-index: 199;
}

.logo__image {
  z-index: 299;
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 15px;
  width: 200px;
}

@media screen and (max-width: 900px) {
  .logo__image {
    top: 24px;
    left: 16px;
    width: 180px;
  }
}
