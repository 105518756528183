.sticky-row {
  margin: 1rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.sticky-row__content {
  /*max-width: 800px;*/
}

.sticky-row__sticky {
  /*-webkit-box-flex: 1;*/
  /*-ms-flex: 1 1 auto;*/
  /*flex: 1 1 auto;*/
  /*padding: 0 2rem;*/
  position: -webkit-sticky;
  position: sticky;
  top: 2rem;
}
