.scrollspy {
  padding-bottom: 8rem;
  /*margin-bottom: 8rem;*/
}

.scrollspy * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /*color: white;*/
}

.scrollspy__html {
  /*!*max-width: 85%;*!*/
  /*margin: 25px auto 65px;*/
  /*padding: 0 0 0 0;*/
  /*width: 70%;*/
}

.scrollspy__html .box {
  /*margin: 0 0 0 40px;*/
  /*width: 100%;*/
}

.scrollspy__html * {
  max-width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.scrollspy__html table * {
  word-break: break-all;
}

.scrollspy__html table {
  table-layout: fixed !important;
  margin-left: 0 !important;
  width: 100%;
  display: block;
}

.scrollspy__html table td {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.scrollspy__list {
  /*-webkit-box-flex: 1;*/
  /*-ms-flex: 1 0 15%;*/
  /*flex: 1 0 15%;*/
  /*position: fixed;*/
  /*-ms-flex-item-align: start;*/
  /*align-self: flex-start;*/
  /*margin-top: 25px;*/
  padding: 0;
  /*position: ;*/
}

.goTop {
  top: 0;
}

.scrollspy__list-element--active {
  display: block;
  border-left: 3px solid #006e80;
  -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.26);
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.26);
  background: #006e80;
  color: #fff;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .scrollspy__list {
    /*top: 3rem;*/
    position: sticky;
    top: 0;
    position: -webkit-sticky;
  }
}

.scrollspy__list-element {
  display: none;
  margin-bottom: 8px;
  cursor: pointer;
  padding: 8px;
}

.scrollspy__list-element--h1 {
  font-weight: 900;
  font-size: 1.1rem;
}

.scrollspy__list-element--h2 {
  font-weight: 700;
  font-size: 0.9rem;
  margin-left: 4px;
}

.scrollspy__list-element--h3 {
  font-weight: 400;
  font-size: 0.8rem;
  margin-left: 6px;
}

.scrollspy__list-element--inactive {
  opacity: 1;
  display: block;
  color: white;
}

.scrollspy__list-element--active {
  /*padding: 10px 60px;*/
  padding-top: 10px;
  padding-bottom: 10px;
  background: #024a6c;
  border: none;
  outline-offset: 3px;
  outline: 1px solid rgba(255, 255, 255, 0.25);
  margin: 4px;
  display: block;
  /*font-family: 'CF Asty Std';*/
  /*font-weight: 500;*/
  /*font-size: 14px;*/
  color: #fff;
  cursor: pointer;
  position: relative;
}

@media screen and (max-width: 1250px) {
  .scrollspy__above,
  .scrollspy__list {
    display: none;
  }

  .scrollspy__html {
    width: 100%;
  }

  .scrollspy__html .box {
    margin: 0 0 0 0;
  }
}
