.inviteMembersModal {
  /*max-height: 100vh;*/
  /*overflow-y: auto;*/
}
.inviteMembersModal .edd-items-list {
  overflow: unset;
}

.inviteMembersModal::-webkit-scrollbar {
  width: 8px;
}

.inviteMembersModal::-webkit-scrollbar-thumb {
  background-color: white;
}

.inviteMembersModal::-webkit-scrollbar-thumb:hover {
  background-color: #1c869d;
}
