.scopes {
  text-align: center;
}

.scopes table {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.scopes td,
.scopes th {
  border: 1px solid;
  padding: 8px;
}

.scope th {
  font-weight: 900;
}

.subscriptions_box {
  background-color: rgba(255, 255, 255, 0.02);
  padding-top: 40px;
  padding-left: 40px;
  padding-bottom: 100px;
  padding-right: 150px;
  -webkit-clip-path: polygon(
    0% 0%,
    0% 100%,
    calc(100% - 60px) 100%,
    100% calc(100% - 60px),
    100% 0
  );
  clip-path: polygon(
    0% 0%,
    0% 100%,
    calc(100% - 60px) 100%,
    100% calc(100% - 60px),
    100% 0
  );
  position: relative;
  margin-bottom: 30px;
}

.subscriptions_box:before {
  content: "";
  position: absolute;
  border-left: 30px solid transparent;
  /* border-right: 20px solid transparent; */
  border-top: 30px solid rgba(255, 255, 255, 0.02);
  /* border-bottom: 20px solid #1a4663; */
  width: 0px;
  height: 0px;
  /* transform: rotate(45deg); */
  right: 0px;
  top: 0px;
}

.subscriptions_box:after {
  content: "";
  position: absolute;
  /* border-left: 30px solid transparent; */
  border-right: 30px solid transparent;
  /* border-top: 30px solid #1a4663; */
  border-bottom: 30px solid rgba(255, 255, 255, 0.02);
  width: 0px;
  height: 0px;
  /* transform: rotate(45deg); */
  left: 0px;
  bottom: 0px;
}

.subscriptions_box .subscriptions__icons {
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
}

.subscriptions_box .subscriptions__icons .subscriptions__viewDetails {
  text-align: end;
  margin-right: 4px;
}

.subscriptions_box .subscriptions__icons .subscriptions__viewDetails a span {
  margin-left: 3.5px;
}

.subscriptions_box .subscriptions__icons .subscriptions__unsubscribe {
  cursor: pointer;
  margin-top: 7px;
  text-align: end;
  margin-right: 4px;
}

.subscriptions_box .subscriptions__icons .subscriptions__unsubscribe svg {
  font-size: 0.7rem;
}

.application_subscriptions_table {
  padding: 10px 12px !important;
}

@media screen and (max-width: 650px) {
  .scopes {
    overflow-x: auto;
  }
}

@media screen and (max-width: 767px) {
  .subscriptions_box {
    padding-left: 20px;
    padding-right: 50px;
  }
  .subscriptions__icons {
    width: 50%;
    float: right;
  }
  .application_subscriptions_info {
    width: 50%;
  }
}
