.application-info {
  /*display: flex;*/
  /*flex-direction: column;*/
}

.application-info__description {
}

.application-info__description p:first-child {
  margin-top: 0;
}

.application-info__table {
  border-collapse: collapse;
}

.application-info__table .tag {
  display: inline-block;
}

.application-info__table tr td {
  padding: 1rem 0.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  /*border: 1px solid rgba(255, 255, 255, 0.7);*/
}

.application-info__table tr td:first-child {
  width: 1%;
  color: #fff77d;
  font-weight: 500;
  white-space: nowrap;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

.app-operations {
  float: right;
  display: inline-flex;
}

.app-operations .bracketButton {
  display: -webkit-inline-box;
  /*display: -ms-inline-flexbox;*/
  /*display: inline-flex;*/
  margin: 0 16px;
}

.app-operations .bracketButton a {
  font-size: 16px;
  font-weight: 500;
}
