.redocTimeline {
  z-index: 9999;
  background: #f5f5f5;
  border-radius: 6px;
  margin-left: 1px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -webkit-animation: openHistory 1s ease;
  animation: openHistory 1s ease;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.redocTimeline .box-head__title {
  color: white;
}

.redocTimeline__sidebar {
  min-width: 240px;
}

.redocTimeline__close {
  background: none;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.redocTimeline__close span {
  font-size: 18px;
  color: white;
}

.redocTimeline__sidebar-menuitem {
  color: white;
  font-size: 0.875rem;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 1rem;
  /*margin-bottom: -1px;*/

  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-bottom: 1px solid transparent;
  min-width: 300px;
  margin: 8px;
}

.redocTimeline__sidebar-menuitem:hover {
  border-color: #015978;
}

.redocTimeline__history {
  color: black;
  padding: 0;
}

.redocTimeline__sidebar-container {
  max-width: 600px;
}

.redocTimeline__sidebar-container--open {
  background: #1c869d;
}

.redocTimeline__sidebar-container--open .redocTimeline__sidebar-menuitem,
.redocTimeline__sidebar-container--open
  .redocTimeline__sidebar-menuitem
  .tryout-form__top
  .path {
  color: white;
}

.redocTimeline__sidebar-menuitem:hover {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  -webkit-box-shadow: none;
  box-shadow: none;
  border-bottom: 1px solid #015978;
}

.redocTimeline__history form:first-child .tryout-form-body textarea {
  min-height: initial;
}

.redocTimeline__sidebar-menuitem .tag {
  margin-left: 10px;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}

.redocTimeline__item-ago {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-right: 24px;
  margin-left: 16px;
  width: 100px;
}

.redocTimeline__sidebar-menuitem .btn {
  margin-right: 0.5rem;
}

.redocTimeline__sidebar-container .tryoutButton--favoriteButton {
  margin-left: 0;
  padding-left: 0;
}

.redocTimeline__sidebar-menuitem .tryout-form__top {
  border: none;
  padding: 0;
  margin-right: auto;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: initial;
  overflow: hidden;
}

.redocTimeline__sidebar-menuitem .tryout-form__top:hover {
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  word-break: initial;
}

.redocTimeline__sidebar-container--open
  .redocTimeline__sidebar-menuitem
  .tryout-form__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -o-text-overflow: initial;
  text-overflow: initial;
  word-break: break-word;
  white-space: initial;
}

.redocTimeline__sidebar-container--open
  .redocTimeline__sidebar-menuitem
  .tryout-form__top
  .method {
  line-height: initial;
}

.redocTimeline__sidebar-menuitem .tryout-form__top .path {
  color: white;
}

.redocTimeline__fab {
  background: #00bcd4;
}

.redocTimeline__fab svg {
  width: 16px;
  height: 16px;
  fill: white;
}

.deleteAllHistory {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.deleteAllHistory .btn span {
  color: white;
}

.buttonDelete {
  outline: none;

  display: inline-block;
  cursor: pointer;
  font-weight: 400;

  background: none;
  padding: 0.5rem 1rem;
  display: -ms-inline-flexbox;
  display: -webkit-inline-box;
  display: inline-flex;
  margin-right: 10px;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  line-height: 1;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /*color: #024a6c;*/
  background: transparent;
  -webkit-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
  font-size: 0.8rem;
  border: 1px solid transparent;
}

.buttonDelete:hover {
  background-color: #ac3931;
  color: white;
  border: 1px solid #ac3931;
}

@media screen and (max-width: 900px) {
  .redocTimeline,
  .redocTimeline__fab {
    display: none;
  }
}

@-webkit-keyframes openHistory {
  from {
    right: 0;
    left: 0;
  }
  to {
    left: 100%;
    right: -100%;
  }
}

@keyframes openHistory {
  from {
    right: 0;
    left: 0;
  }
  to {
    left: 100%;
    right: -100%;
  }
}
