/*.support_page__started_container {*/
/*    -webkit-box-sizing: border-box;*/
/*            box-sizing: border-box;*/
/*    color: black;*/
/*    text-align: left;*/
/*    margin-left: 40px;*/
/*    margin-top: 20px;*/
/*    position: relative;*/
/*    z-index: 50;*/
/*    border-radius: 10px;*/
/*    -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.26);*/
/*            box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.26);*/
/*    !*overflow: hidden;*!*/
/*    margin: 25px auto;*/
/*    margin-bottom: 65px;*/
/*    padding: 10px 40px 40px 40px;*/
/*    top: -15px;*/
/*    z-index: 299;*/
/*    background-color: rgba(255, 255, 255, 0.7);*/
/*    width: 80%;*/
/*}*/

/*!* FIX position sticky issue *!*/
/*.support_page--visible-overflow,*/
/*.support_page--visible-overflow *{*/
/*    overflow: visible!important;*/
/*    }*/

/*.support_page__started_container h3 {*/
/*    font-weight: 900;*/
/*    color: #006E80;*/
/*    font-size: 1.4em;*/
/*}*/

.double-box {
  width: 600px;
  /* float: right; */
  /* width: calc(50% - 62px); */
  border: 1px solid rgba(255, 255, 255, 0.13);
  display: table-cell;
  vertical-align: top;
  padding: 30px;
  width: 33%;
}

.double-box h4 {
  font-family: "Oswald";
  font-weight: 500;
  font-size: 50px;
  line-height: 50px;
  margin: 0px 0px;
  /* float: right; */
  width: 100%;
  text-align: right;
}

.double-box p {
  float: right;
  width: 100%;
  text-align: right;
  line-height: 28px;
  /* padding: 20px 0; */
}

.double-box-button-wrapper {
  float: right;
  width: 100%;
  text-align: right;
}

.opacity-white_bg {
  background-color: rgba(255, 255, 255, 0.13);
  padding: 31px 30px;
}

.opacity-white_bg p {
  /*margin-top: 4.2rem;*/
}

@media screen and (max-width: 991px) {
  .double-box {
    display: inline-block;
    width: calc(100% - 60px);
    float: left;
  }

  .double-box a.movingBrackets.right {
    text-align: right;
  }
}

@media screen and (max-width: 767px) {
  .double-box h4 {
    font-size: 25px;
    line-height: 1.2em;
  }

  .double-box p {
    font-size: 13px;
    line-height: 1.2em;
  }
}
