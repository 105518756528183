.tryoutButton {
  padding: 6px;
  -webkit-box-flex: 0;
  -ms-flex: 0;
  flex: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: none;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.tryoutButton svg {
  color: inherit;
  fill: black;
  width: 16px !important;
  height: 16px !important;
}

.tryoutButton--edit {
  /*font-size: 16px!important;*/
  background: black;
}
.tryoutButton--edit svg path {
  fill: white;
}

.tryoutButton--eyeButton {
  background: #f5f5f5;
}

.tryoutButton--resendButton {
  background: #ffdb7a;
}

.tryoutButton--deleteButton {
  background: #ac3931;
}

.tryoutButton--deleteButton svg {
  fill: white;
}

.tryoutButton--favoriteButton {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.tryoutButton--favoriteButton svg {
  fill: #ffdb7a;
}
