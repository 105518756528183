#add_topic_form {
}

#add_topic_form h2 {
  color: #006e80;
}

#add_topic_form .block__form_text_input {
  border: none !important;
  border-radius: 6px;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1),
    0 1px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1), 0 1px 5px rgba(0, 0, 0, 0.15);
}
