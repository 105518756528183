.dropdown {
  z-index: 2;
}

.dropdown__trigger {
  cursor: pointer;

  padding: 8px;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
}

.dropdown__trigger::before {
  content: "";
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  width: 100%;
  height: 100%;
  border-radius: 50%;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.4;
  -webkit-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
  background: lightgrey;
  z-index: 0;
}

.dropdown__trigger:hover::before {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  background: rgba(255, 255, 255, 0.5019607843137255);
}

.dropdown__trigger svg {
  width: 16px;
  height: 16px;
  fill: white;
  z-index: 1;
}

.dropdown__content {
  border-top: rgba(0, 0, 0, 0.05) 1px solid;
  -webkit-box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  display: none;
  position: absolute;
  right: 15px;
  margin-top: 5px;
  background: #ffffff;
  color: black;
  font-size: 0.97rem;
}

.dropdown__item {
  padding: 10px 15px;
  cursor: pointer;
}

.dropdown__item:hover {
  background: lightgrey;
}

.dropdown__item--no-hover:hover {
  background: none;
}

.dropdown__segment {
  border-bottom: #e1e1e1 1px solid;
}

.dropdown--active .dropdown__content {
  display: block;
}
