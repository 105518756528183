.cornersButton-wrapper {
  position: relative;
  float: left;
  margin: 6px 0;
  width: 100%;
  width: 100%;
  position: relative;
}

cornersButton-wrapper:hover:before,
.cornersButton-wrapper.enable:before {
  content: "";
  position: absolute;
  border-top: 1px solid rgba(255, 255, 255, 0.7);
  border-left: 1px solid rgba(255, 255, 255, 0.7);
  top: -4px;
  left: -4px;
  width: 10px;
  height: 10px;
  z-index: 1;
}

.cornersButton-wrapper:hover .cornersButton:before,
.cornersButton-wrapper.enable .cornersButton:before {
  content: "";
  position: absolute;
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  border-left: 1px solid rgba(255, 255, 255, 0.7);
  bottom: -4px;
  left: -4px;
  width: 10px;
  height: 10px;
  z-index: 1;
}

.cornersButton-wrapper:hover .btn:after,
.cornersButton-wrapper.enable .btn:after {
  border-top: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
}

.cornersButton-wrapper:hover .cornersButton:after,
.cornersButton-wrapper.enable .cornersButton:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  border-right: 1px solid rgba(255, 255, 255, 0.7);
  bottom: -4px;
  right: -4px;
  width: 10px;
  height: 10px;
  z-index: 1;
}

.cornersButton-wrapper:hover:after,
.cornersButton-wrapper.enable:after {
  content: "";
  position: absolute;
  border-top: 1px solid rgba(255, 255, 255, 0.7);
  border-right: 1px solid rgba(255, 255, 255, 0.7);
  top: -4px;
  right: -4px;
  width: 10px;
  height: 10px;
  z-index: 1;
}

.txtBox-wrapper.advantage-wrapper {
  width: 1345px;
  position: relative;
  width: calc(100% - 20px);
}

.advantage-wrapper-inner {
  background-color: rgba(255, 255, 255, 0.13);
  padding-top: 40px;
  padding-left: 40px;
  padding-bottom: 100px;
  padding-right: 150px;
  -webkit-clip-path: polygon(
    0% 0%,
    0% 100%,
    calc(100% - 60px) 100%,
    100% calc(100% - 60px),
    100% 0
  );
  clip-path: polygon(
    0% 0%,
    0% 100%,
    calc(100% - 60px) 100%,
    100% calc(100% - 60px),
    100% 0
  );
  position: relative;
  margin-bottom: 30px;
}

.advantage-wrapper-inner:before {
  content: "";
  position: absolute;
  border-left: 30px solid transparent;
  /* border-right: 20px solid transparent; */
  border-top: 30px solid rgba(255, 255, 255, 0.22);
  /* border-bottom: 20px solid #1a4663; */
  width: 0px;
  height: 0px;
  /* transform: rotate(45deg); */
  right: 0px;
  top: 0px;
}

.advantage-wrapper-inner a {
  color: #fff77d;
}

.txtBox-wrapper.advantage-wrapper p {
  padding: 10px 0;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.8);
}

.advantage-wrapper-inner:after {
  content: "";
  position: absolute;
  /* border-left: 30px solid transparent; */
  border-right: 30px solid transparent;
  /* border-top: 30px solid #1a4663; */
  border-bottom: 30px solid rgba(255, 255, 255, 0.22);
  width: 0px;
  height: 0px;
  /* transform: rotate(45deg); */
  left: 0px;
  bottom: 0px;
}

@media screen and (max-width: 1950px) {
  .txtBox-wrapper.advantage-wrapper {
    width: auto;
  }

  .nbg-hub-apis-product .advantage-wrapper-inner {
    margin-top: 30px;
  }

  .advantage-wrapper-inner {
    padding-right: 100px;
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 1199px) {
  .nbg-hub-apis-product .simple_btn .btn,
  .simple_btn .btn {
    padding: 10px 20px;
  }
}

@media screen and (max-width: 991px) {
  .nbg-hub-apis-product .simple_btn {
    width: 100%;
    margin: 10px 0;
  }

  .nbg-hub-apis-product .simple_btn.left {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) {
  .nbg-hub-apis-product .simple_btn .btn {
    width: calc(100% - 8px);
  }

  .nbg-hub-apis-product .advantage-wrapper-inner {
    margin-top: 0px;
    padding-top: 5px;
  }

  .advantage-wrapper-inner {
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 50px;
  }

  .txtBox-wrapper.advantage-wrapper p {
    padding: 10px 0;
    line-height: 1.2em;
    font-size: 13px;
  }
}
