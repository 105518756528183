.sidebar {
  /* width: 100%; */
  /* margin: 14px; */
  float: left;
}

.sidebar.left {
  width: calc(25% - 40px);
  /* width: 310px; */
  padding: 0 20px;
}

.sidebar.left .view_all .bracketButton {
  margin: 10px 0;
}

.sidebar.left .view_all .bracketButton {
  margin: 10px 0;
}

.sidebar.right .small_title,
.array_title {
  font-family: "Oswald";
  font-weight: 500;
  font-size: 25px;
  line-height: 1;
  letter-spacing: -2px;
  margin: 10px 0 0 0;
  padding: 0;
}

.sidebar.right {
  width: calc(75% - 40px);
  /* width: calc(100% - 310px); */
  margin-bottom: 20px;
  padding: 0 20px;
  margin-bottom: 60px !important;
}

.sidebar.right .array_descr {
  margin: 8px 0;
  padding: 0px 16px;
  float: left;
}

.sidebar.right .array_descr span.path_big {
  margin: 2px 0 6px 0;
  /* padding: 0 16px; */
  font-size: 25px;
  letter-spacing: -0.05em;
  line-height: 1em;
  font-family: "Oswald";
  font-weight: 500;
}

.sidebar.right .news {
  display: inline-block;
  float: left;
}

.sidebar.right .news {
  display: inline-block;
  float: left;
  margin: 4px 0;
}

.area_of.titles {
  /* width: calc(100% - 20px); */
  /* padding: 0 10px; */
  width: 100%;
  /* margin: 53px; */
  position: relative;
}

.area_of.view_all {
  width: 100%;
}

.area_of {
  /*display: inline-block;*/
  /*float: left;*/
  /* width: 100%; */
}

.left-sidebar-button-wrapper {
  /* margin-top: 30px; */
  width: 100%;
  position: relative;
}

.left-sidebar-button-wrapper .btn {
  margin: 0 !important;
  outline: 0;
  padding: 14px 60px 14px 20px !important;
  width: 100%;
  text-align: left;
}

.ApiButtons {
  width: 100%;
  padding: 0;
  /* margin: 10px 0; */
  margin: 6px 0;
}

@media screen and (max-width: 991px) {
  .sidebar {
    margin-bottom: 20px;
  }

  .sidebar.left {
    width: calc(100% - 40px);
    /* display: table; */
  }

  .sidebar.right {
    width: calc(100% - 40px);
    /* width: 100%; */
  }

  .sidebar.right .array_descr {
    margin: 8px 0;
    padding: 0 16px;
    float: left;
    width: calc(100% - 32px);
  }

  .area_of.titles {
    /* position: relative; */
    /* width: calc(100% - 20px); */
  }

  .area_of.view_all {
    width: 100%;
    vertical-align: bottom;
    /* width: calc(100% - 20px); */
  }
}

@media screen and (max-width: 767px) {
  .sidebar.right .array_descr {
    float: none;
    display: inline-block;
    float: left;
    width: 100%;
    padding: 0;
  }
}
