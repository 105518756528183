/* FORUM FOLDER */
.cell.forum a {
  text-transform: capitalize;
}

.cell button span.icon-arrow_down:before {
  color: #ffffff70;
  margin: 0 4px;
}

.forum .cell button {
  background: transparent;
}

/*.forum-folder {*/
/*    margin-bottom: 1rem;*/
/*    }*/

/*.forum-folder__header {*/
/*    border-top-left-radius: 10px;*/
/*    border-top-right-radius: 10px;*/
/*    padding: 1rem 2rem;*/
/*    background: #006E80;*/
/*    font-size: 1.5rem;*/
/*    font-weight: 700;*/
/*    color: white;*/
/*    text-align: left !important;*/
/*    text-transform: capitalize;*/
/*    }*/

/*!* FORUM TABLE *!*/

/*.forum-table {*/
/*    color: black;*/
/*    border-collapse: collapse;*/
/*    width: 100%;*/
/*    }*/

/*.forum-table thead {*/
/*    background: rgb(219, 219, 219);*/
/*    }*/

/*.forum-table thead th {*/
/*    font-weight: 300;*/
/*    }*/

/*.forum-table tbody {*/
/*    border-radius: 10px;*/
/*    -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1), 0 1px 6px rgba(0, 0, 0, .15);*/
/*            box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1), 0 1px 6px rgba(0, 0, 0, .15);*/
/*    }*/

/*.forum-table tbody tr th:not(.forum-folder__header) {*/
/*    background: rgba(155, 155, 155, 0.1);*/
/*    }*/

/*.forum-table tbody tr th:first-child {*/
/*    text-align: left;*/
/*    }*/

/*.forum-table td,*/
/*.forum-table th {*/
/*    text-align: center;*/
/*    padding: .5rem 1rem;*/
/*    }*/

/*.forum-table td {*/
/*    text-align: center;*/
/*    padding: .7rem 1rem;*/
/*    }*/

/*.forum-table td a {*/
/*    text-decoration: none;*/
/*    color: #006E80;*/
/*    font-size: 1rem;*/
/*    }*/

/*.forum-table .forum-table__row:nth-child(even) {*/
/*    background: #dddddd;*/
/*    }*/

/*.forum-table__row {*/
/*    width: 100%;*/
/*    }*/

/*.forum-table__row td:first-child {*/
/*    text-align: left;*/
/*    }*/

/*.forum-table__row svg {*/
/*    width: 45px;*/
/*    margin-right: 1rem;*/
/*    display: inline-block;*/
/*    vertical-align: middle;*/
/*    }*/

/*.forum-table__row--discussion svg {*/
/*    width: 45px !important;*/
/*    height: 45px;*/
/*    }*/

/*@media screen and (max-width: 450px) {*/
/*    .forum-table__row svg {*/
/*        width: 24px;*/
/*        }*/

/*    .forum-table__row--discussion svg {*/
/*        width: 24px !important;*/
/*        }*/
/*    }*/

/*tbody.separator,*/
/*tbody.spinner {*/
/*    height: 300px;*/
/*    -webkit-box-shadow: none;*/
/*            box-shadow: none;*/
/*    border: none;*/
/*    }*/
/*tbody.separator{*/
/*    height: 50px;*/
/*}*/

/*!*---------------------------------*!*/

/*.forum-table--small {*/
/*    }*/

/*.forum-table--small td,*/
/*.forum-table--small th {*/
/*    padding: .5rem;*/
/*    }*/

/*.forum-table--small .forum-table__row td:first-child {*/
/*    text-align: inherit;*/
/*    }*/

/*.forum-table__row td:first-child > div{*/
/*    display: -webkit-box;*/
/*    display: -ms-flexbox;*/
/*    display: flex;*/
/*    -webkit-box-pack: justify;*/
/*        -ms-flex-pack: justify;*/
/*            justify-content: space-between;*/
/*    -webkit-box-align: center;*/
/*        -ms-flex-align: center;*/
/*            align-items: center;*/
/*}*/

/*!*---------------------------------*!*/

/*.forum-table--sharp {*/
/*    border: 1px solid white;*/
/*    -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1), 0 1px 6px rgba(0, 0, 0, .15);*/
/*            box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1), 0 1px 6px rgba(0, 0, 0, .15);*/
/*    }*/

/*.forum-table--sharp tbody {*/
/*    -webkit-box-shadow: none;*/
/*            box-shadow: none;*/
/*    border-radius: 0;*/
/*    }*/

/*!*---------------------------------*!*/

/*.forum-table--colored-head {*/
/*    border-collapse: initial;*/
/*    }*/

/*.forum-table--colored-head thead th {*/
/*    background-color: #006E80;*/
/*    font-weight: 900;*/
/*    color: white;*/
/*    }*/

/*blockquote {*/
/*    display: block;*/
/*    background: #fff;*/
/*    padding: 15px 20px 15px 45px !important;*/
/*    margin: 0 0 20px !important;*/
/*    position: relative;*/
/*    line-height: 1.2;*/
/*    color: #666;*/

/*    -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1), 0 1px 6px rgba(0, 0, 0, .15);*/

/*            box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1), 0 1px 6px rgba(0, 0, 0, .15);*/
/*    border-right: 2px solid #34bc9d !important;*/
/*    border-left: 15px solid #46cfb0 !important;*/
/*    }*/

/*blockquote::before {*/
/*    content: "\201C";*/
/*    !*font-family: Georgia, serif;*!*/
/*    font-size: 60px;*/
/*    font-weight: bold;*/
/*    color: #999;*/
/*    position: absolute;*/
/*    left: 10px;*/
/*    top: 5px;*/
/*    }*/

/*blockquote::after {*/
/*    content: "";*/
/*    }*/

/*blockquote p {*/
/*    font-size: 16px;*/
/*    }*/

/*blockquote a {*/
/*    text-decoration: none;*/
/*    background: #eee;*/
/*    cursor: pointer;*/
/*    padding: 0 3px;*/
/*    color: #006E80;*/
/*    margin-bottom: .4rem;*/
/*    }*/

/*blockquote a:hover {*/
/*    color: #666;*/
/*    }*/

/*blockquote em {*/
/*    font-style: italic;*/
/*    }*/

/*blockquote code {*/
/*    color: #da4453;*/
/*    }*/

/*blockquote span {*/
/*    font-weight: bolder;*/
/*    }*/

/*blockquote h1 {*/
/*    text-align: left;*/
/*    font-size: 16px;*/
/*    font-family: 'Francois One', sans-serif;*/
/*    }*/

/*@media screen and (max-width: 450px) {*/
/*    blockquote {*/
/*        border-right: 2px solid #34bc9d !important;*/
/*        border-left: 8px solid #46cfb0 !important;*/
/*        padding: 15px 20px 15px 25px !important;*/

/*        max-height: 8.3rem;*/
/*        overflow: hidden;*/
/*        }*/

/*    blockquote::before {*/
/*        font-size: 30px;*/
/*        }*/

/*    blockquote::after {*/
/*        content: '...';*/
/*        }*/

/*    blockquote:hover {*/
/*        max-height: initial;*/

/*        }*/

/*    blockquote:hover::after {*/
/*        content: '';*/
/*        }*/
/*    }*/
