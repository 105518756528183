.unsupported {
  padding: 10px;
  border: 10px solid whitesmoke;
  max-width: 50%;
  margin: 150px auto;
  text-align: center;
}

@media screen and (max-width: 900px) {
  .unsupported a img {
    width: 50px;
  }
}
