.productNotificationSubscribe i {
  font-size: initial;
}
.productNotificationSubscribe p {
  font-size: 0.8rem !important;
  line-height: initial !important;
  letter-spacing: initial;
  font-family: "CF Asty Std";
}

.productNotificationSubscribe .popover__content {
  display: flex;
}
