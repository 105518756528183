.nbg-organization-wrapper-left {
  min-height: 400px;
}

/*.plakidio {*/
/*    float: left;*/
/*    position: relative;*/
/*    width: 315px;*/
/*    !* min-width: 295px; *!*/
/*    min-height: 0;*/
/*    padding-bottom: 200px;*/
/*    margin: 10px;*/
/*    !* background-color: #1E1E1E; *!*/
/*    border-left: 1px solid rgba(255, 255, 255, 0.22);*/
/*    border-bottom: 1px solid rgba(255, 255, 255, 0.22);*/
/*    overflow: hidden;*/
/*}*/
