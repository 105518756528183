.cookiePopup {
}

.cookiePopup__content {
}

.cookiePopup__body {
}

.cookiePopup__body p:first-child {
  margin-top: 0;
}

.cookiePopup__actions {
  margin-top: 16px;
}

.cookiePopup__checkbox {
  display: inline-block;
  margin-bottom: 1rem;
}

.cookiePopup__checkbox-input {
  margin-right: 1rem;
}

.cookiePopup__checkbox-label {
  margin-left: 8px;
}

.cookiePopup__actions .btn:not(:last-child) {
  margin-right: 8px;
}

.cookiePopup a:link,
.cookiePopup a:visited {
  text-decoration: underline;
  color: #fff77d;
}

.cookiePopup a:hover {
  color: white;
}
