.form-contact input[type="text"].body_field {
  margin: 8px 0;
}

.simple_btn.right {
  float: right;
}

.slack_field {
  margin-bottom: 16px;
  font-size: 14px;
  outline: none;
  padding: 0;
  box-sizing: border-box;
  transition: 0.3s;
  border: 1px solid rgba(255, 255, 255, 0.13);
  display: flex;
  align-items: center;
}
