.authorization {
  /* float: left; */
  position: relative;
  width: 100%;
  /* min-width: 295px; */
  /* min-height: 0; */
  /* padding-bottom: 20%; */
  margin: 10px 0;
  padding: 20px 0;
  /* background-color: #1E1E1E; */
  border-left: 1px solid rgba(255, 255, 255, 0.22);
  border-bottom: 1px solid rgba(255, 255, 255, 0.22);
  overflow: hidden;
}

.authorization:before {
  right: 20px;
  width: calc(100% - 20px);
  border-top: 1px solid rgba(255, 255, 255, 0.22);
}

.authorization:before,
.authorization:after {
  position: absolute;
  content: "";
  top: 0px;
  height: 100%;
}

.authorization h3 {
  font-family: "Oswald";
  font-weight: 600;
  padding: 0 20px;
  font-size: 22px;
}

.authorization p {
  padding: 0 20px;
  color: rgba(255, 255, 255, 0.7);
}

.authorization .simple_btn {
  padding: 0 20px;
}

.simple_btn.left {
  margin-right: 30px;
}

.simple_btn.left {
  float: left;
}

.authorization .icon-arrow_classic_right {
  color: #fff77d;
}

.authorization:after {
  right: 0px;
  top: 20px;
  width: 20px;
  -webkit-transform-origin: right bottom;
  -ms-transform-origin: right bottom;
  transform-origin: right bottom;
  -webkit-transform: skewY(45deg);
  -ms-transform: skewY(45deg);
  transform: skewY(45deg);
  border-top: 1px solid rgba(255, 255, 255, 0.22);
  border-right: 1px solid rgba(255, 255, 255, 0.22);
}

@media screen and (max-width: 767px) {
  .authorization .simple_btn {
    padding: 0 20px;
    width: calc(100% - 40px);
  }

  .authorization .simple_btn.left {
    margin-right: 0;
  }
}
