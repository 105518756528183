.wysiwyg {
  /*padding: 1rem ;*/
  /*border-radius: 6px;*/
  /*-webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1), 0 1px 5px rgba(0, 0, 0, .15);*/
  /*        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1), 0 1px 5px rgba(0, 0, 0, .15);*/
  max-width: 100%;

  resize: vertical;
  width: 100%;
  font-size: 14px;
  border: none;
  margin: 8px 0;
  outline: none;
  padding: 15px 40px 15px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  background: rgba(255, 255, 255, 0.13);
  color: #ced9de;
  -webkit-text-fill-color: rgba(255, 255, 255, 0.8);
  font-family: "CF Asty Std";
}

.rdw-dropdown-wrapper * {
  color: black !important;
  -webkit-text-fill-color: initial !important;
}

.wysiwyg__toolbar {
  border: none;
  padding: 4px 0;
  background: transparent;
  border-bottom: 1px solid #fff;
}

.rdw-editor-wrapper .rdw-link-modal {
  border: none;
  background-color: #1c869d;
  box-shadow: none;
}

.rdw-editor-wrapper .rdw-link-modal-input {
  border: none;
  background: #ffffff21;
  font-size: 12px;
}

.rdw-editor-wrapper .rdw-link-modal-btn {
  border: none;
  background: #024a6c;
  font-size: 12px;
  box-shadow: none;
}

.rdw-editor-wrapper .rdw-link-modal-btn:hover {
  background: #1c869d;
}

.rdw-editor-wrapper .rdw-colorpicker-modal {
  border: none;
  background-color: #1c869d;
  box-shadow: none;
}

.rdw-editor-wrapper .rdw-embedded-modal {
  border: none;
  background-color: #1c869d;
  box-shadow: none;
}

.rdw-editor-wrapper .rdw-embedded-modal-link-input {
  border: none;
  background: #ffffff21;
  font-size: 12px;
}

.rdw-editor-wrapper .rdw-embedded-modal-size-input {
  border: none;
  background: #ffffff21;
  font-size: 12px;
}

.rdw-editor-wrapper .rdw-embedded-modal-btn {
  border: none;
  background: #024a6c;
  font-size: 12px;
  box-shadow: none;
}

.rdw-editor-wrapper .rdw-embedded-modal-btn:hover {
  background: #1c869d;
}

.rdw-editor-wrapper .rdw-emoji-modal {
  border: none;
  background-color: #1c869d;
  box-shadow: none;
}

.rdw-editor-wrapper .rdw-link-decorator-wrapper {
  /*display: inline-flex;*/
  display: inline-block;
}
.rdw-editor-wrapper .rdw-link-decorator-wrapper a {
  color: #fff77d;
}

.rdw-editor-wrapper .rdw-link-decorator-icon {
  max-width: 12px;
  padding: 8px;
  position: absolute;
  top: 8px;
  right: 8px;
  left: initial;
  transform: translate(100%, -100%);
  background: transparent;
}

.rdw-editor-wrapper .rdw-link-modal-target-option input {
  -webkit-appearance: checkbox !important;
}

.rdw-editor-wrapper .rdw-image-modal {
  border: none;
  background-color: #1c869d;
  box-shadow: none;
}

.rdw-editor-wrapper .rdw-image-modal-url-input {
  border: none;
  background: #ffffff21;
  font-size: 12px;
}

.rdw-editor-wrapper .rdw-image-modal-size-input {
  border: none;
  background: #ffffff21;
  font-size: 12px;
}

.rdw-editor-wrapper .rdw-image-modal-btn {
  border: none;
  background: #024a6c;
  font-size: 12px;
  box-shadow: none;
}

.rdw-editor-wrapper .rdw-image-modal-btn:hover {
  background: #1c869d;
}

.wysiwyg__toolbar .rdw-inline-wrapper .rdw-option-wrapper:first-child {
  margin-left: 0;
}

.wysiwyg__toolbar .rdw-inline-wrapper .rdw-option-wrapper:last-child {
  margin-right: 0;
}

.wysiwyg__editor {
  max-width: 100%;
  min-height: 300px;
}

@media screen and (max-width: 450px) {
  .wysiwyg__toolbar {
    display: none;
  }

  .wysiwyg__editor {
    max-width: 100%;
    min-height: 100px;
  }
}

.rdw-option-wrapper {
  min-width: 11px;

  /*padding: 10px 60px;*/
  color: #024a6c;
  border: none;
  outline-offset: 3px;
  outline: 1px solid rgba(255, 255, 255, 0.25);
  margin: 4px;
  margin-right: 8px;
  font-family: "CF Asty Std";
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  position: relative;
}

.rdw-dropdown-optionwrapper {
  color: #024a6c;
}
