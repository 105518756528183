
.check-mark-icon-animated svg {
    width: 52px;
    display: block;
    position: absolute;
}

.path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
&.circle {
     -webkit-animation: dash .9s ease-in-out;
     animation: dash .9s ease-in-out;
 }
&.line {
     stroke-dashoffset: 1000;
     -webkit-animation: dash .9s .35s ease-in-out forwards;
     animation: dash .9s .35s ease-in-out forwards;
 }
&.check {
     stroke-dashoffset: -100;
     -webkit-animation: dash-check .9s .35s ease-in-out forwards;
     animation: dash-check .9s .35s ease-in-out forwards;
 }
}


@-webkit-keyframes dash {
    0% {
        stroke-dashoffset: 1000;
    }
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: 1000;
    }
    100% {
        stroke-dashoffset: 0;
    }
}

@-webkit-keyframes dash-check {
    0% {
        stroke-dashoffset: -100;
    }
    100% {
        stroke-dashoffset: 900;
    }
}

@keyframes dash-check {
    0% {
        stroke-dashoffset: -100;
    }
    100% {
        stroke-dashoffset: 900;
    }
}
