.client_info-wrapper {
  margin-top: 10px;
}
.client__buttons {
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.client__buttons .simple_btn .btn {
  font-weight: 400;
  font-size: 12px;
}
.application-info__credentials-clientSecret div .simple_btn .btn {
  font-weight: 400;
  font-size: 12px;
}

@media screen and (max-width: 767px) {
  /*.client_info-wrapper .simple_btn {*/
  /*    width: calc(100% - 8px);*/
  /*}*/
  .client__buttons .simple_btn label.btn {
    width: 50%;
  }
}
