.text-field {
  width: 80%;
  height: 65px;
  font-size: 12px;
  padding: 15px 40px 15px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  background: rgba(255, 255, 255, 0.13);
}

.slack_button {
  display: flex;
  position: absolute;
  width: 33%;
  margin-top: 10px;
  margin-bottom: 16px;
  font-size: 14px;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  transition: 0.3s;
  align-items: center;
}

.response-request {
  display: block;
  margin-top: 7px;
  margin-bottom: 7px;
}
