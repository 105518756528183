.copy-paste {
  display: inline-block;
  transform: translateY(4px);
  margin-left: 15px;
  cursor: pointer;
}

.enter-icon {
  width: 20px;
  height: auto;
  fill: white;
}
