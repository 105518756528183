.uploadDocumentForm {
  margin: 2rem 0;
  padding: 1rem;
  border: 1px dashed rgba(255, 255, 255, 0.22);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.uploadDocumentForm > div:first-child {
  flex: 1 1 100%;
}

.uploadDocumentForm label {
  font-size: 16px;
}

.uploadDocumentForm input[type="file"] {
  color: white;
}
