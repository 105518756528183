.chip {
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.87);
  height: 32px;
  cursor: default;
  border: none;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  outline: none;
  font-size: 0.8125rem;
  -webkit-transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    -webkit-box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    -webkit-box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -o-transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    -webkit-box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
  border-radius: 16px;
  vertical-align: middle;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-decoration: none;
  background-color: #ffdb7a;
  color: #006e80;
  padding: 4px;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.chip__filter {
  cursor: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
}

.chip__close {
  background-color: rgba(255, 255, 255, 0.7);
  color: inherit;
  font-size: 1rem;
  padding: 4px;

  height: 24px;
  width: 24px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 50%;
}
