.search__icon {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0px;
  background-color: #ffffff00;
  color: #fff;
  width: 40px;
  height: 60px;
  font-size: 18px;
  text-align: center;
  border-color: transparent;
  /* border-radius: 50%; */
  pointer-events: none;
  display: inline-block;
  -webkit-transition: background-color 0.2s ease-in-out;
  -o-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
}

.search__icon__nav {
  background-color: #ffffff00;
  color: #fff;
  font-size: 18px;
  text-align: center;
  border-color: transparent;
}

.body_field + .search__icon {
  height: 100%;
  width: 60px;
}

.st0 {
  fill: #859096;
}
.st1 {
  fill: #fff77d;
}
.st2 {
  fill: #ffffff;
}
.st3 {
  opacity: 0.1;
  fill: #ffffff;
}
.st4 {
  fill: #33b3bf;
}
.st5 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ffffff;
}
.st6 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #007580;
}
.st7 {
  fill: #231f20;
}
.st8 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #231f20;
}
.st9 {
  fill: #c49958;
}
.st10 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 0.9789;
  stroke-miterlimit: 10;
}
.st11 {
  opacity: 0.3;
}
.st12 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 1.0001;
  stroke-miterlimit: 10;
}
.st13 {
  fill: none;
  stroke: #fff77d;
  stroke-width: 1.0001;
  stroke-miterlimit: 10;
}
.st14 {
  fill: none;
  stroke: #fff77d;
  stroke-width: 0.5;
  stroke-miterlimit: 10;
}
.st15 {
  fill: #024a6c;
}
.st16 {
  fill: #1c869d;
}
.st17 {
  fill: #1b2d4c;
}
.st18 {
  opacity: 0.5;
  fill: #1b2d4c;
}
.st19 {
  opacity: 0.2;
  fill: #1b2d4c;
}
.st20 {
  fill: url(#SVGID_1_);
}
.st21 {
  fill: url(#SVGID_2_);
}
.st22 {
  fill: url(#SVGID_3_);
}
.st23 {
  opacity: 0.7;
  fill: url(#SVGID_4_);
}
.st24 {
  opacity: 0.5;
  fill: #ffffff;
}
.st25 {
  opacity: 0.5;
}
.st26 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 1.0014;
  stroke-miterlimit: 10;
}
.st27 {
  fill: none;
  stroke: #fff77d;
  stroke-width: 1.0014;
  stroke-miterlimit: 10;
}
.st28 {
  opacity: 0.15;
}
.st29 {
  -webkit-clip-path: url(#SVGID_6_);
  clip-path: url(#SVGID_6_);
}
.st30 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 0.9738;
  stroke-miterlimit: 10;
}
.st31 {
  opacity: 0.05;
  fill: #ffffff;
}
.st32 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 0.5;
  stroke-miterlimit: 10;
}
.st33 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 2;
  stroke-miterlimit: 10;
}
.st34 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #1c869d;
}
.st35 {
  fill: #c1272d;
}
.st36 {
  fill: #0b698e;
}
.st37 {
  opacity: 0.1;
}
.st38 {
  -webkit-clip-path: url(#SVGID_8_);
  clip-path: url(#SVGID_8_);
}
.st39 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 1.0005;
  stroke-miterlimit: 10;
  stroke-opacity: 0.3;
}
.st40 {
  opacity: 0.3;
  fill: #ffffff;
}
