.members_form {
  display: flex;
  justify-content: space-around;
  padding: 15px 0px;
}

.members_form .option_item_description {
  float: right;
  margin-right: 17px;
}
.members_form .checkbox {
  margin-left: 10px;
}

.members_form .popover__content {
  transform: translateY(50%);
}
.members_form .popover__message {
  margin-top: 7px;
}

.members_form_email {
  width: 40%;
}

.members_form_roles {
  width: 50%;
}

.remove_member {
  width: 30px;
  height: 30px;
}

.remove_member .trash_icon {
  transform: translateY(25%);
  text-align: center;
  cursor: pointer;
}

.remove_member .trash_icon svg {
  fill: white;
  width: 15px;
  height: 15px;
  transform: translateY(50%);
}
.members_form_buttons {
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 767px) {
  .members_form {
    display: flex;
    flex-direction: column;
  }

  .members_form_email {
    margin: auto;
    width: 50%;
  }

  .members_form_roles {
    margin: 20px auto auto auto;
    width: 50%;
  }

  .remove_member {
    margin: auto;
    width: 30px;
    height: 30px;
  }
}
@media screen and (max-width: 767px) {
  .members_form {
    display: flex;
    flex-direction: column;
  }

  .members_form_email {
    margin: auto;
    width: 100%;
  }

  .members_form_roles {
    margin: 20px auto auto auto;
    width: 100%;
  }

  .remove_member {
    margin: auto;
    width: 30px;
    height: 30px;
  }
}
