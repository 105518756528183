.tryout-form__top {
  color: white;
}

.tryout-form__top .method {
  font-size: 0.929em;
  line-height: 20px;
  padding: 3px 10px;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  margin: 0;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.tryout-form__top .path {
  padding: 4px 10px;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  color: white;
}

.tryout-form__top .method-post {
  color: #ffffff;
  background-color: #3a7ca5;
}

.tryout-form__top .method-get {
  background-color: #81c14b;
  color: #ffffff;
}

.tryout-form__top .method-put {
  background-color: #7b5e7b;
  color: #ffffff;
}

.tryout-form__top .method-delete {
  background-color: #ac3931;
  color: #ffffff;
}
