.changelog-container {
  margin-bottom: 16px;
}

.changelog {
  color: white;

  font-size: 0.8rem;
  margin-top: 8px;
  margin-bottom: 8px;
}

/*.changelog:nth-of-type(odd) {*/
/*    background: rgb(245, 245, 245);*/
/*}*/

.changelog__bar {
  background: rgba(255, 255, 255, 0.13);
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
}

.changelog__bar:hover {
  /*color: #006E80;*/
}

.changelog__bar {
  padding: 8px 16px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.changelog__bar > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.changelog__bar > div > small {
}

.changelog__bar > div > small:first-child {
  margin-right: 20px;
}

.changelog__bar > div .tag {
  margin-left: 5px;
  font-size: inherit;
  text-transform: capitalize;
}

.changelog__toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;

  cursor: pointer;

  padding: 8px;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.changelog__toggle svg {
  fill: white;
}

/*.changelog__bar:hover .changelog__toggle svg {*/
/*    fill: #006E80;*/
/*}*/

.changelog__toggle svg {
  width: 16px;
  height: 16px;
}

.changelog__contents {
  color: rgba(255, 255, 255, 0.57);
  font-size: 11px;
  padding: 1px 16px 8px;
  background: transparent;
}

.changelog--open .changelog__bar:hover {
  /*background: none;*/
  /*color: #006E80;*/
}

.changelog--open .changelog__toggle span {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
