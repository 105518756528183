.dockIcon {
  display: inline-block;
  color: darkgrey;
  border: 2px solid currentColor;
  width: 18px;
  height: 14px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-left: 8px;
  vertical-align: center;
}

.dockIcon:hover {
  color: black;
}

.dockIcon--active {
  color: #00bcd4 !important;
}

.dockIcon--left {
  border-left-width: 6px;
}

.dockIcon--right {
  border-right-width: 6px;
}

.dockIcon--bottom {
  border-bottom-width: 6px;
}

.dockIcon--external {
  -webkit-box-shadow: -1px 1px 0 white, -3px 3px 0 currentColor;
  box-shadow: -1px 1px 0 white, -3px 3px 0 currentColor;
  margin-left: 12px;
  width: 15px;
  height: 12px;
  margin-bottom: 2px;
}
