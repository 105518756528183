.cr {
  width: 120px;
  padding: 8px 0;
  position: absolute;
  text-align: center;
  color: #f0f0f0;
  /*color: #fff77d;*/
  /*color: rgba(255, 255, 255, 0.7);*/
  font-size: 0.55rem;
  /*background: #ffffff21;*/
  background: #024a6c;
  /*border: 1px solid #024a6c ;*/
}

.cr-sticky {
  position: fixed;
}

/* Positions */

.cr-top.cr-left {
  top: 28px;
  left: 28px;
  transform-origin: center;
  transform: translateX(-50%) translateY(-50%) rotate(-45deg);
}

.cr-left.cr-bottom {
  bottom: 28px;
  left: 28px;
  transform-origin: center;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

.cr-top.cr-right {
  top: 28px;
  right: 28px;
  transform-origin: center;
  transform: translateX(50%) translateY(-50%) rotate(45deg);
}

.cr-bottom.cr-right {
  bottom: 28px;
  right: 28px;
  transform-origin: center;
  transform: translateX(50%) translateY(50%) rotate(-45deg);
}

/* Colors */

.cr-white {
  background-color: #f0f0f0;
  color: #444;
}
.cr-black {
  background-color: #333;
}
.cr-grey {
  background-color: #999;
}
.cr-blue {
  background-color: #39d;
}
.cr-green {
  background-color: #2c7;
}
.cr-turquoise {
  background-color: #1b9;
}
.cr-purple {
  background-color: #95b;
}
.cr-red {
  background-color: #e43;
}
.cr-orange {
  background-color: #e82;
}
.cr-yellow {
  background-color: #ec0;
}
