.result {
  width: calc(100% - 20px);
  padding: 0 10px;
  position: relative;
}

.sidebar.right .result .rInfo {
  display: inline-block;
  float: left;
  width: calc(100% - 20px);
  padding: 0 10px;
  margin: 6px 0;
}

.rTitle {
  font-size: 12px;
  color: #ffffff;
  font-weight: 500;
}

.sidebar.right .result .rInfo > div {
  margin: 8px 0;
}

.rDescription {
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
}

.sidebar.right .result .rInfo > div {
  margin: 8px 0;
}

.rDate {
  width: 100%;
  font-size: 10px;
  color: #fff77d;
}

.sidebar.right .result .rInfo > div {
  margin: 8px 0;
}
